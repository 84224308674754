.configurations .stamp-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.configurations .custom-switch.custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 1.5rem;
}

.configurations .custom-switch.custom-switch .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.configurations .custom-switch.custom-switch .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.configurations .custom-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

.configurations .stamp-container .text-switch h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.configurations .stamp-container .text-switch p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
}

.configurations .Button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: 10px;
}

.configurations .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.configurations .row-selection a{
    margin-right: 10px;
}

.configurations .row-selection a:hover{
    color: black;
}

.configurations .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.configurations .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.configurations .input{
    align-items: center;
    transform: scale(1.5);
}

.configurations .alter-table {
    display: flex;
    align-items:center;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
    justify-content:space-between;
}

.configurations .alter-table .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
}

.configurations .alter-table .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0 0 0 10px;
}

.configurations .alter-table  .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.configurations .alter-table .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -10px;
}

.configurations .alter-table .options {
    display: flex;
    align-items: center;
}

.configurations .alter-table .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}

.configurations .alter-table  .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}

.configurations .table-container {
    display: flex;
    width: 97%;
    height: 70vh;
    overflow: scroll;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.configurations .table-container thead,.configurations .table-container th{
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: sticky;
    top: 0;
    z-index: 2 !important;
    font-size: 1em !important;
    font-weight: 500 !important;
}

.configurations .table-container tbody td{
    z-index: 1;
    background: rgb(255, 255, 255)!important;
    text-align: center;
}

.configurations .api-container .api{
height: 40px;
padding: 10px;
font-size: 20px;
background: #FAFAFA;
opacity: 1;
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
backdrop-filter: blur(50px);
border-radius: 20px;
}

.configurations .ButtonTest{
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: 10px;
    justify-content: center;
    max-width: 150px;
}

.configurations .api-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 97%;
}

.configurations .api-container label{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}
.configurations .api-container form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.configurations .api-container .form-group{
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.configurations .api-container .api-group{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.configurations .editor-container{
    display: flex;
    width: 97%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.configurations .tox-tinymce{
    width: 95% !important;
    margin-top: 30px;
    margin-bottom: 30px;
}

.configurations .editor-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tox .tox-notification--warn, .tox .tox-notification--warning{
    display: none !important;
}