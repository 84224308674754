.help .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}


.help .pdf-viewer{
    height: 80vh;
    width: 97%;
}