.register{
    background: white;
    width: inherit;
    height: inherit;
}

.register .card{
    margin-top: 70%;
    width: 100%;
}

.register .background-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.register .description-container {
    padding: 10% 0 0 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.register .description-container h2{
    color: #007ed1;
    font-weight: bold;
    width: 50%;
    font-size: 50px;
    margin-bottom: 5%;
}

.register .description-container h4{
    width: 60%;
    font-size: 25px;
}

.register .description-container h5{
    font-weight: bold;
    margin-bottom: 3%;
}


.register .row{
    margin: 0;
}
.register form{
    width: 70%;
    margin: auto;
}

.register .InternsLogo {
    width: 150px;
    height: auto;
}

.register .logoCaintra {
    width: 100px;
    height: auto;
}

.register .text-container{
    margin-top: 30px;
}

.register .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.register input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.register .form-label{
    color: black;
    font-size: 15px;
}

.register .row-logo{
    margin: 5% 5% 5% 5%;
}

.register .row-contact{
    text-align: center;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
}

.register .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.register .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
}

.register .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.register .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

@media only screen and (min-width: 1920px) {
    .register .row-selection{
        margin-bottom: 5%;
    }    

    .register .btn-register{
        margin-top: 10%;
    }

    .register .row-contact{
        padding-top: 15px;
    }
}

@media only screen and (min-width: 1367px) and (max-width: 1919px) {
    .register .row-selection a{
        margin-bottom: 5%;
    }

    .register .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .register .background-container{
        display: none;
    }

    .register .row-selection a{
        font-size: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-height: 900px) {
    .register input{
        height: 33px;
    }

    .register .form-label{
        font-size: 15px;
        margin: 0;
    }

    .register form{
        height: 85vh;
        overflow-y: auto;
        padding: 10px;
    }
}