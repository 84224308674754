

.custom-date-picker{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
}

.custom-date-picker .react-datepicker-wrapper input{
    background: transparent !important;
    color: #666;
    height: 50px;
    padding-top: 0;
    border: none;
    padding: 0;
    margin: 0;
}

.custom-date-picker .date-btn:hover input{
    color: white;
}

.custom-date-picker .date-btn input:focus-visible{
    outline: none;
    border: none;
}

.custom-date-picker .date-btn{
    padding: 0 20px !important;
}

.custom-date-picker search-container{
    display: inline-block;
    margin: 0;
    min-width: 30%;
}

.custom-date-picker .search-icon{
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
    width: 50px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}

.custom-date-picker .btn {
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}

.custom-date-picker .inner-text{
    margin-right: 5px;
}

/* 
.default-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 10px;
} */

