.amounts .save-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    min-width: 100px;
}

.amounts .row-selection .selected{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.amounts .row-selection p{
    font-size: 18px;
    color: black;
    margin: 0 auto;
}
.amounts .row-selection .section{
    margin-right: 10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.amounts .row-selection{
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 15px;
    background-size: 30%;
    width: fit-content;
}

.amounts .row-selection .text-selected {
    color: #FAFAFA;
    font-weight: 700;
}

.amounts .row-selection .text-section {
    color: #535A63;
    font-weight: 400;
}

.amounts .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}

.amounts .text-form{
    background: #FFFFFF;
    opacity: 0.8;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}
.amounts .dispersion-text{
    background: #FFFFFF;
    opacity: 0.8;
    border-radius: 20px 20px 20px 20px;
    width: 40%;
    text-align: center;
    margin-left: 5px;
}
.amounts .dispersion-row{
    display: flex;
    align-items: center;
}
.amounts .error{
    background: rgba(215, 82, 82, 0.8);
}

.amounts .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
    width: 97%;
}

.amounts .table{
    border: none;
    margin: 0;
}

.amounts .card-header{
    padding: 0;
    margin: 0;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.amounts .card-header button{
    text-decoration: none;
    color: #1B3769;
    width: fit-content;
}

.amounts .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}


.amounts .card-header button:active{
    outline: none;
    border: none;
}

.amounts .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.amounts .table thead, .amounts .table th{
    border: none;
    border-color: transparent;
    text-align: center !important;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}


.amounts .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.amounts .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.amounts .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.amounts .table tbody tr td svg{
    font-size: 12px;
}

.amounts .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.amounts .wi-90{
    width: 90px !important;
}

.amounts .wi-110{
    width: 110px;
}

.amounts .wi-70{
    width: 70px;
}

.amounts .center-text{
    text-align: center;
}

.amounts .check-container{
    display: flex;
    align-items: center;
}
.amounts .line-bottom{
    border-bottom: 1px solid;
    border-bottom-color: gainsboro;
}
.amounts .max-vh{
    height: 70vh;
    overflow: auto;
}

.amounts .date-selector-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center
}

.amounts .date-selector-container > *{
    display: flex;
    flex-direction: row;
    margin: 10px 10px 10px 0px;
}

.amounts .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.amounts .table-amounts{
    width: 97% !important;
    margin-top: 20px;
}

.amounts .table-amounts tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
}

.amounts .table-amounts tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table-amounts tbody {
    background: rgba(255, 255, 255, 255) !important;
}

.amounts .table-amounts thead {
    background: #E9EDF3;
    color: white !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.amounts .table-amounts tr{
    height: 30px;
}

.amounts .total{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
}

.amounts .total th{
    font-weight: bold;
    font-size: 20px !important;
}


.amounts .table-amounts{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.amounts .table-amounts tbody tr:last-child th:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table-amounts tbody tr:last-child  th:last-child{
    border-bottom-right-radius: 10px;
}


.amounts .total-container{
    width: 97%;
}

.amounts .total-container .total{
    display: flex;
    justify-content: flex-end;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.amounts .total-container{
    width: 97%;
    background-color: #E9EDF3 !important; 
    border-radius: 15px;
    border: 1px solid #d7d7d7;
    margin-top: 10px;
}
.amounts .footer-total{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    padding: 10px;
}

.amounts .footer-total p{
    margin: 0;
}

.amounts .delete-bank{
    display: flex;
    align-items: center;
    justify-content: center;
}

.amounts .delete-bank b{
    margin-right: 10px;
}

.amounts .delete-bank .trash-icon:hover{
    cursor: pointer;
}

.amounts .reload-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    font-size: 25px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    width: 50px;
    color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amounts .reload-button:hover{
    cursor: pointer;
}