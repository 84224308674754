@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700, 500);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header, .row {
  display: flex; 

}

.col {
  flex: 1 1;        
}

.apexcharts-tooltip{
  width: 60%;
  max-width: 1230px;
  font-size: 9px;
  z-index: 99999 !important;
}

.apexcharts-tooltip-series-group{
  margin: auto;
  width: 61%;
}


.header{
  font-weight: bold;
}

.modal-content{
  padding-bottom: 15px !important;
}

.sticky {
 
  top: 0;
  margin: 10px 30px 0px 7px !important;
  z-index: 1;
  border-radius: 10px;
  margin: auto;
  width: 96.5%;
  padding: 12px;
  height: 170px;
}

.element-space{
  margin: 20px 0 30px 0;
  height: 40px !important;
  word-wrap: break-word;
}


*{
  padding: 0;
  margin:  0;
  scroll-behavior: smooth;
}

#root{
  background: linear-gradient(72.46deg, rgba(37, 235, 81, 0.75) 0%, rgba(0, 174, 239, 0.75) 100%);
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 15px;

}

::-webkit-scrollbar-thumb:hover {
  background: #1B3769;
}

@media only screen and (min-width: 993px) {
  #root{
    overflow-y: hidden;
  }
}


.myButton{
  border-radius: 20px !important;
  margin-top: 15px;
}

.myHeader{
  height: 90px !important;
  overflow: hidden;
}

div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
} 

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}


.myInputDate{
  border-style: solid !important;
  border-color: rgb(197, 197, 197) !important;
  border-width: 0.5px !important;
  border-radius: 5px !important;
  height: 30px !important;
  width: 104px !important;
  margin-left: 11px !important;
}
.register{
    background: white;
    width: inherit;
    height: inherit;
}

.register .card{
    margin-top: 70%;
    width: 100%;
}

.register .background-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.register .description-container {
    padding: 10% 0 0 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.register .description-container h2{
    color: #007ed1;
    font-weight: bold;
    width: 50%;
    font-size: 50px;
    margin-bottom: 5%;
}

.register .description-container h4{
    width: 60%;
    font-size: 25px;
}

.register .description-container h5{
    font-weight: bold;
    margin-bottom: 3%;
}


.register .row{
    margin: 0;
}
.register form{
    width: 70%;
    margin: auto;
}

.register .InternsLogo {
    width: 150px;
    height: auto;
}

.register .logoCaintra {
    width: 100px;
    height: auto;
}

.register .text-container{
    margin-top: 30px;
}

.register .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.register input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.register .form-label{
    color: black;
    font-size: 15px;
}

.register .row-logo{
    margin: 5% 5% 5% 5%;
}

.register .row-contact{
    text-align: center;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
}

.register .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.register .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
}

.register .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.register .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

@media only screen and (min-width: 1920px) {
    .register .row-selection{
        margin-bottom: 5%;
    }    

    .register .btn-register{
        margin-top: 10%;
    }

    .register .row-contact{
        padding-top: 15px;
    }
}

@media only screen and (min-width: 1367px) and (max-width: 1919px) {
    .register .row-selection a{
        margin-bottom: 5%;
    }

    .register .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .register .background-container{
        display: none;
    }

    .register .row-selection a{
        font-size: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-height: 900px) {
    .register input{
        height: 33px;
    }

    .register .form-label{
        font-size: 15px;
        margin: 0;
    }

    .register form{
        height: 85vh;
        overflow-y: auto;
        padding: 10px;
    }
}
.documentation-request{
    width: inherit;
    height: inherit;
    font-family: "Mulish";
}

.documentation-request .background-container{
    background-image: url(/static/media/background-girl.de953396.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.documentation-request .form-card{
    background-color: rgba(255, 255, 255, 0.96);
    padding: 20px;
    border-radius: 5px;
    height: 90vh;
    overflow-y: auto;
}

.documentation-request form{
    width: 70%;
    margin: auto;
}

.documentation-request select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.documentation-request h4{
    color: #1B3769;
    margin: auto;
    padding-bottom: 12px;
}

.documentation-request small{
    color: #1B3769;
    margin: auto;
}

.documentation-request .InternsLogo {
    width: 150px;
    height: auto;
}

.documentation-request .logoCaintra {
    width: 100px;
    height: auto;
}

.documentation-request .text-container{
    margin-top: 30px;
}

.documentation-request .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.documentation-request input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.documentation-request .form-label{
    color: black;
    font-size: 15px;
}

.documentation-request .row-logo{
    margin: 25px 5px 5px 5px;
}

.documentation-request .row-contact{
    text-align: center;
    margin-top: 5%;
}

.documentation-request .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.documentation-request .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    padding-top: 40%;
}

.documentation-request .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.documentation-request .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.documentation-request .files{
    background-color: #eceef1!important;
    height: 140px !important;
    border-radius: 20px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
}

.documentation-request .files .divcard{
    max-width: 90% !important;
    margin: 0 auto;
}

.documentation-request .files .card-title{
    width: 90% !important;
    margin: 0 auto;
}


.documentation-request .files .upload-container{
    width: 90% !important;
    text-align: center !important;
    color: white;
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    outline-style: none;
    padding: 0;
    padding-top: 6px;
    border-radius: 25px;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    opacity: 0.8;
    margin: 0 auto !important;   
}

.files .header-container{
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 5px;
    padding-bottom: 15px;
    display: flex;
    width: 90%;
    justify-content: space-between;
}


.documentation-request .files a{
    display: flex;
    justify-content:  space-between;
    flex-wrap: wrap;
}

.documentation-request .files svg{
    margin: 3px 3px 0 0;
}

.documentation-request .files small{
    margin: 0;
}

.documentation-request .form-file-label:hover{
    cursor: pointer;
}

.documentation-request .form-file{
    width: 100% !important;
    height: 100% !important;
}

.documentation-request .form-file-label{
    width: 100% !important;
    height: 100% !important;
}


.documentation-request .form-control-file{
    display: none;
}

.documentation-request input[type="checkbox"]{
    height: auto;
}

.documentation-request .success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}

.no-margin{
    margin: 0 !important;
}
.rw-widget > .rw-widget-container  {
    border: none !important;
}
.rw-datetime-picker input{
    border-radius: 10px 0px 0px 10px !important;
}
.rw-select-bordered{
    border: none;
    background-color: #eceef1;
}
.rw-widget-input, .rw-filter-input {
    box-shadow: none !important;
}
@media only screen and (min-width: 1920px) {
    .documentation-request .row-selection{
        margin-bottom: 10%;
    }    

    .documentation-request input{
        height: 50px;
    }

    .documentation-request select{
        height: 50px;
    }

    .documentation-request .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .documentation-request .row-contact{
        padding-top: 30px;
    }
}

@media only screen and (min-width: 1366px) {
    .documentation-request .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .documentation-request .row-selection{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .documentation-request .row-selection a{
        font-size: 20px;
    }
}

@media only screen and (max-width: 1140px) {
    .documentation-request .files{
        min-height: 175px;
    }
}
.login{
    width: inherit;
    height: inherit;
    background-image: url(/static/media/background-girl.de953396.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.login .blurer{
    height: 100%;
}
.login .card{
    margin-top: 70%;
    width: 100%;
}

.login .form-container{
    display: flex;
    align-items: center;
    justify-content: center;
}


.login .form-box{
    width: 400px;
    background:  rgb(250, 250, 250, 0.7);
    border-radius: 15px;
    min-height: 60vh !important;
    max-height: 90vh !important;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: transparent;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(7,210,204);
    background: linear-gradient(0deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.login .description-container {
    padding: 20% 0 0 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.login .description-container h2{
    font-weight: bold;
    width: 50%;
    font-size: 55px;
    margin-bottom: 20px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login .description-container h4{
    width: 75%;
    font-size: 25px;
    font-weight: 200;
    color: white;
}

.login h5{
    font-weight: bold;
    color: rgb(41, 36, 36);
    display: inline-block;
    margin-bottom: 3%;
}

.new-cc-modal form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}

.new-cc-modal form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.login .row{
    margin: 0;
}
.login form{
    margin: 0 40px;
}
.login form input{
    height: 50px;
    background: rgba(250, 250, 250, 0.75) !important;
    border-radius: 15px;
    color: rgb(41, 36, 36) !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15) !important;

}
.login form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
    color: #12121307;
}
.login form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}
.login form input:focus{
    outline: none;
    box-shadow: none;
    color: #12121307;
    background: rgba(250, 250, 250, 0.75);
}
.login form input:focus::-webkit-input-placeholder{
    color: #97a3b2;
}
.login form input:focus::placeholder{
    color: #97a3b2;
}
.login form input::-webkit-input-placeholder{
    color: #12121362;
}
.login form input::placeholder{
    color: #12121362;
}
.login form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.login form .input-icon{
    font-size: 25px;
    color: rgb(2, 2, 2);
}
.login form .see-password{
    cursor: pointer;
}
input:-webkit-autofill:hover,
input:-webkit-autofill{
    box-shadow: 0 0 0 30 #97a3b2 inset !important;
    -webkit-box-shadow: 0 0 0 30px rgba(250, 250, 250, 0.75) inset !important;
    -webkit-text-fill-color: rgb(104, 104, 104) !important;
}
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    box-shadow: 0 0 0 30 #e8f0fe inset !important;
    -webkit-box-shadow: 0 0 0 30px #e8f0fe inset !important;
    -webkit-text-fill-color: rgb(104, 104, 104)  !important;
}
input:-webkit-autofill
{
 background-color: rgba(250, 250, 250, 0.75);
 -webkit-text-fill-color: rgb(104, 104, 104) !important;
}

.login .InternsLogo {
    width: 207px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.login .logoCaintra {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.login .text-container{
    margin-top: 30px;
}

.login .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.login input{
    background-color: #eceef1;
    border: none;
    border-radius: 5px;
}
.login .forgot-container{
    text-align: left;
}
.login .forgot-password{
    color: rgb(104, 104, 104) !important;
    text-decoration:underline;
    cursor: pointer;
    font-size: 19px;
}

.login .row-logo{
    margin: 5% 5% 5% 5%;
}

.login .row-contact{
    text-align: center;
    margin-top: 5%;
}

.login .btn {
    background: rgb(181, 197, 197);
    background: #1B3769;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    outline-style: none;
    padding: 8px 70px 8px 70px;
    font-size: 1.3em;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px auto;
}

.login .btn:hover{
    background: #072353;
}


.login .login-btn{
    margin-top: 80px ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.login .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: 40px;
    margin-bottom: 20px;
}


.login .row-selection a{
    color: rgb(54, 54, 54);
    font-size: 25px;
    margin-right: 5%;
    font-family: 'Mulish', sans-serif !important;
}

.logos-container .divider{
    border: none !important;
    border-right: 1px solid rgb(54, 54, 54) !important;
} 

.login .row-selection .logInBtn{
    border-right: 1px solid rgb(54, 54, 54);
    padding-right: 5%;
}

.login .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.login .need-help-container{
    text-align: right;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
}
.login .need-help-container .need-help{
    color: rgb(61, 61, 61);
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 1em;
    text-decoration: underline;
}

.login .register-company-container{
    text-align: right;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.login .register-company-container .register-here{
    color: rgb(61, 61, 61);
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 1em;
    text-decoration: underline;
} 

.login .need-help-container .need-help svg{
    color: rgb(54, 54, 54);
    margin-left: 10px;
    margin-top: -5px;
    font-size: 2.4em;
}
@media only screen and (min-width: 1920px) {
    .login .row-selection{
        margin-bottom: 10%;
    }    

    .login input{
        height: 50px;
    }

    .login .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .login .row-contact{
        padding-top: 30px;
    }
}

.logos-container{
   margin-top: 15px;
   display: flex !important;
   justify-content: space-evenly !important;
   align-content: center !important;
}

.whatsapp-icon{
    width: 18px;
    height: 18px;
}


@media only screen and (min-width: 1366px) {
    .login .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .login .background-container{
        display: none;
    }

    .login .row-selection{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .login .row-selection a{
        font-size: 20px;
    }
    
}
.dashboard {
    overflow-x: hidden;
    overflow-y: scroll; 
    height: 87vh;
    font-family: "Mulish" !important;
}

.dashboard h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.dashboard .title-search-bar{
    display: flex;
    flex-direction:column;
}

.dashboard .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.dashboard .title-search-bar .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
    font-weight: bold;
}

.internsDashBoard{
    margin-top: 5%;
    height: 10% !important;
}

.interns-container{
    height: 100%;
    width: 100%;
}

.interns-container .container{
    background-attachment: #FFFFFFE5;
    font-size: 25px;
    border-radius: 12px;
    display: flex;
    flex-direction: initial;
    height: 130px;
}

.interns-container .container .circle-container{
    width: 35%;
    height: 100%;
    align-content: center;
    padding: 1rem;
}

.interns-container .container .text{
    color: #121213;
    font-weight: bold;
    padding: 1rem;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.interns-container .container .text h5{
    color: #121213;
    font-weight: bold;
}
.interns-container .container .text h2{
    color: #121213;
    font-weight: bold;
}

.interns-container .container .circle-container .circle{
    align-items: center;
    -webkit-clip-path: circle();
            clip-path: circle();
    justify-content: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
}

.set-color-icon{
    -webkit-filter: invert(100%) sepia(1%) saturate(3485%) hue-rotate(236deg) brightness(117%) contrast(100%);
            filter: invert(100%) sepia(1%) saturate(3485%) hue-rotate(236deg) brightness(117%) contrast(100%);
    width: 28%;
}


.active-color{
    background: rgba(6, 209, 205, 0.8);
}

.inactive-color{
    background: rgba(237, 90, 108, 0.8);
}

.dashboard .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.dashboard .search-container{
    width: 90%;
}

.dashboard .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.dashboard .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.dashboard .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.dashboard .search-bar-container .show{
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
}

.dashboard input{
    background-color: #eceef1;
    border-radius: 12px;
}

.dashboard-row{
    margin: 0px 50px 20px 0px;
    height:66vh !important;
    overflow: auto ;
}

.dashboard-container{
    background: #FFFFFFE5;
    font-size: 25px;
    border-radius: 12px;
    padding: 2rem;
    margin: 30px 0px 0px 6px;
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.title-icon-container{
    display: flex;
    flex-direction: column;
}

.title-dashboard{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 14px;
    margin-bottom: 10px;
    justify-content: space-between;
}
.title-gender-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.title-dashboard p{
    margin: 0px 10px;
    font-weight: bold;
}

.gender-dashboard{
    max-width: 99% !important;
}

.gender-dashboard .apexcharts-menu-icon{
    -webkit-transform: scale(1.7)!important;
            transform: scale(1.7)!important;
    padding-bottom: 50px !important;
    padding-right: 30px !important;
}

.title-icon-container .icons-container{
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 20px 0px 10px 26px;
}

.title-icon-container .comparsion-icons{
    margin: 20px 20px 10px 0px !important;
}


.title-icon-container .icons-container .masculine-icon{
    margin-right: 30px;
    margin-left: 10px;
    width: 20px;
}

.dashboard .history-icon{
    margin-left: 5px;
    width: 25px !important;
}

.dashboard .arrow-rise{
    margin-left: 5px;
    width: 27px !important;
}

.title-icon-container .icons-container .femenine-icon{
    margin-left: 15px;
    width: 25px;
    margin-right: 10px;
}

.legends-container{
    padding: 4rem;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-flow: wrap;
}


.label-container{
    font-size: .8rem;
    width: 100%;
    min-height: 4rem;
    max-height: 6rem;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    flex-wrap: nowrap;
}

.label-legend-brand{
    border-radius: 30px 30px 30px 30px;
    background: rgba(237, 90, 108, 0.8);
    width: 1rem !important;
    height: .5rem;
    margin-right: .8rem;
}
.apexcharts-canvas .apexcharts-title-text{
    font-weight: 700;
    font-size: 20px;
}
.gender-dashboard .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
    display: none;
}

.dashboard .options{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard .options .dropdown-options{
    margin: 10px 0px 10px 0px;
}

.dashboard .options-dashboard-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 45px;
    margin-left: 6px;
}

.dashboard .options-dashboard-container .green-button{
    margin: 10px 0px;
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    border-radius: 25px;
    font-style: normal;
    font-weight: 400;
    color: #FAFAFA;
    min-width: 100px;
    padding: 10px 20px;
    border: none !important;
    outline-style: none !important;
    justify-content: center;
    align-items: center;
    height: 44px;
    display: flex !important;
}
.dashboard .options-dashboard-container .green-button .download-all-icon{
    width: 19px;
    height: 19px;
    -webkit-filter: invert(100%) sepia(100%) saturate(9%) hue-rotate(346deg) brightness(110%) contrast(96%);
            filter: invert(100%) sepia(100%) saturate(9%) hue-rotate(346deg) brightness(110%) contrast(96%);
    margin-right: 5px;
}

.dashboard .options .btn{
    background: #FFFFFFE5 !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: 600;
    margin-right: 6px;
}

.dashboard .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.dashboard .options .filters-menu-companies{
    min-width: 200px !important;
}

.dashboard .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}
.dashboard .options .options-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.dashboard .options-search-container a:hover{
    background-color: rgba(220, 220, 220, 0.500) !important;
}

.dashboard .options-search-container a:active{
    background-color: rgba(220, 220, 220, 0.500) !important;
}


.dashboard .options-search-container hr{
    width: 80%;
    margin: 5px auto;
}

.title-icon-container .icons-container .icon-text-container{
   display: flex;
   align-items: center;
   flex-direction: row;
}
.title-icon-container .icons-container .icon-text-container p{
    font-weight: bold;
    margin-bottom: 0px;
}

.title-icon-container .icons-container .difference-gender-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.title-icon-container .icons-container .difference-gender-container-women{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: 14px;
}


.title-icon-container .icons-container .difference-gender-container-men{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
}
.dashboard .avergare-info-container{
    margin: 30px 0px 0px 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.dashboard .avergare-info-container .scholarship-container{
    width: 50%;
    height: 180px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    margin-right: 10px;
}
.dashboard .avergare-info-container .scholarship-container .info-container{
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    width: 68%;
    justify-content: flex-start;
}

.dashboard .avergare-info-container .scholarship-container .info-container .coin-icon{
    width: 48px;
    height: 48px;
}

.dashboard .avergare-info-container .scholarship-container .info-container h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
}
.dashboard .avergare-info-container .scholarship-container .info-container p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 10px 0px 0px 0px;
    color: rgba(0, 0, 0, 0.6);
}

.dashboard .avergare-info-container .scholarship-container .options-icons{
    display: flex;
    flex-direction: column;
    width: 10% !important;
    justify-content: space-around;
    align-items: flex-end;
    margin-left: 10px;
    height: 40%;
}

.dashboard .avergare-info-container .scholarship-container .options-icons .info-icon{
    width: 18px;
    height: 18px;
}

.dashboard .avergare-info-container .scholarship-container .options-icons .download-icon{
    width: 19px;
    height: 19px;
}

.dashboard .avergare-info-container .scholarship-container  .title-container{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}


.dashboard .avergare-info-container .scholarship-container .title-container .top-uniersities-container{
    overflow-y: scroll;
}

.dashboard .avergare-info-container .scholarship-container .title-container .title-top{
    margin: 0px !important;
}

.dashboard .avergare-info-container .scholarship-container .title-container .top-universities{
    font-weight: 500;
    font-size: 14px;
    margin: 0 !important;
    color: #121213;
}


.dashboard .avergare-info-container .universities-container .universities-title-container{
    height: 100%;
    padding: 10px 0px 10px 0px;
    justify-content: center;
}

.dashboard .avergare-info-container .universities-container{
    width: 50%;
    margin-left: 10px;
    margin-right: 0px;
}

.dashboard .avergare-info-container .scholarship-container .info-universities-container{
    width: 75%;
}


.dashboard .avergare-info-container .scholarship-container .options-university{
    width: 15%;
    align-items: flex-end;
    margin-left: 10px;
    height: 100%;
    justify-content: space-between;
    padding: 15px 0px 20px 0px;
}

.dashboard .avergare-info-container .scholarship-container .options-university .pagination-icon{
    width: 19px;
    height: 19px;
    color: grey;
    margin-right: 2px;
}

.dashboard .avergare-info-container .scholarship-container .options-university .pagination-icon:hover{
    cursor: pointer;
}

.dashboard .dashboard-row .intern-status-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    margin: 30px 0px 0px 6px;
}

.dashboard .dashboard-row .intern-status-container .intern-status{
    margin: 0px 10px 0px 0px !important;
}

.dashboard .dashboard-row .intern-status-container .total-interns{
    margin: 0px 0px 0px 10px !important;
}

.dashboard  .title-search-bar .title-container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.dashboard  .title-search-bar .title-container h5{
    font-weight: lighter;
    margin: 5px 0 0 15px;
    color: darkblue;
    font-size: 1.15em;
    font-weight: 400;
}

.dashboard .date-title {
    font-size: 18px;
}

.dashboard .arrow-up{
    color: #25EB51;
    margin-right: 5px;
}

.dashboard .arrow-down{
    color: #ED5A6C;
    margin-right: 5px;
}

.dashboard .total-percent{
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1366px){
    .dashboard .total-percent{
        justify-content: left;
    }   
}


.dashboard .loading-screen{
    margin: 0 auto !important;
}

.dashboard .arrow-up{
    color: #25EB51;
    margin-right: 5px;
}

.dashboard .arrow-down{
    color: #ED5A6C;
    margin-right: 5px;
}

.dashboard .total-percent{
    display: flex;
    justify-content: space-evenly;
}

.dashboard .download-loading-icon{
    width: 19px;
    height: 19px;
    margin-right: 5px;
    color: #FAFAFA;
}

.dashboard .intern-redirect-container{
    margin: 30px 0px 30px 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dashboard .intern-redirect-container .redirect-container{
    width: 50%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
}

.dashboard .intern-redirect-container .start-container{
    margin-right: 10px;
}

.dashboard .intern-redirect-container .middle-container{
    margin-right: 10px;
    margin-left: 10px;
}

.dashboard .intern-redirect-container .end-container{
    margin-left: 10px;
}

.dashboard .intern-redirect-container .redirect-container .info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 10%;
}

.dashboard .intern-redirect-container .redirect-container .info-container .people-icon{
    width: 48px;
    height: 48px;
}

.dashboard .intern-redirect-container .redirect-container .info-container .info-icon{
    width: 19px;
    height: 19px;
    display: none;
}

.dashboard .intern-redirect-container .redirect-container .info-container .title-container{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.dashboard .intern-redirect-container .redirect-container .info-container .title-container h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.dashboard .intern-redirect-container  .info-container .title-container p{
    margin: 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #717171;
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon{
    margin-right: 10%;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon .pagination-icon{
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0);
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon .pagination-icon:hover{
    cursor: pointer !important;
}

.dashboard .manual-btn{
    background: linear-gradient(270deg, #3DD7DA 0%, #1D8BE8 100%);
    border-radius: 9999px;
    padding: 8px 20px;
    grid-gap: 8px;
    gap: 8px;
    width: 150px;
    height: 40px;
    border: none;
}

.dashboard .manual-container{
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 45px;
    margin-left: 6px;
}

.dashboard .manual-button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    
    padding: 0;
}

.dashboard .manual-container .manual-button-container .manual-btn .manual-icon{
    width: 21px;
    height: 24px;
    margin-right: 3px;
}

@media only screen and (max-width: 1366px){
    .dashboard .total-percent{
        justify-content: left;
    }   
}


@media only screen and (max-width: 1300px){
    .dashboard .dashboard-row .intern-status-container{
        flex-direction: column;
    }
    .dashboard .dashboard-row .intern-status-container .intern-status{
        margin: 0px!important;
    }
    
    .dashboard .dashboard-row .intern-status-container .total-interns{
        margin: 30px 0px 0px 0px !important;
    }    
}

@media only screen and (max-width: 1085px){

    .dashboard .avergare-info-container .scholarship-container{
        height: 200px;
    }
    .dashboard .avergare-info-container .scholarship-container .options-icons{
        flex-direction: row;
        margin-left: 0px !important;
        width: 100% !important;
        justify-content: flex-end;
        margin-top: 0px !important;
        height: 20px;
    }

    .dashboard .avergare-info-container .scholarship-container{
        flex-direction: column-reverse;
        padding: 10px;
    }

    .dashboard .avergare-info-container .scholarship-container .info-container{
        margin-bottom: 20px;
    }

    .dashboard .avergare-info-container .scholarship-container .options-icons .download-icon{
        margin-left: 10px;
    }
    
    .dashboard .avergare-info-container .scholarship-container .info-universities-container{
        height: 73%;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .options-university{
        height: 30%;
    }
    
}

@media only screen and (max-width: 995px){

    .dashboard .avergare-info-container .scholarship-container{
        height: 240px;
    }

    .dashboard .avergare-info-container .scholarship-container .info-container{
        flex-direction: column-reverse;
        padding: 10px;
        justify-content: center;
        margin-bottom: 0px;
        align-content: center;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .info-universities-container{
        height: 85%;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .options-university{
        height: 15%;
    }

    .dashboard .intern-redirect-container{
        flex-direction: column;
        margin-top: 0;
    }

    .dashboard .intern-redirect-container .redirect-container{
        width: 100%;
        margin: 0;
    }

    .dashboard .intern-redirect-container .start-container{
        margin: 20px 0px 0px 0px;
    }

    .dashboard .intern-redirect-container .middle-container{
        margin: 30px 0px 30px 0px;
    }

    .dashboard .intern-redirect-container .end-finish{
        margin: 30px 0px 0px 0px;
    }

}

@media only screen and (max-width: 1280px){
    .doughnut-container{
        flex-direction: column
    }
}

.loading-screen-container .modal-content{
    width: 120px;
    margin: 0 auto;
    border: none;
    background-color: transparent;
}

.cssload-thecube {
	width: 73px;
	height: 73px;
	margin: 0 auto;
	margin-top: 49px;
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
		-o-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(7,211,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
	animation: cssload-fold-thecube 2.76s infinite linear both;
		-o-animation: cssload-fold-thecube 2.76s infinite linear both;
		-ms-animation: cssload-fold-thecube 2.76s infinite linear both;
		-webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
		-moz-animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
		-o-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		-webkit-transform-origin: 100% 100%;
		-moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
		-o-transform: scale(1.1) rotateZ(90deg);
		-ms-transform: scale(1.1) rotateZ(90deg);
		-webkit-transform: scale(1.1) rotateZ(90deg);
		-moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
		-o-transform: scale(1.1) rotateZ(180deg);
		-ms-transform: scale(1.1) rotateZ(180deg);
		-webkit-transform: scale(1.1) rotateZ(180deg);
		-moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
		-o-transform: scale(1.1) rotateZ(270deg);
		-ms-transform: scale(1.1) rotateZ(270deg);
		-webkit-transform: scale(1.1) rotateZ(270deg);
		-moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
		-o-animation-delay: 0.35s;
		-ms-animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
		-moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
		-o-animation-delay: 0.69s;
		-ms-animation-delay: 0.69s;
		-webkit-animation-delay: 0.69s;
		-moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
		-o-animation-delay: 1.04s;
		-ms-animation-delay: 1.04s;
		-webkit-animation-delay: 1.04s;
		-moz-animation-delay: 1.04s;
}


.progress {
	background-color:  rgba(255, 255, 255, 0.5);
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 25px !important;
	width: 98%;
	font-weight: bold;
	box-sizing: border-box;
}

.progress-done {
	background: linear-gradient(to left,  rgba(7,210,204,1), rgba(35,234,87,1));
	box-shadow: 0 3px 3px -5px rgba(7,210,204,1), 0 2px 5px rgba(35,234,87,1);
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.4s;
	box-sizing: border-box;
}
.interns {
    width: 100%;
    min-height: 85vh !important;
    font-family: "Mulish", 'Verdana', 'Tahoma';
}

.interns .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.interns .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.interns #addIntern{
    float: left;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 30px;
    font-weight:100;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    cursor: pointer;
}
.interns #addIntern svg {
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}

.interns .title-search-bar{
    display: flex;
    flex-direction: row;
}

.interns .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.interns .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.interns .search{
    float: left;
    margin-left: 15px;
}

.interns .check{
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.interns .search-container{
    width: 400px !important;
    margin: 0;
    float: left;
}

.interns .search-container-global{
    width: 200px !important;
    margin: 0;
    float: left;
}

.interns .search-btn{
    width: 50px !important;
    margin: 0 auto;
    height: 100%;
}

.interns .search-container-global input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0;
}
.interns .search-container-global input:focus{
    outline: none !important;
    box-shadow: none;
}

.interns .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}
.interns .search-icon:hover{
    color: darkgreen;
}
.interns .search-container input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0;
}
.interns .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}

.clearfix { overflow: auto;  display: inline-block; }
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.interns .alter-table .loadCSV{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 15px;
    border-radius: 25px;
    height: 50px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
}
.interns .alter-table .loadCSV svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.interns .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
    margin-right: 20px;
}

.interns .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.interns .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.interns .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.interns .search-bar-container .show{
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
}

.interns input{
    background-color: #eceef1;
    border-radius: 10px;
}
.time-picker{
    background-color: #eceef1 !important;
    border-radius: 10px !important;
    font-weight: bolder !important;
}
.pop-up{
    border-radius: 10px !important;
    background-color: rgb(41, 190, 38) !important;
}
.interns select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.interns label{
    font-weight: bold;
}

.interns .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.interns .form-label{
    color: black;
    font-size: 15px;
}

.interns .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.interns .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-intern form{
    width: 95%;
}

.checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.new-intern .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.new-intern .title-container svg{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 20px;
    cursor: pointer;
}

.new-intern h4{
    display: inline-block;
    color: #222 !important;
    font-size: 2em;
}

.new-intern a{
    color: #1B3769;
}

.new-intern label{
    color: #333 !important;
    margin: 0 0 1px 10px;
    font-weight: 600 !important;
}

.new-intern .input, .new-intern input, .new-intern .rw-widget-container{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
}

.new-intern .btn-container .btn{
    display: inline-block;
    border-radius: 20px;
    font-weight: 600;
}
.new-intern .btn-container .btn-cancel{
    background: white !important;
    color: #eb697b !important;
    border: 1px solid #eb697b !important;
    box-shadow: none !important;
    margin-right: 10px !important;
}

.new-intern .btn-container .btn-confirm{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}

.unsuscribe-intern-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.unsuscribe-intern-modal .modal-content{
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.85);
}

.unsuscribe-intern-modal .modal-header{
    text-align: left;
    border: none;
    color: black;
    padding-top: 25px;
}

.unsuscribe-intern-modal .description-input, .unsuscribe-intern-modal select, .unsuscribe-intern-modal .rw-widget-picker{
    border: 1px solid #ddd !important;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 3px 3px 3px #ccc;
    width: 100%;
    height: 60px;
}

.unsuscribe-intern-modal .btn-container .col{
    margin-top: 20px;
}
.unsuscribe-intern-modal .btn{
    display: inline-block !important;
    margin: 0 !important;
    border-radius: 20px !important;
    width: 100%;
}
.unsuscribe-intern-modal .cancel-btn{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    margin-right: 5px !important;
}
.unsuscribe-intern-modal .confirm-btn{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}
.unsuscribe-intern-modal .delete-btn{
    background: rgb(238,120,134) !important;
    background: linear-gradient(90deg, rgba(238,120,134,1) 0%, rgba(234,134,125,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}

.unsuscribe-intern-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.unsuscribe-intern-modal small{
    color: #1B3769;
    margin: auto;
}

.unsuscribe-intern-modal label{
    font-size: 14.5px;
}


.verify-interns-docs .form-overflow {
    height: 75vh;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.verify-interns-docs .profile-picture{
    width: 150px;
    height: 150px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}

.verify-interns-docs .profile-photo-camera{
    position: absolute;
    right: -9px !important;
    top: -8px;
}

.profile-photo svg{
    color: rgb(7,210,204);
}

.verify-interns-docs .form-control-file{
    display: none;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.verify-interns-docs .profile-picture svg:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs .row-selection a{
    margin-right: 10px;
}

.verify-interns-docs .row-selection a:hover{
    color: black;
}

.verify-interns-docs .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.verify-interns-docs .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.verify-interns-docs a{
    color: #1B3769;
}

.verify-interns-docs a:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.verify-interns-docs h6{
    font-weight: bold;
    font-size: medium;
    margin: 0 0 15px 0;
    color: #1B3769;
}

.verify-interns-docs .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.verify-interns-docs .modal-header{
    display: flex;
    flex-direction: column;
}

.verify-interns-docs p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}


.verify-interns-docs small{
    color: #1B3769;
    margin: auto;
}

.verify-interns-docs input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs .form-label{
    color: black;
    font-size: 15px;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
}

.verify-interns-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.verify-interns-docs .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.verify-interns-docs .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-cc-modal .modal-content{
    background-color: rgba(250, 250, 250, 0.9);
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
    border-radius: 20px;
    color: #535A63 !important;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.new-cc-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.modal-header-styled{
    font-weight: 700;
    color: #121213;
    border: none !important;
    margin: 5px 5px 15px 5px;
    font-size: 18px !important;
}

.new-cc-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.input-cc{
    background: #FAFAFA;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
    border-radius: 15px !important;
    height: 50px !important;
}

.new-cc-modal .form-check input[type=checkbox]{
    width: 16px;
    height:  16px;
}

.center-buttons{
    margin: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}


.new-cc-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 25px !important;
    margin: auto;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40% !important;
}

.new-cc-modal .btn-danger{
    background-image: linear-gradient(to right, white 0.1%, gray 100%);
    border: none;
    border-radius: 25px !important;
    margin: auto;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40% !important;
}

.new-cc-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.interns-list{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 65vh !important;
}

.interns-list .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.interns-list .options a{
    color: #1B3769;
    margin: 0 0 0 3px;
}
.interns-list-options .status-filter{
    color: rgb(245, 245, 245);
    padding: 3px;
    margin: 0px;
    border-radius: 20px;
}

.interns-list .options a:hover{
    color: rgb(46, 243, 41);
}

.interns-list .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    width: 100%;
    height: 75vh;
    margin-bottom: 30px !important;
}

.interns-list .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 250px;
    height: 260px;
    box-shadow: 3px 5px 5px #d7d7d7;
}

.interns-list .card .card-body .profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    position: relative;
    margin: 10px;
}

.interns-list .card .card-body .profile-photo .profile-photo-camera {
    position: absolute;
    right: -8px;
    top: -10px;
    font-size: 22px;
}

.interns-list .card .form-control-file{
    display: none;
}

.interns-list .card .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.interns-list .card .form-file-label:hover{
    color: rgb(46, 243, 41);
}

.interns-list .card .card-body .card-options svg:hover{
    color: rgb(46, 243, 41);
}

.interns-list .pagination-container{
    display: flex;
    flex-direction: row;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 0 0 0;
    float: right;
    padding: 0;
}

.interns-list .pagination-container li{
    border: none !important;
}

.interns-list .pagination-container li a{
    margin: 10px;
}

.interns-list .pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.interns-list .pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.interns-list .pagination-container .selected{
    font-weight: bolder;
    color: white;
}

.interns-list-options {
    display: flex;
    flex-direction: row;
    margin-right: 10px !important;
}

.interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.interns .intern-list-table{
    max-height: 65vh;
    margin-top: 15px;
}

.interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.rotate{
    -webkit-animation: rotate 1.5s linear infinite;
            animation: rotate 1.5s linear infinite; 
}

.manage-departments a{
    color: #1B3769;
    font-size: 18px;
    margin: 0 0 0 5px;
}

.manage-departments .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.manage-departments .title a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 20px;
}

.manage-departments  a:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .title svg{
    margin: 8px 5px 0 0;
}

.manage-departments p{
    font-weight: bold;
    font-size: 14px;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.manage-departments .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
}

.manage-departments button {
    background: none;
    background-color: transparent;
    border: 0;
}

.scroll{
    max-height: 65vh !important;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden !important;
    margin-bottom: 100px;
}

.react-time-picker {
    display: inline-flex;
    position: relative;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    box-sizing: border-box;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    height: 38px;
    width: 200px;
  }
  .react-time-picker__inputGroup {
    min-width: calc((6px * 3) +  0.70em * 6  +  0.217em * 4);
    flex-grow: 1;
    box-sizing: content-box;
  }
  .react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-time-picker__inputGroup__input {
    min-width: 0.74em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    padding-left: 12px;
    padding-right: 12px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px +  0.54em);
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-time-picker__button svg {
    display: inherit;
  }

  .center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

.filter-button{
    text-decoration: none !important;
    color: #1B3769;
}
.filter-button:hover{
    cursor: pointer !important;
    color: rgb(41, 190, 38);
}

.interns-list{
    width: 97%;
}

.interns-list #table{
    margin: 30px 0 !important;
}

.interns-list #table{
    margin: 0px !important;
}

.table-container-list .table-header{
    margin: 0;
    margin-bottom: 0px !important;
}
.table-container-list #table{
    margin: 0 !important;
}

.interns-list .table-header .col{
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #555;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.interns-list .table-header .col span{
    color: black;
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 5px;
}
.span-text{
    color: black;
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 5px
}

.interns-list .table-header a{
    color: #555;
}

.interns-list .table-content .row{
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
    margin: 0;
    margin-bottom: 5px !important;
    padding: 10px 0;
}

.interns-list .table-content .profile-photo{
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 0;
    margin-right: 10px;
}

.interns-list .table-content .col{
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.table-container-list .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em !important;
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
    cursor: pointer !important;
}
.table-container-list  .dropdown-toggle:active, .interns-list .table-content .dropdown-toggle:focus{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.table-container-list  .dropdown-toggle::after{
    display: none;
}

.table-container-list  .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.table-container-list  .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.table-container-list  .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.table-container-list  .no-select{
    color: #eb697b;
    cursor: default;
}
.table-container-list  .no-select:hover,
.table-container-list  .no-select:active,
.table-container-list  .no-select:focus
{
    color: #eb697b;
    cursor: default;
    background: transparent !important;
}

.table-container-list .dropdown-item .row-in-drop,
.table-container-list .dropdown-item .row-in-drop .col{
    margin: 0;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
}

.table-container-list  .dropdown-item .row-in-drop .icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
}

.table-container-list .dropdown-item .row-in-drop .col svg{
    margin: 0 5px;
}

.table-container-list-interns{
    height: 60vh !important;
}

.verify-interns-docs .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.verify-interns-docs .table-container {
    overflow-x: scroll !important;
    display: inline-block;
    width: 95%;
    margin-top: 30px;
}

.verify-interns-docs .table{
    width: 100% !important;
    border: none;
    margin: 0;
    border-collapse:separate;
    border-spacing:0 10px;
}

.verify-interns-docs .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: bold;
}

.center-text{
    text-align: center !important;
}

.document-name{
    margin: 0 auto !important;
}

.verify-interns-docs .table tbody tr{
    background: rgba(255, 255, 255, 0.6)!important;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.10);
    border-radius: 5px; 
}

.verify-interns-docs .table tbody tr td:first-child { border-top-left-radius: 5px; }
.verify-interns-docs .table tbody tr td:last-child { border-top-right-radius: 5px; }
.verify-interns-docs .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.verify-interns-docs .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.verify-interns-docs .table thead tr th{
    border: none;
}

.verify-interns-docs .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.verify-interns-docs .table tbody tr td svg{
    font-size: 18px;
}

.verify-interns-docs .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.verify-interns-docs .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.on-click-event{
    cursor: pointer
}
.on-click-event:hover{
    cursor: pointer
}

.table-container-list {
    overflow-x: scroll !important;
    display: inline-block;
    width: 100%;
    min-height: 65vh !important;
}
.table-container-list::-webkit-scrollbar {
    height: 12px
}

.th-text{
    text-align: left !important;
}

.profile-picture-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
}

.department-text{
    color: #0025E5 !important;
    font-size: 15px !important;
    font-weight: bold;
    margin-right: 5px;
    margin: 0px 5px 0px 0px !important;
    padding: 0 !important;
}

.add-deparment-containter{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.center-modal-text{
    display: flex ;
    justify-content: center;
}

.circle-div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0;
}

.center-status-signature{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.all-signatures {
    background: linear-gradient(to right, #06D1CD 0, #25EB51 33%, #E4D270 33%, #E4D270 66%, #ED5A6C 66%, #E86C60 100%) !important;
}
.signed-signatures {
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%) !important;
}
.pending-signatures{
    background: #E4D270 !important;
}

.red-signatures{
    background: linear-gradient(90deg, #ED5A6C 0%, #E86C60 100%) !important;
}

.search-container-interns{
    display: flex !important;
    flex-wrap: wrap;
}

.mt-15{
    margin-top: 15px !important;
}

.reload-table-interns{
    margin-left: 10px;
    display: inline-flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
    margin-right: 20px;
}

@-webkit-keyframes rotate{
    to{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate{
    to{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}



@media only screen and (max-width: 1484px){
    .table-container-list-interns{
        min-height: 55vh !important;
        height: 45vh !important;
    }

    .interns .intern-list-table{
        max-height: 55vh;
        margin-top: 15px;
    }

    .interns .interns-list{
        min-height: 60vh !important;
    }
}


@media only screen and (max-width: 1620px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(4, 1fr);
        height: 100% !important;
        margin-bottom: 150px !important;
    }
}

@media only screen and (max-width: 1337px) {
    .manage-departments .grid {
        grid-template-columns: repeat(2, 1fr);
        height: 100% !important;
        margin-bottom: 50px !important;
    }
}

@media only screen and (max-width: 1300px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(3, 1fr);
        height: 100% !important;
        margin-bottom: 30px !important;
    }
}


.training-intern-modal>.modal-dialog{
    
}

@media only screen and (max-width: 991px) {
    .verify-interns-docs .modal-dialog-centered{
        max-width: 95%;
        width: 100%;
    }
}

@media only screen and (max-width: 845px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(2, 1fr);
        height: 100% !important;
        margin-bottom: 20px !important;
    }

    .interns-list{
        height: 68vh;
    }
}

@media only screen and (max-width: 540px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(1, 1fr);
        height: 100% !important;
        margin-bottom: 10px !important;
    }
}

.video-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}


.dd-wrapper-noti .dd-header-noti{
    background-color: transparent !important;
    border: none;
    box-shadow: 0px 0px 0px rgba(215, 215, 215, 1) !important;
    outline-style: none;
    font-size: 15px;
    text-align: left;
    padding: 10px 15px;
    color: #666;
    z-index: 1;
}

.dd-wrapper-noti .dd-header-noti div{
    display: inline-block;
    font-size: 16px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.dd-wrapper-noti .dd-header-noti p{
    font-weight: normal;
    margin: auto;
}

.dd-wrapper-noti .dd-header-title{
    margin: 0;
    padding: 0;
}

.dd-wrapper-noti ul{
    -webkit-margin-after: 20 !important;
            margin-block-end: 20 !important;
    -webkit-margin-before: 0 !important;
            margin-block-start: 0 !important;
    -webkit-margin-end: 0 !important;
            margin-inline-end: 0 !important;
    -webkit-margin-start: 0 !important;
            margin-inline-start: 0 !important;
    margin: 0;
    padding: 0;
    width: 100px !important;
}

.dd-wrapper-noti .dd-list{
    position: absolute !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    margin-top: 10px;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0px;  
    z-index: 1;
    width: 400px !important;
}
.dd-wrapper-noti .dd-list .nav-item{
    width: 48% !important;
    border-radius: 0px !important;
}
.dd-wrapper-noti .dd-list li{
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100% !important;
    box-shadow: none !important; 
}

.dd-wrapper-noti .dd-list > *{
    color: rgb(0, 0, 0);
    font-weight: lighter;
    font-size: 18px;
}

.dd-wrapper-noti .dd-list li label{
    margin: 0;
    padding: 0;
}

.dropdown-container{
    word-wrap: break-word;
    box-shadow: none; 
}

.lu-container{
    overflow-y: scroll;
    max-height: 80vh;
    background-color: white;
    box-shadow: none !important;
    padding-bottom: 1rem !important;
}

.toast-border{
    border: none !important;
    background-color: white !important;
}
.card-text{
   font-size: small; 
   word-wrap: break-word;
}
hr .solid{
    width: 100%;
}
.text-title{
    font-size: 1rem !important;
    text-align: start;
    margin: 0;
    color: #1B3769;
    margin-bottom: .2rem !important;
}
.card-divider{
    margin-bottom: 2% !important;
    margin-top: 2% !important;
}
.footer-text{
    font-weight: bold;
    text-decoration: none !important;
    font-size: .7rem !important;
    text-align: start;
    margin-bottom: 0;
    color: #1B3769;
}
.dd-wrapper-noti .card-body{
    padding: 1rem .6rem 0rem .6rem !important;
}
.text-description{
    text-align: start !important;
    margin-bottom: -.5rem !important;
    font-size: .8rem;
    font-weight: initial;
    color: rgb(66, 66, 66);
}

.list-end:last-child{
    background-color: aquamarine !important;
}

.tab-title{
    color: #1B3769 !important;
}

@media only screen and (max-width: 1780px){
    .dd-wrapper-noti .dd-list{
        width: 15% !important;
    }
}

@media only screen and (max-width: 1550px){
    .dd-wrapper-noti .dd-list{
        width:35% !important;
    }
}

@media only screen and (max-width: 1380px){
    .dd-wrapper-noti .dd-list{
        width: 19% !important;
    }
}

@media only screen and (max-width: 1280px){
    .dd-wrapper-noti .dd-list{
        width: 20% !important;
    }
}
.layout {
    width: inherit;
    height: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}

.layout .navbar .logoCaintra, .layout .sidebar .logoCaintra{
    height: auto;
}

.layout .navbar{
    border-bottom: rgba(215, 215, 215, 0.5) solid 2px;
}

.layout .navbar .nav-link{
    color: #1B3769;
}

.layout .navbar .mobile-options{
    display: none;
}

.layout .navbar .navbar-brand{
    padding: 15px 0px 10px 20px;
}

.divider{
    border: 1px solid #535A63 !important;
    border-radius: 5px !important;
    margin-left: auto;
    margin-right: auto;
}

.layout .content {
    width: 98%;
    height: 100%;
    margin-top: 10px;
    margin-left: 10px;
    box-sizing: border-box;
}
.layout .page {
    float: right;
    padding: 40px 0 0 30px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.5);
    transition: all .3s;
    padding-bottom: 20px;
    min-height: 95vh;
    margin-left: 1%;
}

.layout .sidebar-container{
    display: inline-block;
    transition: all .3s;
    min-height: 95vh !important;
}

.layout .sidebar {
    border-radius: 15px;
    padding: 15px 5px;
    background-color: rgba(250, 250, 250, 0.65);
    height: 100%;
    box-shadow: 2px 0px 10px 0px rgb(0, 0, 0, 0.050);
    min-height: 95vh !important;
}

.layout .sidebar ul::-webkit-scrollbar{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.layout .sidebar ul::-webkit-scrollbar{
    display: none;
}

.layout .sidebar .profile{
    display: flex;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    border-radius: 25px;
    margin: 25px 0 25px 0;
    padding: 5px;
}

.layout .sidebar .profile .img-container{
    margin-right: 8px;
    padding: 5px;
    border-radius: 50%;
    overflow: hidden;
}
.layout .sidebar .profile .img-container img{
    border-radius: 50%;
}
.layout .sidebar .profile .cont{
    display: inline-block;
}

.layout .sidebar .profile span{
    display: block;
    line-height: 23px;
}

.layout .sidebar .profile .name{
    font-size: 1.1em;
    color: black;
    font-weight: 600;
}

.layout .sidebar .profile .role{
    font-size: .8em;
    color: rgb(132, 134, 132);
    font-weight: 500;
}

.layout .sidebar .profile .company{
    font-size: 1em;
    color: rgb(47, 150, 219);
    font-weight: 500;
}

.layout .sidebar .items{ 
    list-style-type: none;
}

.layout .sidebar li{
    margin-top: 6%;
    text-align: center;
}

.layout .sidebar .items svg{
    margin-right: 10px;
    margin-top: -4px;
}

.layout .sidebar .items a{
    display: inline-flex;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 13px;
    color: #454545;
    font-weight: 400;
    align-items: center;
    justify-content: left;
    padding: 0 15px;
    height: 45px;
    width: 100%;
    text-align: center;
}


.layout .sidebar .items a.mini{
    display: inline-flex;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 13px;
    color: #454545;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 45px;
    width: 45px;
    text-align: center;
}
.layout .sidebar .items a:hover,
.layout .sidebar .items a.mini:hover
{
    text-decoration: none !important;
}
.layout .sidebar .items .selected{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}
.layout .sidebar .items .selected img{
    -webkit-filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
            filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
}

.layout .sidebar #toggle-sidebar{
    font-size: 32px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 15px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 15px !important;
}

.layout .sidebar #toggle-sidebar svg{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    transition: all 0.5s;
    border-radius: 50%;
    padding: 5px;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 5px #666;
}
.layout .sidebar #toggle-sidebar svg.extended{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.dropdown-decoration{
    text-decoration: none !important;
}
.dropdown-decoration:hover{
    text-decoration: none !important;
}

.layout .navbar .see-as-button{
    background: none;
    color: #1B3769;
    border: none;
}

.layout .navbar .show > .see-as-button{
    background: none;
    color: #1B3769;
    border:none;
}

.layout .navbar .see-as-button:active:focus{
    background: none;
    color: #1B3769;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none;
    outline: none;
}

.layout .navbar .dropdown-menu, .show > *{
    z-index: 999;
}

.layout .navbar .dropdown-menu a{
    color: #1B3769;
    font-weight: bold;
}

.layout .navbar .btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
 }

.nav-modal{
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.border-radius-2 div{
    border-radius: 20px !important;
}

.nav-modal .form-companies{
    border-radius: 20px !important; 
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
}

.modal-content{
    width: 920px !important;
}

.unsuscribe-intern-modal .modal-header{
    text-align: left;
    border: none;
    color: black;
    padding-top: 25px;
}

.layout .sidebar .configuration-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.sidebar-display{
    display: flex;
    flex-direction: column;
}

.modal-height-patch{
    height: 90vh !important;
    padding: 30px !important;
}

.version-notes{
    height: 95% !important;
    padding: 10px 10px 10px 18px;

    overflow: auto;
}

.modal-height-patch .buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.modal-height-patch .buttons-container .checkbox-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-right: 20px;
}
.modal-height-patch .buttons-container .checkbox-container p{
    margin-left: 10px; 
    margin-top: 0;
    padding-top: 0px;
    font-weight: bold; 
    font-size: 14px;
}

.modal-height-patch .buttons-container .checkbox-container .checkbox-class{
    margin: 0 !important;
}

.modal-height-patch .buttons-container .default-button{
    margin: 0 !important;
}



@media only screen and (max-width: 991px) {
    .layout .navbar .mobile-options{
        margin: 5% 0 5% 50%;
        display: block;
    }

    .layout .navbar .mobile-options a{
        text-align: end;
        
    }

    .layout .navbar .desktop-options{
        display: none;
    }

    .layout .navbar .navbar-brand{
        padding: 5px 0px 0px 0px;
    }

    .layout .navbar svg{
        margin-right: 5px;
        width: 25px;
    }
    
    .layout .navbar a{
        font-size: 18px;
        padding: 0 0 5px 0;
    }

    .layout .sidebar-container {
        display: none;
    }
}

@media only screen and (max-height: 2000px)  {
    
    .layout .sidebar-container{
        max-height: 90vh !important;
    }
    
    .layout .sidebar {
        max-height: 90vh !important;
    }
}

.version{
    margin-top: 2px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.version2{
    margin-top: -24px !important;
    margin-left: 0px !important;
    color :rgb(178, 179, 178) !important;
    font-weight: bold !important;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1 1;
    
}  

.flex-child:first-child {
    margin-right: 20px;
} 

.myVersion {
    margin-top: -22px;
    color: rgb(132, 134, 132);
    font-weight: 500;
}

.modal-patch-notes-container{
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    
}

@media only screen and (max-width: 1189px){
    .modal-patch-notes-container img{
        width: 90% !important;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.prefecture{
    width: 100%;
    min-height: 85vh !important;
}
.prefecture .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}

.prefecture .search{
    display: flex;
    flex-direction: row;
}

.prefecture .search-icon:hover{
    color: darkgreen;
}
.prefecture .search-container{
    width: 350px !important;
    margin: 0;
    float: left !important;
}
.prefecture .search-container input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0 15 0 0;
}
.prefecture .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}
.prefecture h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
    display: inline-block;
}

.prefecture .company{
    font-size: 18px;
    color: #1B3769;
}

.prefecture h5{
    display: inline-block;
    font-weight: lighter;
    margin: 5px 0 0 15px;
    color: darkblue;
    font-size: 1.15em;
    font-weight: 400;
}

.prefecture .title-search-bar{
    display: flex;
    flex-direction: row;
}

.prefecture .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.prefecture .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.prefecture .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.prefecture .table-container {
    display: flex;
    width: 95%;
}

.table-scroll {
    overflow-x: scroll !important;
}

.header-height {
    height: 120px;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.prefecture .table{
    width: 100%;
    display: inline-block !important;
    border: none;
    margin: 0;
}

.prefecture .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: 400;
}

.prefecture .table tbody tr{
    background: rgba(255, 255, 255, 0.7)!important;
}

.prefecture .table tbody tr td:first-child { border-top-left-radius: 5px; }
.prefecture .table tbody tr td:last-child { border-top-right-radius: 5px; }
.prefecture .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.prefecture .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.prefecture .no-spacer{
    height: 70px !important;
}
.prefecture .spacer{
    background: transparent !important;
    height: 10px !important;
}
.prefecture .table thead tr th{
    border: none;
}

.prefecture .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.prefecture .table tbody tr td svg{
    font-size: 12px;
}

.prefecture .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.prefecture .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.prefecture .filters{
    min-width: 25%;
    max-width: 50%;
}

.prefecture .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0.5rem 0;
}

.prefecture .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}
.prefecture .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}
.prefecture .options .btn svg{
    margin-right: 10px;
}
.prefecture .options > *{
    margin: 0 15px 0 0;
}

.prefecture .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
}

.prefecture .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0 0 0 10px;
}

.prefecture .options label{
    margin: 2.5px 0 0 0;
}

.prefecture .options .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.prefecture .options .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -15px;
}

.change-period .modal-dialog .modal-content{
    background-color: transparent;
    border: none;
}

.change-period .modal-dialog .modal-content .modal-body{
    height: 100%;
    width: 65%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
}

.change-period .modal-dialog .modal-content .modal-body .content{
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.change-period .modal-dialog .modal-content .modal-body .content h5{
    font-weight: bolder;
}

.change-period .modal-dialog .modal-content .modal-body .content p{
    font-weight: bolder;
    padding: 0;
    margin: 1rem 0 1rem 0;
}

.change-period .modal-dialog .modal-content .modal-body .content .default-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 10px;
}

.change-period .modal-dialog .modal-content .modal-body .content form select{
    border-radius: 20px;
    font-size: 15px;
    background-color: #ECEEF1;
    cursor: pointer;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 0.5);
    border: none;
}

.change-period .modal-dialog .modal-content .modal-body .content form select option{
    background-color: #ECEEF1;
}

.prefecture .alter-table{
    display: flex !important;
    align-items: center;
}

.prefecture .columns{
    flex-direction:column;
}

.prefecture .columns .margin{
    margin-top: 5px;
}

.header-container-prefacture{
    width: 95%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.search-container-prefacture{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 15px;
}

.authorize-button-text{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    min-width: 100px;
    display: flex !important;
}
.prefecture .search{
    margin: 0 !important;
    float: left;
}

.prefecture .search input{
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    float: left;
}

.prefecture .search-and-authorize{
    display: flex;
    flex-direction: row;
}

.prefecture .authorize-button{
    display: flex !important;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    padding: 0 30px 0 10px;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 0px 15px 0 0px;
}


.prefecture .authorize-button-period{
    display: inline-block !important;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 7px 20px 0 10px;
    padding: 0 20px;
}


.prefecture .authorize-button-disabled{
    display: inline-block !important;
    background: rgba(255, 255, 255, 0.5) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 0px 20px 0 10px;
    color: #666 !important;
    padding: 0 20px;
}

.prefecture .authorize-button-disabled:hover{
    color: white !important;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}


.prefecture .authorize-button svg{
    font-size: 1.4em !important;
    margin: 0 10px;
}

.prefecture .costs-table{
    display: inline-block !important;
    width: auto;
    margin-bottom: 20px;
    margin-right: 50px;
    border-radius: 10px;
}

.prefecture .search-and-date{
    margin-top: 10px;
}
.prefecture .costs-table td{
    text-align: left !important;
}

.prefecture .search-and-date .search-bar-container{
    margin-left: auto;
    display: flex;
}

.prefecture .search-and-date .search-bar-container .search-bar{
    margin-left: auto;
}

.prefecture .search-and-date .period-container{
    align-self: start;
}
.interns-list-options {
    float: left;
    left: 68%;
}

.prefecture .interns-list-options{
    margin-right: 50px !important;
}

.prefecture .interns-list-options .selected{
    color: white !important;
}

.prefacture .paginate .interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.prefacture .paginate .interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.paginate {
    display: flex;
}

.prefacture-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.prefacture-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.prefacture-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.prefacture-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.prefacture-modals small{
    color: black;
    margin: auto;
    text-align: center;
}

.prefacture-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.prefacture-modals .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}


.prefacture-modals .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}


.prefacture-modals .btn-danger:hover, 
.prefacture-modals .btn-danger:focus,
.prefacture-modals .btn-danger:active{
    background: white !important;
    border: 1px solid #666 !important;
    color: #666 !important;
    box-shadow: none;
}

.prefacture-modals .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.prefacture-modals .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.prefacture-modals label {
    font-size: 12px;
    margin: 0;
}

.prefacture-modals select {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.records, .records > * {
    transition: none !important;
}
.records .modal-dialog {
    width: 30% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width:none !important;
    position: absolute;
    right: 0px;
}

.records .modal-dialog .modal-content{
    height: 0% !important;
    min-height: 100% !important;
    border-radius: 0 !important;
    background-color: white !important;
    overflow-y: auto;
}

.records .records-title{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d7d7d7;
}

.records .records-title svg{
    margin: 5px 5px 0 0;
    font-size: 20px;
}

.records .records-title p{
    font-size: 20px;
    font-weight: bold;
}

.records .record {
    margin: 10px 0 0 0;
    border-bottom: 1px solid #d7d7d7;
}

.records .record .message{
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 8px 0;
}

.records .record .author{
    color: #1858CA;
    font-size: 13px;
    margin: 0 0 0 0;
}

.records .record .date{
    color: gray;
    font-size: 13px;
    margin: 0 0 8px 0;
}

.set-text-center{
    text-align: center !important;
}
.loading-screen-container .modal-content{
    width: 120px;
    margin: 0 auto;
    border: none;
    background-color: transparent;
}

.cssload-thecube {
	width: 73px;
	height: 73px;
	margin: 0 auto;
	margin-top: 49px;
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
		-o-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(7,211,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
	animation: cssload-fold-thecube 2.76s infinite linear both;
		-o-animation: cssload-fold-thecube 2.76s infinite linear both;
		-ms-animation: cssload-fold-thecube 2.76s infinite linear both;
		-webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
		-moz-animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
		-o-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		-webkit-transform-origin: 100% 100%;
		-moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
		-o-transform: scale(1.1) rotateZ(90deg);
		-ms-transform: scale(1.1) rotateZ(90deg);
		-webkit-transform: scale(1.1) rotateZ(90deg);
		-moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
		-o-transform: scale(1.1) rotateZ(180deg);
		-ms-transform: scale(1.1) rotateZ(180deg);
		-webkit-transform: scale(1.1) rotateZ(180deg);
		-moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
		-o-transform: scale(1.1) rotateZ(270deg);
		-ms-transform: scale(1.1) rotateZ(270deg);
		-webkit-transform: scale(1.1) rotateZ(270deg);
		-moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
		-o-animation-delay: 0.35s;
		-ms-animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
		-moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
		-o-animation-delay: 0.69s;
		-ms-animation-delay: 0.69s;
		-webkit-animation-delay: 0.69s;
		-moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
		-o-animation-delay: 1.04s;
		-ms-animation-delay: 1.04s;
		-webkit-animation-delay: 1.04s;
		-moz-animation-delay: 1.04s;
}
.purchase-order{
    margin-bottom: 1rem;
    border: none;
    box-shadow: none;
}

.purchase-order .input-group {
    border-radius: 15px;
    overflow: auto !important;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    color: #12121307;
}

.input-icon-text{
    border-radius: 15px 0px 0px 15px !important;
    border-right: none !important;
}
.input-icon{
    border-radius: 0px 15px 15px 0px !important;
    border-left: none !important;
    background: rgba(250, 250, 250, 0.75) !important; 
}
.text-column{
    margin: 1rem 1.5rem 0rem 1.5rem;
}
.purchase-order-title{
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    padding-left: 1rem;
}

.purchase-padding{
    padding: 0.5rem !important;
}
.float-left{
    float: left !important;
}

.contpaq-margin {
    margin: -10px 10px -20px 10px;
}

.contpaq-margin .margin-buttom {
    margin-right: 5px !important;
}

.new-contpaq-modal .modal-content{
    background-color: rgba(250, 250, 250, 0.9);
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
    border-radius: 20px;
    color: #535A63 !important;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.new-contpaq-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 45px 8px 45px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-contpaq-modal .btn-danger{
    border: 1px solid #666 !important;
    color: #666;
    background: transparent;
    border: none;
    border-radius: 20px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 45px 8px 45px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-contpaq-modal .btn-danger:hover,
.new-contpaq-modal .btn-danger:focus,
.new-contpaq-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.new-contpaq-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.title-video-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.video-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.video-text{
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #1B3769;
    padding-left: 10px;
    font-weight: 400;
}

.compaq-buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 15px;
}

.video-player{
    width: 100% !important;
    height: 100% !important; 
} 

 .modal-height {
    height: 55% !important;
    max-height: 55% !important;
}

.modal-width {
    width: 55% !important;
    max-width: 55% !important;
}

.purchase-padding .manually-invoice-row{
    width: 100%;
    margin-left: 0;
}

.manually-invoice-row .custom-label{
    width: 140px;
    margin-left: 0;
}

.manually-invoice-row .custom-group{
    border-radius: 10px !important;
}

.manually-invoice-row .custom-input{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px !important;
    width: 320px !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.manually-invoice-row .money-radius{
    border-radius: 10px 0px 0px 10px;
}

.manually-invoice-row .money-input{
    border-radius: 0px 10px 10px 0px !important; 
}

.manually-invoice-row .custom-form{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 70px 0px 70px;
}

.manually-invoice-row .money-input:focus{
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0.5px 0px 1px 0px rgba(209, 209, 209, 0.5) !important;
}
.manually-invoice-row .custom-input:focus{
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 0px 1px 0px rgba(209, 209, 209, 0.5) !important;
}

@keyframes cssload-fold-thecube {
	0%, 10% {
		-webkit-transform: perspective(136px) rotateX(-180deg);
		        transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-webkit-transform: perspective(136px) rotateX(0deg);
		        transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-webkit-transform: perspective(136px) rotateY(180deg);
		        transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

@-webkit-keyframes cssload-fold-thecube {
	0%, 10% {
		-webkit-transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-webkit-transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-webkit-transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

:root {
--black: rgba(0, 0, 0, 1);
--deep-sapphire: rgba(8, 46, 109, 1) ;
--bunker: rgba(17, 18, 19, 1) ;
--mountain-mist: rgba(147, 149, 152, 1) ;
--silver: rgba(192, 192, 192, 1) ;
--quill-gray: rgba(211, 211, 211, 1) ;
--carnation: rgba(237, 90, 108, 1) ;
--alabaster: rgba(250, 250, 250, 1) ;
--white: rgba(255, 255, 255, 1) ;
--font-size-xxs: 7px;
--font-size-xs: 8px ;
--font-size-s: 9px;
--font-size-m: 10px;
--font-size-l: 12px;
--font-family-roboto: "Roboto";
} 

/* Classes */
.roboto-bold-black-8px {
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 8px !important;
    font-size: var(--font-size-xs) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.border-1px-black {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 1);
    border-color: var(--black) ;
}

.roboto-bold-black-10px {
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-bold-black-10px-2 {
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family: "Roboto" !important;
    font-family: var(--font-family-roboto) !important;
    font-size: 9.1px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    margin-top: -7px !important;
    margin-bottom: -5px !important;
    margin-left: -1px !important;
}

.roboto-normal-black-8px {
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 8px !important;
    font-size: var(--font-size-xs) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.border-1px-silver {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(192, 192, 192, 1);
    border-color: var(--silver) ;
}

.roboto-medium-mountain-mist-10px {
    color: rgba(147, 149, 152, 1) !important;
    color: var(--mountain-mist) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 500 !important;
    font-style: normal !important;
}

.roboto-normal-white-10px {
    color: rgba(255, 255, 255, 1) !important;
    color: var(--white) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size:10px !important;
    font-size:var(--font-size-m) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.roboto-bold-bunker-10px {
    color: rgba(17, 18, 19, 1) !important;
    color: var(--bunker) !important;
    font-family: "Roboto" !important;
    font-family: var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-normal-black-10px {
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family: "Roboto" !important;
    font-family: var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}


.roboto-bold-alabaster-10px {
    color: rgba(250, 250, 250, 1) !important;
    color: var(--alabaster) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-bold-carnation-10px {
    color: rgba(237, 90, 108, 1) !important;
    color: var(--carnation) !important;
    font-family:"Roboto" !important;
    font-family:var(--font-family-roboto) !important;
    font-size: 10px !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-normal-black-7px{
    color: rgba(0, 0, 0, 1) !important;
    color: var(--black) !important;
    font-family: "Roboto" !important;
    font-family: var(--font-family-roboto) !important;
    font-size:7px !important;
    font-size:var(--font-size-xxs) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

.factura{
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    background-color: var(--white) ;
    display: flex;
    flex-direction: column;
    height: 820px;
    padding: 15px 0;
    width: 612px;
}

.flex-row{
    align-items: flex-start;
    display: flex;
    height: 99px;
    margin-left: 12px;
    min-width: 560px;
}

.flex-row-1 {
    align-items: center;
    display: flex;
    height: 98px;
    min-width: 393px;
}

.logo {
    height: 70px;
    margin-bottom: 6px;
    object-fit: cover;
    width: 70px;
}

.flex-col{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 13px;
    min-height: 98px;
    width: 310px;
}

.flex-row-2 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    min-width: 262px;
}

.emisor {
    align-self: flex-end;
    letter-spacing:0;
    min-height: 12px;
    min-width: 35px;
}

.documento-no-oficial{
    color: rgba(237, 90, 108, 1);
    color: var(--carnation);
    font-family:"Roboto";
    font-family:var(--font-family-roboto);
    font-size: 12px;
    font-size: var(--font-size-l) ;
    font-weight: 700;
    letter-spacing: 0;
    margin-left: 86px;
    min-height: 14px;
}

.flex-col-item{
    letter-spacing: 0;
    min-height: 12px;
}

.rfc-cit4402264-p8 {
letter-spacing:0;
margin-top: 5px;
min-height: 12px;
}

.overlap-group4 {
height: 25px;
position: relative;
width: 150px;
}

.col-obrera {
left: 0;
letter-spacing:0;
position: absolute;
top: 0;
}

.x64010-monterrey-nuevo-len {
left: 0;
letter-spacing:0;
position: absolute;
top: 13px;
}

.flex-col-1 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    min-height: 88px;
    width: 143px;
}
.field-4 {
    align-items: flex-start;
    background-color:rgba(8, 46, 109, 1);
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    min-width: 145px;
    padding: 1px 5px;
}

.factura-1 {
    letter-spacing: 0;
    min-height: 12px;
    text-align: center;
    width: 135px;
}

.field-container{
    align-items: flex-start;
    display: flex;
    margin-top: 2px;
    min-width: 145px;
}

.field {
    align-items: flex-start;
    display: flex;
    height: 16px;
    min-width: 62px;
    padding: 1px 5px;
}

.serie,
.folio,
.fecha,
.hora,
.moneda {
    letter-spacing: 0;
    min-height: 12px;
    text-align: right;
    width: 52px;
}

.field-1 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    margin-left: 2px;
    min-width: 80px;
    padding: 1px 5px;
}

.adp,
.number,
.date,
.text-3
.mxn{
    letter-spacing:0;
    min-height: 12px;
    text-align: center;
    width: 69px;
}

.flex-col-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    min-height: 657px;
    width: 572px;
}

.flex-row-3 {
    align-items: center;
    align-self: flex-end;
    display: flex;
    min-width: 477px;
}

.rgimen-fiscal-603 {
    letter-spacing: 0;
    min-height: 12px;
    min-width: 282px;
}

.field-5 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    margin-left: 50px;
    min-width: 62px;
    padding: 1px 5px;
}

.field-6 {
    align-items: flex-start;
    align-self: flex-start;
    background-color: rgba(8, 46, 109, 1);
    background-color: var(--deep-sapphire);
    display: flex;
    height: 16px;
    margin-top: 9px;
    min-width: 95px;
    padding: 1px 5px;
}
.receptor {
    letter-spacing: 0;
    min-height: 12px;
    width: 85px;
}

.frame-16 {
    align-items: center;
    display: flex;
    height: 97px;
    justify-content: flex-end;
    min-width: 572px;
}

.column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 62px;
    width: 236px;
}

.socio-15586 {
    letter-spacing:0;
    min-height: 12px;
    width: 300px;
}

.celestica-de-monterrey-sa-de-cv {
    letter-spacing:0;
    min-height: 12px;
    width: 228pX;
    margin-top: -5px !important;
    margin-bottom: -5px !important;
}

.rfc-cm0980416-b80 {
    letter-spacing:0;
    margin-top: 10px;
    min-height: 12px;
    width: 120px;
}

.observaciones{
    color: rgba(0, 0, 0, 1);
    color: var(--black);
    font-family:"Roboto";
    font-family:var(--font-family-roboto);
    font-size:9px;
    font-size:var(--font-size-s);
    font-weight: 400;
    letter-spacing:0;
    margin-top: 5px;
    min-height: 11px;
    width: 77px;
}

.column-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    min-height: 77px;
    width: 314px;
}

.ejecutivo-ninguno{
    letter-spacing:0;
    min-height: 12px;
    width: 300px;
}

.efectuar-el-pago-uti{
    letter-spacing: 0;
    margin-top: 10px;
    min-height: 9px;
    width: 182px;
}

.convenio-cie-bbva00 {
letter-spacing:0;
margin-top: 5px;
min-height: 9px;
width: 300px;
}

.referencia{
letter-spacing: 0;
margin-top: 5px;
min-height: 9px;
width: 40px;
}

.la-referencia-es-un{
letter-spacing:0;
min-height: 18px;
width: 304pX;
margin-top: 5px !important;
}

.overlap-group3 {
margin-right: 0;
margin-top: 5px;
position: relative;
width: 574px;
}

.row-1{
    align-items: flex-start;
    background-color: #f8f8f8;
    display: flex;
    height: 28px;
    left: 1px;
    min-width: 572px;
    position: absolute;
    top: 0;
}

.column-container{
    height: 30px;
    margin-left:-1px;
    margin-top: -1px;
    position: relative;
    width: 574px;
}

.column-2 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 52px;
    min-width: 62px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.clave-unidad-sat{
letter-spacing: 0;
min-height: 18px;
text-align: center;
width: 50px;
}

.column-3 {
align-items: flex-start;
display: flex;
height: 30px;
left: 112px;
min-width: 70px;
padding: 4.5px 5px;
position: absolute;
top: 0;
}

.clave-prod-servicio{
letter-spacing: 0;
min-height: 18px;
text-align: center;
width: 58px;
}

.column-4 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 180px;
    min-width: 177px;
    padding: 9px 5px;
    position: absolute;
    top: 0;
}

.descripcin{
    letter-spacing: 9;
    min-height: 9px;
    text-align: center;
    width: 165px;
}

.column-5 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 355px;
    min-width: 61px;
    padding: 2px 5px;
    position: absolute;
    top: 0;
}

.valor-unitario{
    letter-spacing:0;
    min-height: 9px;
    text-align: center;
    width: 49px;
}

.column-container-1 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 574px;
}
.column-container-2 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 87px;
    width: 574px;
}

.column-container-3 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 126px;
    width: 574px;
}

.column-6 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 0;
    min-width: 54px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.text-5 {
    letter-spacing:0;
    min-height: 8px;
    width: 42px;
}

.column-7 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 52px;
    min-width: 62px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.pce-pce {
    letter-spacing: 0;
    min-height: 8px;
    width: 50px;
}

.column-8 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 112px;
    min-width: 70px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.x94131500-organizac {
    letter-spacing:0px;
    min-height: 24px;
    width: 58px;
    line-height:normal;
}

.column-9 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 180px;
    min-width: 177px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.adp-administracion-de-becas {
    letter-spacing:0;
    min-height: 8px;
    width: 165px;
}

.column-10 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 355px;
    min-width: 61px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.price {
letter-spacing: 0;
min-height: 8px;
text-align: right;
width: 49px;
}

.overlap-group6 {
position: relative;
width: 572px;
}

.overlap-group5 {
height: 169px;
left: 0;
position: absolute;
top: 0;
width: 572px;
}

.overlap-group5_ {
    height: 169px;
    left: 0;
    position: absolute;
    top: 40;
    width: 572px;
}

.layer-2 {
height: 160px;
left: 44pX;
position: absolute;
top: 0;
width: 485px;
}

.layer-2_ {
    height: 160px;
    left: 44pX;
    position: absolute;
    top: 40;
    width: 485px;
}

.tipo-de-relacin{
    left: 0;
    letter-spacing:0;
    position: absolute;
    top: 60px;
}

.tipo-de-relacin_{
    left: 0;
    letter-spacing:0;
    position: absolute;
    top: 100px;
}

.field-7 {
    align-items: flex-start;
    background-color:rgba(8, 46, 109, 1);
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 9px;
}

.field-7_ {
    align-items: flex-start;
    background-color:rgba(8, 46, 109, 1);
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 49px;
}

.factura-2 {
    letter-spacing:0;
    min-height: 12px;
    text-align: center !important;
    width: 324px;
}

.tres-mil-cuatrocient {
    letter-spacing:0;
    min-height: 12px;
    text-align: center !important;
    width: 324px;
    margin-top: -5px !important;
}

.field-8 {
    align-items: flex-start;
    display: flex;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 25px;
}

.field-8_ {
    align-items: flex-start;
    display: flex;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 65px;
}

.phone{
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 10px;
    width: 85px;
}

.phone_{
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 50px;
    width: 85px;
}

.text-2 {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 28px;
    width: 85px;
}

.text-2_ {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 68px;
    width: 85px;
}

.phone-1 {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 46px;
    width: 85px;
}

.phone-1_ {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 86px;
    width: 85px;
}

.text-4 {
    left: 482px;
    letter-spacing: 9;
    position: absolute;
    text-align: right;
    top: 64px;
    width: 85px;
}

.text-4_ {
    left: 482px;
    letter-spacing: 9;
    position: absolute;
    text-align: right;
    top: 104px;
    width: 85px;
}

.field-9 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    left: 477px;
    min-width: 95px;
    padding: 1px 5px;
    position: absolute;
    top: 81px;
}

.field-9_ {
    align-items: flex-start;
    display: flex;
    height: 16px;
    left: 479px;
    min-width: 95px;
    padding: 1px 5px;
    position: absolute;
    top: 121px;
}

.price-1{
    letter-spacing:0;
    min-height: 12px;
    text-align: right;
    width: 85px;
}

.price-1_{
    letter-spacing:0;
    min-height: 12px;
    text-align: right;
    width: 85px;
}

.mtodo-de-pago {
    left: 298px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 106px;
}

.mtodo-de-pago_ {
    left: 298px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 136px;
}


.forma-de-pago {
    left: 303px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 123px;
}

.forma-de-pago_ {
    left: 303px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 153px;
}

.uso-cfdi{
    left: 326px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 140px;
}

.uso-cfdi_{
    left: 326px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 170px;
}

.serie-del-certificado-del-emisor {
left: 233px;
letter-spacing:0;
position: absolute;
text-align: right;
top: 157px;
}

.serie-del-certificado-del-emisor_ {
    left: 233px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 187px;
}

.ppd-pago-en-parcialidades-o-diferido{
left: 381px;
letter-spacing:0;
position: absolute;
top: 106px;
}


.ppd-pago-en-parcialidades-o-diferido_{
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 136px;
}

.×99-por-definir {
left: 381px;
letter-spacing:0;
position: absolute;
top: 123px;
}

.×99-por-definir_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 153px;
}

.p01-por-definir {
left: 381px;
letter-spacing:0;
position: absolute;
top: 140px;
}

.p01-por-definir_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 170px;
}

.no-se-puede-agregar-firma-digital-a-un {
left: 381px;
letter-spacing:0;
position: absolute;
top: 157px;
}

.no-se-puede-agregar-firma-digital-a-un_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 187px;
}

.text-1 {
left: 0;
letter-spacing: 9;
position: absolute;
top: 77pX;
}

.text-1_ {
    left: 0;
    letter-spacing: 9;
    position: absolute;
    top: 110pX;
}

.flex-row-4 {
align-items: flex-start;
display: flex;
height: 12px;
margin-left: 129px;
margin-top: 5px;
min-width: 67px;
}

.folio-fiscal{
letter-spacing: 0;
min-height: 12px;
min-width: 54px;
text-align: right;
}

.folio-fiscal_{
    letter-spacing: 0;
    min-height: 12px;
    min-width: 54px;
    text-align: right;
    margin-top: 15px;
}

.text-6,
.no-se-puede-agregar-firma-digital-a-un-1,
.text-7 {
letter-spacing:0;
margin-left: 10px;
min-height: 12px;
}

.text-6_{
    margin-top: 15px !important;
}
.text-6_,
.no-se-puede-agregar-firma-digital-a-un-1_,
.text-7_{
letter-spacing:0;
margin-left: 10px;
min-height: 12px;
margin-top : 10px;
}

.flex-row-5 {
align-items: flex-start;
align-self: flex-end;
display: flex;
height: 12px;
margin-right:21px;
margin-top: 5px;
min-width: 343px;
}

.no-de-serio-del-certificado-del-sat {
letter-spacing:0;
min-height: 12px;
min-width: 163px;
text-align: right;
}

.no-de-serio-del-certificado-del-sat_ {
    letter-spacing:0;
    min-height: 12px;
    min-width: 163px;
    text-align: right;
    margin-top: 10px;
}

.flex-row-6 {
align-items: flex-start;
display: flex;
height: 12px;
margin-left: 50px;
margin-top: 5px;
min-width: 146px;
}

.fecha-y-hora-de-certificacin{
    letter-spacing:0;
    min-height: 12px;
    min-width: 133px;
    text-align: right;
}

.fecha-y-hora-de-certificacin_{
    letter-spacing:0;
    min-height: 12px;
    min-width: 133px;
    text-align: right;
    margin-top: 10px;
}

.este-documento-es-u {
    letter-spacing:0;
    margin-top: 20px;
    min-height: 12px;
    min-width: 272px;
    text-align: center;
}

.este-comprobante-no{
    letter-spacing:0;
    min-height: 9px;
    min-width: 476px;
    text-align: center;
}

.field-container-1 {
    height: 49px;
    margin-top: 20px;
    position: relative;
    width: 574px;
}

.field-2 {
align-items: flex-start;
background-color:rgba(211, 211, 211, 1);
background-color:var(--quill-gray);
display: flex;
height: 18px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 0;
}

.sello-digital-del-cfdi,
.sello-del-sat,
.cadena-original-del {
letter-spacing:0;
min-height: 12px;
text-align: center;
width: 562px;
}

.field-3 {
align-items: flex-start;
background-color: rgba(255, 255, 255, 1);
background-color: var(--white) ;
display: flex;
height: 33px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 16px;
}

.no-se-puede-agregar { 
letter-spacing:0;
min-height: 27px;
width: 562px;
}

.field-container-2 {
height: 49px;
margin-top: 3px;
position: relative;
width: 574px;
}

.field-container-3 {
height: 67px;
margin-top: 3px;
position: relative;
width: 574px;
}

.field-10 {
align-items: flex-start;
background-color: rgba(255, 255, 255, 1);
background-color: var(--white) ;
display: flex;
height: 51px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 16px;
}

.no-se-puede-agregar-1 {
    letter-spacing:0;
    min-height: 45px;
    width: 562px;
}

.column-11 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 0;
    min-width: 54px;
    padding: 9px 5px;
    position: absolute;
    top: 0;
}

.cantidad {
    letter-spacing:0;
    min-height: 9px;
    text-align: center;
    width: 42px;
}

.column-11.column-12 {
    left: 414px;
    min-width: 55px;
}

.column-11.column-12 .cantidad,
.column-11.column-13 .cantidad,
.column-11.column-14 .cantidad {
width: 43px;
}

.column-11.column-13 {
left: 467px;
min-width: 55px;
}

.column-11.column-14 {
left: 519px;
min-width: 55px;
}

.column-15 {
align-items: flex-start;
display: flex;
height:60px;
left: 414px;
min-width: 55px;
padding: 4.5px 5px;
position: absolute;
top: 0;
}

.price-2 {
letter-spacing: 0;
min-height: 8px;
text-align: right;
width: 43px;
}

.column-15.column-16 {
left: 467px;
}

.column-15.column-17 {
left: 519px;
}

.field-11 {
align-items: flex-start;
background-color:rgba(8, 46, 109, 1);
background-color:var(--deep-sapphire);
display: flex;
height: 16px;
left: 381px;
min-width: 94px;
padding: 1px 5px;
position: absolute;
top: 9px;
}

.field-11_ {
    align-items: flex-start;
    background-color:rgba(8, 46, 109, 1);
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 381px;
    min-width: 94px;
    padding: 1px 5px;
    position: absolute;
    top: 50px;
}

.subtotal {
letter-spacing:0;
min-height: 12px;
text-align: right;
width: 84px;
}

.field-11.field-11_ {
    top: 50x;
}

.field-11.field-12 {
top: 27px;
}

.field-11.field-12_ {
    top: 67px;
}

.field-11.field-13 {
    top: 45px;
}

.field-11.field-13_ {
    top: 85px;
}

.field-11.field-14 {
    top: 63px;
}


.field-11.field-14_ {
    top: 103px;
}


.field-11.field-15 {
    top: 81px;
} 

.field-11.field-15_ {
    top: 121px;
} 

.verify-preview{
    margin-top: 30px;
}

.zoom-div{
    overflow: hidden;
    bottom: 10px;
    position: fixed;
    left: 48%;
}

.zoom{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    color: white;
    background-color: black;
    margin: 5px;
}
.reports .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}

.reports h5{
    font-weight: lighter;
    margin: 5px 0 0 0;
    color: #00AEEF;
}
.reports .search-icon{
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
    width: 50px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}
.reports .search-container{
    display: inline-block;
    margin: 0;
    min-width: 30%;
}
.reports .search-container input{
    height: 50px;
    background: rgba(255,255,255,0.6);
    border-radius: 0 25px 25px 0 !important;
    border: none;
}
.reports .search-container input:focus{
    box-shadow: none;
}
.reports .options{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.reports .options .react-datepicker-wrapper input{
    background: transparent !important;
    color: #666;
    height: 50px;
    padding-top: 0;
}
.reports .options .date-btn{
    padding: 0 20px !important;
}
.reports .options .date-btn:hover input{
    color: white;
}
.reports .options .date-btn input:focus-visible{
    outline: none;
    border: none;
}

.reports .title-search-bar{
    display: flex;
    flex-direction: row;
}

.reports .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
    height: 2.2rem;
}

.reports .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.reports .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.reports .table{
    border: none;
    margin: 0;
    margin-top: 30px;
}

.reports .table td p{
    margin: 0;
}

.reports .table thead tr th{
    border-top: none;
}

.reports .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.reports .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.reports .table tbody tr td{
    color: black;
    font-size: 14px;
    height: 50px;
    text-align: center;
}
.reports .table tbody tr td.spacer{
    height: 10px;
    background: transparent;
}
.reports .table tbody tr td svg{
    font-size: 12px;
}

.reports .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.reports .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.reports .react-datepicker-wrapper input{
    background-color: #1B3769;
    color: white;
    border: none;
    padding: 0;
    margin: 0;
}

.reports .barraBusqueda{
    border: 0.5px solid #D7D7D7;
    border-radius: 12px;
}

.reports .barraBusqueda input{
    border: none;
}

.reports .barraBusqueda button{
    background: none;
    background-color: none;
    border: none;
}

.reports .drop-down-filters{
    background-image: none;
    background-Color: #1B3769;
    border-radius: 20px;
    border-style: none;
}

.reports .drop-down-categories{
    margin: auto;
    padding: 0px;
    text-align: center;    
}

.reports .table-scroll{
    max-height: 60vh;
    overflow-x: auto;
}

.reports .tables-scroll{
    height:53%;
    overflow-x: auto;
}

.reports .card-body{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.reports .pagination-container{
    display: flex;
    flex-direction: row;
    color: #1B3769;
    list-style-type: none;
    margin: 10px;
    float: right;
    padding: 0;
}

.reports .pagination-container li{
    border: none !important;
}

.reports .pagination-container li a{
    margin: 8px;
    font-size: 20px;
}

.reports .pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.reports .pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.reports .pagination-container .selected{
    font-weight: bolder;
    color: white;
}

.reports .options .card-body label{
    margin: 0px;
    padding: 0px;
}

.reports .reports-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.reports .row-selection .selected{
    background: #FFFFFF;
    opacity: 0.8;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.reports .row-selection p{
    font-size: 18px;
    color: black;
    margin: 0 auto;
}
.reports .row-selection .section{
    margin-right: 10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.reports .row-selection{
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 20px 20px 20px 20px;
}

.reports .row-selection .text-selected {
    font-weight: bold;
}

.reports .row-selection .text-section {
    font-weight: lighter;
}

.reports .col-reports{
    padding: 0 !important;
}

.reports .reload-table-grey{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5) !important;
    color: black;
    font-size: 1.4em;
}

.reports .check-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
}

.reports .table-scroll ul {
    margin-bottom: 0;
}

.reports .table-scroll li {
    text-align: start;
}
*{
    font-family: "Mulish" !important;
}

.approve-orgs-and-comps {
    width: 100%;
    min-height: 85vh !important;
}

.approve-orgs-and-comps .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.approve-orgs-and-comps .title-search-bar{
    display: inline-block;
}

.approve-orgs-and-comps .alter-table{
    display: flex;
    align-items: center;
}

.approve-orgs-and-comps .add-company{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    font-weight:100;
    cursor: pointer;
}

.approve-orgs-and-comps .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.approve-orgs-and-comps .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.approve-orgs-and-comps h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}
.scroll{
    max-height: 80vh;
    overflow-y: scroll !important;
}

.company-form form{
    width: 95%;
    height: 78vh;
    padding-right: 10px;
    padding-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.company-form .title-container,
.users .title-container,
.manage-departments .title-container,
.roles .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.company-form .title-container svg,
.users .title-container svg.back,
.manage-departments .title-container svg.back,
.roles .title-container svg.back{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 20px;
    cursor: pointer;
}
.users .title-container .users-options, 
.manage-departments  .title-container .users-options, 
.roles .title-container .users-options{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.users .title-container .users-options svg,
.manage-departments .title-container .users-options svg,
.roles .title-container .users-options svg{
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}
.company-form .title-container svg:hover, 
.users .title-container svg.back:hover,
.manage-departments .title-container svg.back:hover,
.roles .title-container svg.back:hover{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}

.company-form p{
    margin-top: 20px;
    font-size: 1.2em;
}

.company-form input, .company-form .input{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
    border: none;
    border-radius: 10px;
}

.company-form .btn-container{
    width: 95%;
}
.company-form .btn-container .btn{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.users form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}


.users form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.users form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.approve-orgs-and-comps .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    margin-left: 15px;
    font-size: 28px;
    color: #666;
}
.approve-orgs-and-comps .search-icon:hover{
    color: darkgreen;
}
.approve-orgs-and-comps .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .search-container input{
    border: none;
    height: 50px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
}

.company-form .form-overflow{
    height: 68vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.company-form p{
    font-weight: bold;
    color: #1B3769;
}

.agent {
    margin-top: 5px;
    font-weight: bold;
    color: #1B3769;
}

.company-form .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.company-form .files svg{
    margin: 3px 3px 0 0;
}

.company-form .files small{
    margin: 0;
}

.company-form .form-label{
    font-weight: bold;
}

.company-form .form-file-label:hover{
    cursor: pointer;
}

.company-form .form-control-file{
    display: none;
}

.company-form input[type="checkbox"]{
    height: auto;
}

.company-form .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.company-form .title a{
    margin: 4px 5px 0 0;
    color: #1B3769;
    font-size: 20px;
}

.company-form .title a:hover{
    color: rgb(46, 243, 41);
}

.company-form .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.company-form button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-form select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.list-companies{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 60vh;
    height: 68vh;
}

.list-companies .title-container{
    margin-top: 30px;
    padding-left: 30px;
    color: #666;
}

.list-companies .title-container h5{
    font-weight: 400;
}
.list-companies .table-row{
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 95%;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px #ccc;
}

.list-companies .table-row .proved-container{
    font-weight: 600;
    font-size: 18px;
}

.list-companies .table-row .name-container{
    margin-right: 20px;
    font-size: 1.2em;
}

.list-companies .options-container{
    margin-left: auto;
}

.list-companies .table-row .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em;
    background: transparent !important;
    border: none;
    color: black;
    box-shadow: none;
    cursor: pointer;
}

.list-companies .table-row .dropdown-toggle:active,
.interns-list .table-content .dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.list-companies .table-row .dropdown-toggle::after{
    display: none;
}

.list-companies .table-row .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.list-companies .table-row .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.list-companies .table-row .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.list-companies .table-row .row{
    margin-left: 0;
}

.list-companies .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: 20px 0 0 0;
}

.list-companies .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 220px;
    height: 205px;
    box-shadow: 3px 5px 5px #d7d7d7;
    margin: auto;
}

.list-companies .flip-card {
    background-color: transparent;
    width: 220px;
    height: 175px;
    margin: auto;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
}

.flip-card-back {
    position: absolute;
    bottom: -25px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.list-companies .card a{
    color: #1B3769;
    font-size: 25px;
    padding: 0px 5px 0px 5px;
}

.list-companies .card a:hover{
    color:rgb(46, 243, 41);
}

.list-companies .flip-card-inner .card-footer{
    position: absolute;
    bottom: 0;
}

.list-companies .card .company-name{
    color: #1B3769;
    font-weight: bold;
    font-size: medium;
    margin-bottom: 2px;
}

.list-companies .card .company-options{
    text-align: center;
    margin: 5px 0;
}

.list-companies .card .company-options .option{
    display: inline-block;
    width: 40%;
    font-size: 28px;
}

.list-companies .card .company-options .option svg:hover{
    cursor: pointer;
}

.list-companies .card .company-options .approve{
    color: green;
}

.list-companies .card .company-options .deny{
    color: tomato;
}

.company-docs a{
    color: #1B3769;
    margin: 0 10px 0 0px;
    font-size: 20px;
}

.company-docs .form-file-label{
    color: #1B3769;
    font-size: 20px;
}

.company-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.company-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.company-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.company-modals p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.company-modals small{
    color: #1B3769;
    margin: auto;
}

.company-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.company-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger:hover{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
}

.users .title, .roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.users .title a, .roles .title a{
    margin: 4px 5px 0 0;
    color: #333;
    font-size: 20px;
}

.users .title a:hover, .roles .title a:hover{
    color: rgb(46, 243, 41);
}

.users .title h4, .roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #333;
}

.users .users-options a, .roles .users-options a{
    font-size: 25px;
    margin-left: 10px;
}

.users .user-cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: .5rem 0 0 0;
}

.users .card{
    border-radius: 15px;
    width: 17.5rem;
    height: 17.5rem;
}

.users .card-header p{
    color: #1B3769;
    font-weight: bolder;
    font-size: small;
    margin: auto;
    text-align: center;
}

.users .user-card-body{
    display: flex;
    flex-direction: column;
}

.card-department-body{
    padding: 0 !important;
}
.users .user-card-body div{
    display: flex;
    flex-direction: row;
}

.users .user-card-body div svg{
    color: #1B3769;
    margin: 2.5px .5rem 0px 0px;
    width: 18px;
}

.users .user-card-body div p{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .card-header:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.users .card-footer:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.users .user-card-footer .options{
    float: right;
}

.users .user-card-footer .options a svg{
    color: #1B3769;
    width: 30px;
    font-size: 25px;
}

.users .user-card-footer .options a svg:hover{
    color: rgb(46, 243, 41);
}

.users .create-user{
    height: 75vh;
    padding: 1rem;
    overflow-y: auto;
}

.users .create-user .checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}


.users .create-user input{
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 15px;
    height: 50px;
}

.users .create-user label{
    color: #1B3769;
    font-size: 14px;
    font-weight: bold;
}

.users .create-user button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 18px;
    margin-right: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.users .table{
    width: 95%;
    margin-bottom: 150px;
}
.users .table h5{
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #666;
    margin-bottom: 15px;
}
.users .table .header{
    background: transparent;
    margin-left: 0;
    padding: 0 10px;
    margin-bottom: 5px;
    color: #666;
}
.users .table .body{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background:rgba(255, 255, 255, 0.6);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 0;
}
.users .table .options{
    text-align: center;
}
.users .table .options svg{ color: #333; }

.roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.roles .title a{
    margin: 4px 5px 0 0;
    color: black;
    font-size: 20px;
}

.roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.roles .roles-list{
    width: 95%;
    padding: 0 20px;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .toggle{
    padding: 0;
    border: none;
    background: transparent;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card{
    border: none;
    margin-bottom: 15px;
    border-radius: 15px  !important;
    box-shadow: 0 3px 8px #aaa;
    background: transparent;
    color: black;
}
.roles .roles-list .card-header{
    border: none;
    background: rgba(255,255,255,0.6);
    color: black !important;
}
.roles .roles-list .card-header .name-container{
    text-align: left;
    color: rgb(0, 0, 0) !important;  
}
.roles .roles-list .btn-link:hover,
.roles .roles-list .btn-link:focus{
    text-decoration: none !important;
    box-shadow: none;
}
.roles .roles-list .card-header .icon-container{
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card-header .btn:hover,
.roles .roles-list .card-header .btn:focus,
.roles .roles-list .card-header .btn:hover{
    text-decoration: none;
    box-shadow:none;
    outline: none;
}

.roles .roles-list .card-header button{
    color: white;
    font-weight: bolder;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}

.roles .roles-list .card-header svg{
    font-size: 20px;
    display: inline-block;
    color: black;
}

.roles .roles-list .card-header .options{
    color: white;
    float: right;
    font-size: 18px;
}

.roles .roles-list .card-header .options > *{
    margin-left: 5px;
}

.roles .roles-list .card-body{
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
}
.roles .roles-list .show{
    background: rgba(255,255,255,0.6);
}

.roles .roles-list .card-body .user-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    min-height: 50px;
    margin: 10px auto;
    width: 95%;
    justify-content: space-between;
    background: #efefef;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
}
.roles .roles-list .card-body .user-options .user-name{
    margin: 0;
    text-align: left;
}
.roles .roles-list .card-body .user-options svg{
    color: #666;
    font-size: 15px;
    margin-right: 5px;
}

.roles .roles-list .card-body .user-options .option{
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
}

.roles .roles-list .card-body .user-options .option:hover{
    color: #333;
}

.roles .roles-list .card-body .user-options .assign-role{
    color: black;
}

.manage-departments .card{
    border: none;
    box-shadow: 0 5px 5px #666;
}

.manage-departments .card-header{
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card-header {
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card svg{
    font-size: .9em;
    margin-left: 3px;
    color: #666 !important;
}

.manage-departments .card-header svg{
    font-size: .9em;
    margin-left: 3px;
    color: white !important;
}

.companies-modals p{
    font-weight: bold;
    font-size: large;
    text-align: center;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.companies-modals table{
    padding: 10px;
    margin: auto;
    max-width: 98%;
}

.companies-modals table tr td{
    text-align: center;
    font-weight: bolder;
    font-size: 14px;
}

.change-permissions-modal .modal-dialog{
    max-width: 80%;
    margin: auto;
}

.companies-modals small{
    color: #1B3769;
    margin: auto;
    text-align: center;
}

.companies-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.companies-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .btn-danger{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.companies-modals .modal-header{
    display: flex;
    flex-direction: column;
}
.options a{
    margin: 0 0 0 10px;
    color: #1B3769;
    font-size: 22x !important;
}
.options a:hover{
    color: rgb(46, 243, 41);
}
.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    position: relative;
    margin: 10px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 18px !important;
}
.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: rgb(46, 243, 41);
}
.form-control-file{
    display: none;
}
.form-control-file{
    display: none !important;
}
.form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}
.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

@media only screen and (max-width: 1620px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 845px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width: 540px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

 .pagination-container{
    display: flex;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 100px 0 0;
    float: right;
    padding: 0;
}

 .pagination-container li{
    border: none;
}

 .pagination-container li a{
    margin: 10px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

 .pagination-container .selected{
    font-weight: bolder;
    color: white !important;
}
.interns-list-options {
    margin-right: 60px;
}

.interns-list-options p{
    margin: 10px 0 0 0;
    color: #1B3769;
}
.search-container input{
    background-color: #eceef1;
    border-radius: 10px;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}
.table-width{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important; 
}
.scroll-row-section{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
    height: 85vh;
}
.user-container{
    width: 100% !important;
}
.search-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%) !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    height: 30px;
    padding: 0 12px !important;
    margin-top: 4px ;
}
.card-roles{
    font-size: 13px !important;
    color: black !important;
}

.roles-check-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.roles-check-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roles-check-center .form-check{
    padding: 0;
}

.height-roles{
    height: 76vh !important;
    overflow-y: scroll !important;
}
.approve-orgs-and-comps {
    width: 100%;
    min-height: 85vh !important;
}

.approve-orgs-and-comps .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.approve-orgs-and-comps .title-search-bar{
    display: inline-block;
}

.approve-orgs-and-comps .alter-table{
    display: flex;
    align-items: center;
}

.approve-orgs-and-comps .add-company{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    font-weight:100;
    cursor: pointer;
}

.approve-orgs-and-comps .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.approve-orgs-and-comps .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.approve-orgs-and-comps h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}
.scroll{
    max-height: 80vh;
    overflow-y: scroll !important;
}

.company-form form{
    width: 95%;
    height: 78vh;
    padding-right: 10px;
    padding-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.company-form .title-container,
.users .title-container,
.manage-departments .title-container,
.roles .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.button-back {
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}


.users .title-container .users-options, 
.manage-departments  .title-container .users-options, 
.roles .title-container .users-options{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.users .title-container .users-options svg,
.manage-departments .title-container .users-options svg,
.roles .title-container .users-options svg{
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}
.company-form .title-container svg:hover, 
.users .title-container svg.back:hover,
.manage-departments .title-container svg.back:hover,
.roles .title-container svg.back:hover{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}

.company-form p{
    margin-top: 20px;
    font-size: 1.2em;
}

.company-form input, .company-form .input{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
    border: none;
    border-radius: 10px;
}

.company-form .btn-container{
    width: 95%;
}
.company-form .btn-container .btn{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.users form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}


.users form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.users form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.approve-orgs-and-comps .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    margin-left: 15px;
    font-size: 28px;
    color: #666;
}
.approve-orgs-and-comps .search-icon:hover{
    color: darkgreen;
}
.approve-orgs-and-comps .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .search-container input{
    border: none;
    height: 50px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
}

.company-form .form-overflow{
    height: 68vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.company-form p{
    font-weight: bold;
    color: #1B3769;
}

.company-form .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.company-form .files svg{
    margin: 3px 3px 0 0;
}

.company-form .files small{
    margin: 0;
}

.company-form .form-label{
    font-weight: bold;
}

.company-form .form-file-label:hover{
    cursor: pointer;
}

.company-form .form-control-file{
    display: none;
}

.company-form input[type="checkbox"]{
    height: auto;
}

.company-form .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.company-form .title a{
    margin: 4px 5px 0 0;
    color: #1B3769;
    font-size: 20px;
}

.company-form .title a:hover{
    color: rgb(46, 243, 41);
}

.company-form .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.company-form button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-form select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.list-companies{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 60vh
}

.list-companies .title-container{
    margin-top: 30px;
    padding-left: 30px;
    color: #666;
}

.list-companies .title-container h5{
    font-weight: 400;
}
.list-companies .table-row{
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 95%;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px #ccc;
}

.list-companies .table-row .proved-container{
    font-weight: 600;
    font-size: 18px;
}

.list-companies .table-row .name-container{
    margin-right: 20px;
    font-size: 1.2em;
}

.list-companies .options-container{
    margin-left: auto;
}

.list-companies .table-row .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em;
    background: transparent !important;
    border: none;
    color: black;
    box-shadow: none;
    cursor: pointer;
}

.list-companies .table-row .dropdown-toggle:active,
.interns-list .table-content .dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.list-companies .table-row .dropdown-toggle::after{
    display: none;
}

.list-companies .table-row .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.list-companies .table-row .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.list-companies .table-row .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.list-companies .table-row .row{
    margin-left: 0;
}

.list-companies .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: 20px 0 0 0;
}

.list-companies .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 220px;
    height: 205px;
    box-shadow: 3px 5px 5px #d7d7d7;
    margin: auto;
}

.list-companies .flip-card {
    background-color: transparent;
    width: 220px;
    height: 175px;
    margin: auto;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
}

.flip-card-back {
    position: absolute;
    bottom: -25px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.list-companies .card a{
    color: #1B3769;
    font-size: 25px;
    padding: 0px 5px 0px 5px;
}

.list-companies .card a:hover{
    color:rgb(46, 243, 41);
}

.list-companies .flip-card-inner .card-footer{
    position: absolute;
    bottom: 0;
}

.list-companies .card .company-name{
    color: #1B3769;
    font-weight: bold;
    font-size: medium;
    margin-bottom: 2px;
}

.list-companies .card .company-options{
    text-align: center;
    margin: 5px 0;
}

.list-companies .card .company-options .option{
    display: inline-block;
    width: 40%;
    font-size: 28px;
}

.list-companies .card .company-options .option svg:hover{
    cursor: pointer;
}

.list-companies .card .company-options .approve{
    color: green;
}

.list-companies .card .company-options .deny{
    color: tomato;
}

.company-docs a{
    color: #1B3769;
    margin: 0 10px 0 0px;
    font-size: 20px;
}

.company-docs .form-file-label{
    color: #1B3769;
    font-size: 20px;
}

.company-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.company-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.company-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.company-modals p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.company-modals small{
    color: #1B3769;
    margin: auto;
}

.company-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.company-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger:hover{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
}

.users .title, .roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.users .title a, .roles .title a{
    margin: 4px 5px 0 0;
    color: #333;
    font-size: 20px;
}

.users .title a:hover, .roles .title a:hover{
    color: rgb(46, 243, 41);
}

.users .title h4, .roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #333;
}

.users .users-options a, .roles .users-options a{
    font-size: 25px;
    margin-left: 10px;
}

.users .user-cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: .5rem 0 0 0;
}

.users .card{
    border-radius: 15px;
    width: 17.5rem;
    height: 17.5rem;
}

.users .card-header p{
    color: #1B3769;
    font-weight: bolder;
    font-size: small;
    margin: auto;
    text-align: center;
}

.users .user-card-body{
    display: flex;
    flex-direction: column;
}

.card-department-body{
    padding: 0 !important;
}
.users .user-card-body div{
    display: flex;
    flex-direction: row;
}

.users .user-card-body div svg{
    color: #1B3769;
    margin: 2.5px .5rem 0px 0px;
    width: 18px;
}

.users .user-card-body div p{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .card-header:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.users .card-footer:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.users .user-card-footer .options{
    float: right;
}

.users .user-card-footer .options a svg{
    color: #1B3769;
    width: 30px;
    font-size: 25px;
}

.users .user-card-footer .options a svg:hover{
    color: rgb(46, 243, 41);
}

.users .create-user{
    height: 75vh;
    padding: 1rem;
    overflow-y: auto;
}

.users .create-user .checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}


.users .create-user input{
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 15px;
    height: 50px;
}

.users .create-user label{
    color: #1B3769;
    font-size: 14px;
    font-weight: bold;
}

.users .create-user button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 18px;
    margin-right: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.users .table{
    width: 95%;
    margin-bottom: 150px;
}
.users .table h5{
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #666;
    margin-bottom: 15px;
}
.users .table .header{
    background: transparent;
    margin-left: 0;
    padding: 0 10px;
    margin-bottom: 5px;
    color: #666;
}
.users .table .body{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background:rgba(255, 255, 255, 0.6);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 0;
}
.users .table .options{
    text-align: center;
}
.users .table .options svg{ color: #333; }

.roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.roles .title a{
    margin: 4px 5px 0 0;
    color: black;
    font-size: 20px;
}

.roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.roles .roles-list{
    width: 95%;
    padding: 0 20px;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .toggle{
    padding: 0;
    border: none;
    background: transparent;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card{
    border: none;
    margin-bottom: 15px;
    border-radius: 15px  !important;
    box-shadow: 0 3px 8px #aaa;
    background: transparent;
    color: black;
}
.roles .roles-list .card-header{
    border: none;
    background: rgba(255,255,255,0.6);
    color: black !important;
}
.roles .roles-list .card-header .name-container{
    text-align: left;
    color: rgb(0, 0, 0) !important;  
}
.roles .roles-list .btn-link:hover,
.roles .roles-list .btn-link:focus{
    text-decoration: none !important;
    box-shadow: none;
}
.roles .roles-list .card-header .icon-container{
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card-header .btn:hover,
.roles .roles-list .card-header .btn:focus,
.roles .roles-list .card-header .btn:hover{
    text-decoration: none;
    box-shadow:none;
    outline: none;
}

.roles .roles-list .card-header button{
    color: white;
    font-weight: bolder;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}

.roles .roles-list .card-header svg{
    font-size: 20px;
    display: inline-block;
    color: black;
}

.roles .roles-list .card-header .options{
    color: white;
    float: right;
    font-size: 18px;
}

.roles .roles-list .card-header .options > *{
    margin-left: 5px;
}

.roles .roles-list .card-body{
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
}
.roles .roles-list .show{
    background: rgba(255,255,255,0.6);
}

.roles .roles-list .card-body .user-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    min-height: 50px;
    margin: 10px auto;
    width: 95%;
    justify-content: space-between;
    background: #efefef;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
}
.roles .roles-list .card-body .user-options .user-name{
    margin: 0;
    text-align: left;
}
.roles .roles-list .card-body .user-options svg{
    color: #666;
    font-size: 15px;
    margin-right: 5px;
}

.roles .roles-list .card-body .user-options .option{
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
}

.roles .roles-list .card-body .user-options .option:hover{
    color: #333;
}

.roles .roles-list .card-body .user-options .assign-role{
    color: black;
}

.manage-departments .card{
    border: none;
    box-shadow: 0 5px 5px #666;
}

.manage-departments .card-header{
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card-header {
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card svg{
    font-size: .9em;
    margin-left: 3px;
    color: #666 !important;
}

.manage-departments .card-header svg{
    font-size: .9em;
    margin-left: 3px;
    color: white !important;
}

.companies-modals p{
    font-weight: bold;
    font-size: large;
    text-align: center;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.companies-modals table{
    padding: 10px;
    margin: auto;
    max-width: 98%;
}

.companies-modals table tr td{
    text-align: center;
    font-weight: bolder;
    font-size: 14px;
}

.change-permissions-modal .modal-dialog{
    max-width: 80%;
    margin: auto;
}

.companies-modals small{
    color: #1B3769;
    margin: auto;
    text-align: center;
}

.companies-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.companies-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .btn-danger{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.companies-modals .modal-header{
    display: flex;
    flex-direction: column;
}
.options a{
    margin: 0 0 0 10px;
    color: #1B3769;
    font-size: 22x !important;
}
.options a:hover{
    color: rgb(46, 243, 41);
}
.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    position: relative;
    margin: 10px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 18px !important;
}
.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: rgb(46, 243, 41);
}
.form-control-file{
    display: none;
}
.form-control-file{
    display: none !important;
}
.form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}
.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

@media only screen and (max-width: 1620px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 845px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width: 540px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

 .pagination-container{
    display: flex;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 100px 0 0;
    float: right;
    padding: 0;
}

 .pagination-container li{
    border: none;
}

 .pagination-container li a{
    margin: 10px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

 .pagination-container .selected{
    font-weight: bolder;
    color: white !important;
}
.interns-list-options {
    margin-right: 60px;
}

.interns-list-options p{
    margin: 10px 0 0 0;
    color: #1B3769;
}
.search-container input{
    background-color: #eceef1;
    border-radius: 10px;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}
.table-width{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important; 
}
.scroll-row-section{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
    height: 85vh;
}
.user-container{
    width: 100% !important;
}
.search-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%) !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    height: 30px;
    padding: 0 12px !important;
    margin-top: 4px ;
}
.card-roles{
    font-size: 13px !important;
    color: black !important;
}

.groups-icon {
    font-size: 1.5rem;
    margin: 0 5px;
}

.groups-error-message {
    color: red !important;
}

.back {
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 20px;
    cursor: pointer;
}
.approve-orgs-and-comps {
    width: 100%;
    min-height: 85vh !important;
}

.approve-orgs-and-comps .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.approve-orgs-and-comps .title-search-bar{
    display: inline-block;
}

.approve-orgs-and-comps .alter-table{
    display: flex;
    align-items: center;
}

.approve-orgs-and-comps .add-company{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    font-weight:100;
    cursor: pointer;
}

.approve-orgs-and-comps .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.approve-orgs-and-comps .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.approve-orgs-and-comps h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}
.scroll{
    max-height: 80vh;
    overflow-y: scroll !important;
}

.company-form form{
    width: 95%;
    height: 78vh;
    padding-right: 10px;
    padding-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.company-form .title-container,
.users .title-container,
.manage-departments .title-container,
.roles .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.button-back {
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}


.users .title-container .users-options, 
.manage-departments  .title-container .users-options, 
.roles .title-container .users-options{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.users .title-container .users-options svg,
.manage-departments .title-container .users-options svg,
.roles .title-container .users-options svg{
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}
.company-form .title-container svg:hover, 
.users .title-container svg.back:hover,
.manage-departments .title-container svg.back:hover,
.roles .title-container svg.back:hover{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}

.company-form p{
    margin-top: 20px;
    font-size: 1.2em;
}

.company-form input, .company-form .input{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
    border: none;
    border-radius: 10px;
}

.company-form .btn-container{
    width: 95%;
}
.company-form .btn-container .btn{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.users form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}


.users form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.users form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.approve-orgs-and-comps .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    margin-left: 15px;
    font-size: 28px;
    color: #666;
}
.approve-orgs-and-comps .search-icon:hover{
    color: darkgreen;
}
.approve-orgs-and-comps .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .search-container input{
    border: none;
    height: 50px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
}

.company-form .form-overflow{
    height: 68vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.company-form p{
    font-weight: bold;
    color: #1B3769;
}

.company-form .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.company-form .files svg{
    margin: 3px 3px 0 0;
}

.company-form .files small{
    margin: 0;
}

.company-form .form-label{
    font-weight: bold;
}

.company-form .form-file-label:hover{
    cursor: pointer;
}

.company-form .form-control-file{
    display: none;
}

.company-form input[type="checkbox"]{
    height: auto;
}

.company-form .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.company-form .title a{
    margin: 4px 5px 0 0;
    color: #1B3769;
    font-size: 20px;
}

.company-form .title a:hover{
    color: rgb(46, 243, 41);
}

.company-form .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.company-form button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-form select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.list-companies{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 60vh;
}

.list-companies .title-container{
    margin-top: 30px;
    padding-left: 30px;
    color: #666;
}

.list-companies .title-container h5{
    font-weight: 400;
}
.list-companies .table-row{
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 95%;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px #ccc;
}

.list-companies .table-row .proved-container{
    font-weight: 600;
    font-size: 18px;
}

.list-companies .table-row .name-container{
    margin-right: 20px;
    font-size: 1.2em;
}

.list-companies .options-container{
    margin-left: auto;
}

.list-companies .table-row .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em;
    background: transparent !important;
    border: none;
    color: black;
    box-shadow: none;
    cursor: pointer;
}

.list-companies .table-row .dropdown-toggle:active,
.interns-list .table-content .dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.list-companies .table-row .dropdown-toggle::after{
    display: none;
}

.list-companies .table-row .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.list-companies .table-row .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.list-companies .table-row .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.list-companies .table-row .row{
    margin-left: 0;
}

.list-companies .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: 20px 0 0 0;
}

.list-companies .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 220px;
    height: 205px;
    box-shadow: 3px 5px 5px #d7d7d7;
    margin: auto;
}

.list-companies .flip-card {
    background-color: transparent;
    width: 220px;
    height: 175px;
    margin: auto;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
}

.flip-card-back {
    position: absolute;
    bottom: -25px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.list-companies .card a{
    color: #1B3769;
    font-size: 25px;
    padding: 0px 5px 0px 5px;
}

.list-companies .card a:hover{
    color:rgb(46, 243, 41);
}

.list-companies .flip-card-inner .card-footer{
    position: absolute;
    bottom: 0;
}

.list-companies .card .company-name{
    color: #1B3769;
    font-weight: bold;
    font-size: medium;
    margin-bottom: 2px;
}

.list-companies .card .company-options{
    text-align: center;
    margin: 5px 0;
}

.list-companies .card .company-options .option{
    display: inline-block;
    width: 40%;
    font-size: 28px;
}

.list-companies .card .company-options .option svg:hover{
    cursor: pointer;
}

.list-companies .card .company-options .approve{
    color: green;
}

.list-companies .card .company-options .deny{
    color: tomato;
}

.company-docs a{
    color: #1B3769;
    margin: 0 10px 0 0px;
    font-size: 20px;
}

.company-docs .form-file-label{
    color: #1B3769;
    font-size: 20px;
}

.company-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.company-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.company-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.company-modals p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.company-modals small{
    color: #1B3769;
    margin: auto;
}

.company-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.company-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger:hover{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
}

.users .title, .roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.users .title a, .roles .title a{
    margin: 4px 5px 0 0;
    color: #333;
    font-size: 20px;
}

.users .title a:hover, .roles .title a:hover{
    color: rgb(46, 243, 41);
}

.users .title h4, .roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #333;
}

.users .users-options a, .roles .users-options a{
    font-size: 25px;
    margin-left: 10px;
}

.users .user-cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: .5rem 0 0 0;
}

.users .card{
    border-radius: 15px;
    width: 17.5rem;
    height: 17.5rem;
}

.users .card-header p{
    color: #1B3769;
    font-weight: bolder;
    font-size: small;
    margin: auto;
    text-align: center;
}

.users .user-card-body{
    display: flex;
    flex-direction: column;
}

.card-department-body{
    padding: 0 !important;
}
.users .user-card-body div{
    display: flex;
    flex-direction: row;
}

.users .user-card-body div svg{
    color: #1B3769;
    margin: 2.5px .5rem 0px 0px;
    width: 18px;
}

.users .user-card-body div p{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .card-header:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.users .card-footer:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.users .user-card-footer .options{
    float: right;
}

.users .user-card-footer .options a svg{
    color: #1B3769;
    width: 30px;
    font-size: 25px;
}

.users .user-card-footer .options a svg:hover{
    color: rgb(46, 243, 41);
}

.users .create-user{
    height: 75vh;
    padding: 1rem;
    overflow-y: auto;
}

.users .create-user .checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}


.users .create-user input{
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 15px;
    height: 50px;
}

.users .create-user label{
    color: #1B3769;
    font-size: 14px;
    font-weight: bold;
}

.users .create-user button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 18px;
    margin-right: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.users .table{
    width: 95%;
    margin-bottom: 150px;
}
.users .table h5{
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #666;
    margin-bottom: 15px;
}
.users .table .header{
    background: transparent;
    margin-left: 0;
    padding: 0 10px;
    margin-bottom: 5px;
    color: #666;
}
.users .table .body{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background:rgba(255, 255, 255, 0.6);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 0;
}
.users .table .options{
    text-align: center;
}
.users .table .options svg{ color: #333; }

.roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.roles .title a{
    margin: 4px 5px 0 0;
    color: black;
    font-size: 20px;
}

.roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.roles .roles-list{
    width: 95%;
    padding: 0 20px;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .toggle{
    padding: 0;
    border: none;
    background: transparent;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card{
    border: none;
    margin-bottom: 15px;
    border-radius: 15px  !important;
    box-shadow: 0 3px 8px #aaa;
    background: transparent;
    color: black;
}
.roles .roles-list .card-header{
    border: none;
    background: rgba(255,255,255,0.6);
    color: black !important;
}
.roles .roles-list .card-header .name-container{
    text-align: left;
    color: rgb(0, 0, 0) !important;  
}
.roles .roles-list .btn-link:hover,
.roles .roles-list .btn-link:focus{
    text-decoration: none !important;
    box-shadow: none;
}
.roles .roles-list .card-header .icon-container{
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card-header .btn:hover,
.roles .roles-list .card-header .btn:focus,
.roles .roles-list .card-header .btn:hover{
    text-decoration: none;
    box-shadow:none;
    outline: none;
}

.roles .roles-list .card-header button{
    color: white;
    font-weight: bolder;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}

.roles .roles-list .card-header svg{
    font-size: 20px;
    display: inline-block;
    color: black;
}

.roles .roles-list .card-header .options{
    color: white;
    float: right;
    font-size: 18px;
}

.roles .roles-list .card-header .options > *{
    margin-left: 5px;
}

.roles .roles-list .card-body{
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
}
.roles .roles-list .show{
    background: rgba(255,255,255,0.6);
}

.roles .roles-list .card-body .user-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    min-height: 50px;
    margin: 10px auto;
    width: 95%;
    justify-content: space-between;
    background: #efefef;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
}
.roles .roles-list .card-body .user-options .user-name{
    margin: 0;
    text-align: left;
}
.roles .roles-list .card-body .user-options svg{
    color: #666;
    font-size: 15px;
    margin-right: 5px;
}

.roles .roles-list .card-body .user-options .option{
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
}

.roles .roles-list .card-body .user-options .option:hover{
    color: #333;
}

.roles .roles-list .card-body .user-options .assign-role{
    color: black;
}

.manage-departments .card{
    border: none;
    box-shadow: 0 5px 5px #666;
}

.manage-departments .card-header{
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card-header {
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card svg{
    font-size: .9em;
    margin-left: 3px;
    color: #666 !important;
}

.manage-departments .card-header svg{
    font-size: .9em;
    margin-left: 3px;
    color: white !important;
}

.companies-modals p{
    font-weight: bold;
    font-size: large;
    text-align: center;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.companies-modals table{
    padding: 10px;
    margin: auto;
    max-width: 98%;
}

.companies-modals table tr td{
    text-align: center;
    font-weight: bolder;
    font-size: 14px;
}

.change-permissions-modal .modal-dialog{
    max-width: 80%;
    margin: auto;
}

.companies-modals small{
    color: #1B3769;
    margin: auto;
    text-align: center;
}

.companies-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.companies-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .btn-danger{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.companies-modals .modal-header{
    display: flex;
    flex-direction: column;
}
.options a{
    margin: 0 0 0 10px;
    color: #1B3769;
    font-size: 22x !important;
}
.options a:hover{
    color: rgb(46, 243, 41);
}
.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    position: relative;
    margin: 10px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 18px !important;
}
.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: rgb(46, 243, 41);
}
.form-control-file{
    display: none;
}
.form-control-file{
    display: none !important;
}
.form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}
.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

@media only screen and (max-width: 1620px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 845px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width: 540px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

 .pagination-container{
    display: flex;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 100px 0 0;
    float: right;
    padding: 0;
}

 .pagination-container li{
    border: none;
}

 .pagination-container li a{
    margin: 10px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

 .pagination-container .selected{
    font-weight: bolder;
    color: white !important;
}
.interns-list-options {
    margin-right: 60px;
}

.interns-list-options p{
    margin: 10px 0 0 0;
    color: #1B3769;
}
.search-container input{
    background-color: #eceef1;
    border-radius: 10px;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}
.table-width{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important; 
}
.scroll-row-section{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
    height: 85vh;
}
.user-container{
    width: 100% !important;
}
.search-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%) !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    height: 30px;
    padding: 0 12px !important;
    margin-top: 4px ;
}
.card-roles{
    font-size: 13px !important;
    color: black !important;
}

.groups-icon {
    font-size: 1.5rem;
    margin: 0 5px;
}

.groups-error-message {
    color: red !important;
}
.register-company{
    width: 100vw;
    height: 100vh;
}

.background-container{
    height: 100vh;
    
}

.background-container-register{
    height: 100vh;
    background-image: url(/static/media/background.937c425e.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    border-radius: 25px !important;
}


.register-company .logoCaintra {
    width: 100px;
    height: auto;
    margin-left: 80%;
}


.register-company form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}

.register-company form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.register-company form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.register-company .description-container {
    padding: 20% 0 0 4%;
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    color: white;
}

.register-company .description-container h2{
    font-weight: bold;
    font-size: 55px;
    margin-bottom: 20px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.register-company .description-container h4{
    font-size: 20px;
    text-align: justify;
    margin-right: 50px;
    color: white !important;
}

.register-company .description-container h5{
    font-weight: bold;
    margin-bottom: 3%;
    color: white !important;
}

.register-company .step-bar {
    margin: 20px 0 0 10px;
}

.register-company .content-class{
    margin: 70px 0 0 0;
    padding: 0;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 6rem;
}

.register-company .buttonsClass{
    background: white;
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

.register-company .next-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
    border: none;
    border-radius: 15px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(255, 255, 255);
}

.register-company .previous-button:hover{
    border: none;
    color: white;
    text-decoration: none;
}
.register-company .next-button:hover{
    color: white;
    text-decoration: none;
    box-shadow: none !important;
}

.register-company .previous-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(255, 255, 255);
}

.register-company .previous-button:hover{
    border: none;
}

.register-company .progress-labels{
    font-weight: bold;
    font-size: 12px;
}

.register-company .progress-labels{
    font-weight: bold;
    font-size: 12px;
}

.register-company p{
    font-weight: bold;
    color: #1B3769;
}

.register-company input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
}

.register-company form {
    padding: 10px;
}
.register-company .form-label{
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.register-company select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.register-company .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
    color: white;
}

.register-company .files svg{
    margin: 3px 3px 0 0;
}

.register-company .files small{
    margin: 0;
}

.register-company .form-file-label:hover{
    cursor: pointer;
}

.register-company .form-control-file{
    display: none;
}

.register-company input[type="checkbox"]{
    height: auto;
}

.register-company .back-button{
    position: fixed;
    z-index:100;
    top: 30px;
    left: 60px;
    font-size: 30px;
    color: #1B3769;
}

.register-company .back-button:hover{
    color: white;
}

.welcome-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.welcome-modal .modal-content{
    background: none;
    background-color: transparent;
    border: none;
}

.welcome-modal .modal-dialog-centered{
    min-width: 90%;
    background: none;
    background-color: transparent;
}

.welcome-modal .modal-header{
    display: flex;
    flex-direction: column;
    border: none;
}

.welcome-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: none;
    background-color: transparent;
}

.welcome-modal .modal-body svg{
    color: #6ce090;
}

.welcome-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-size: 75px;
    font-weight: bold;
}

.welcome-modal .modal-body h3{
    color: white;
    font-size: 40px;
    margin-bottom: 15px;
}

.welcome-modal{
    background-color: rgb(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}
.custom-dropdown{
    background-color: #eceef1 !important;
    border: none !important;
    border-radius: 10px !important;
    color: #5d6369 !important; 
    text-align: left !important;
    padding-left: 3% !important;
}
.menu-custom-dropdown{
    width: 100% !important;
    font-size: 1rem !important;
    font-weight: 400 !important; 
    line-height: 1.5  !important;
    background-color: #6a6e70 !important;
    color: blanchedalmond !important;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
}
.validate-email{
    width: 50% !important;
    align-content: center !important;
    align-self: center !important;
}
.error-label{
    color: tomato !important; 
    font-size: .8rem !important;
    padding: 0;
    margin-bottom: 0px !important;
    margin-left: 2%;
}

.agent {
    margin-top: 5px;
    font-weight: bold;
    color: #1B3769 !important;
}
.dd-wrapper .dd-header{
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px;
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    margin: 5px 0px 0px 1rem;
    box-shadow: none !important;
    font-weight: 600;
}

.dd-wrapper .dd-header p{
    font-weight: 600;
    margin: auto;
}

.dd-wrapper .dd-header-title{
    margin: 0;
    padding: 0;
}

.dd-wrapper ul{
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    margin: 0;
    padding: 0;
}

.dd-wrapper .dd-list{
    position: absolute;
    margin-top: 10px;
    margin-left: 1.2rem;
    width: 400px !important;
    max-height: 60vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
}

.dd-wrapper .dd-list li{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.dd-wrapper .dd-list > *{
    color: black;
    font-weight: lighter;
    font-size: 18px;
}

.dd-wrapper .dd-list li label{
    margin: 0;
    padding: 0;
}

.dd-wrapper .dd-list li input[type=checkbox]{
    width: 11px;
    cursor: pointer;
    margin: 5px 5px 0 5px;
}

.dispersion{
    width: 100%;
    height: 93vh;
}

.dispersion .title {
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.dispersion .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: black;
}
.dispersion .title .default-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    color: white;
    margin: 5px 0px 0px 25px;
}

.dispersion .alter-table{
    display: flex;
    align-items: center;
}

.dispersion .alter-table .csv-button{
    display: inline-flex;
    align-items: center;
    color: #666;
    background:rgba(255, 255, 255, 0.3);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #999;
}

.dispersion .alter-table .disperse-button svg,
.dispersion .alter-table .csv-button svg{
    margin-right: 8px;
    font-size: 25px;
}
.dispersion .alter-table .dd-wrapper{
    display: inline-block;
}
.dispersion .alter-table .dd-header{
    height: 50px;
    background:rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px #999;
    color: #666;
    padding: 0 20px;
    margin: 0;
}

.dispersion .options input,
.dispersion .options select,
.dispersion .options .rw-widget-container,
.dispersion .options .rw-select1{
    background:rgba(255, 255, 255, 0.3) !important;
}

.dispersion .options input,
.dispersion .options select{
    height: 50px;
}

.dispersion .title .filters-menu{
    width: 90%;
}

.dispersion .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto; 
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.my-table-border{
    min-height: 350px !important;
}

.dispersion .table{
    border: none;
    margin: 0;
}

.dispersion .card-header{
    padding: 0;
    margin: 0;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.dispersion .card-header button{
    text-decoration: none;
    color: #1B3769;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.dispersion .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.dispersion .card-header button:active{
    outline: none;
    border: none;
}

.dispersion .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.dispersion .table thead, .dispersion .table th{
    border: none;
    border-color: transparent;
    text-align: left !important;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}

.dispersion .table thead tr th{
    border-top: none;
}

.dispersion .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.dispersion .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.dispersion .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.dispersion .table tbody tr td svg{
    font-size: 12px;
}

.dispersion .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.dispersion .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.dispersion .options{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dispersion .options > *{
    margin: 15px 15px 0px 0 !important;
}

.dispersion .form-group{
    margin-top: 20px;
}

.dispersion label{
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
}

.dispersion input[type=number]{
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 20px;
    width: 10rem;
}

.dispersion .rw-datetime-picker input{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: none;
    width: 8rem;
}

.dispersion .rw-datetime-picker > *{
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.dispersion .options .rw-select{
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
}

.dispersion select {
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    width: 15rem;
}

.dispersion .disperse-and-csv{
    display: flex;
    flex-direction: row;
}

.dispersion .disperse-and-csv .disperse-button{
    background-image: linear-gradient(to right, #5FEC90 40%, #5FEC90 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
}

.dispersion .disperse-and-csv .disperse-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.dispersion .disperse-and-csv .disperse-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.dispersion .disperse-and-csv .csv-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
    color: white;
    text-decoration: none;
}

.dispersion .disperse-and-csv .csv-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.dispersion .disperse-and-csv .csv-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.dispersion .company-accordion{
    width: 100% !important;
    background-color: red;
}

.dispersion .card-body{
    padding: 0;
    margin: 0;
}
.dispersion-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 30px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}

.dispersion .search-icon:hover{
    color: darkgreen;
}
.dispersion .search-container{
    width: 300px !important;
    margin: 0;
    float: left !important;
}
.dispersion .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}
.dispersion .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}
.dispersion .search-icon:hover{
    color: darkgreen;
}
.dispersion .search-container input{
    height: 50px;
    border: none;
    border-radius: 0 25px 25px 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.dispersion .search{
    float: left;
}
.dispersion .search input{
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    float: left;
}
.screen-select-fixed {
    min-height: 65vh !important;
}


.green-button{
    margin: 10px 0px;
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    border-radius: 25px;
    font-style: normal;
    font-weight: 400;
    color: #FAFAFA;
    min-width: 100px;
    padding: 10px 20px;
    border: none !important;
    outline-style: none !important;
    justify-content: center;
    align-items: center;
    height: 44px;
    display: flex !important;
    font-size: 15px;
}

.dispersion .dropdown-options{
    margin: 10px 0px 10px 0px;
    padding: 15px;
}

.dispersion .dropdown-options .dropdown-menu{
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
    width: 250px;
}

.dispersion .options-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.dispersion .options-search-container a{
    font-size: 14px !important;
    font-weight: 300;
}

.dispersion .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.dispersion .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}

.dispersion .options .btn{
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 25px;
    color: #666;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    height: 50px;
}

.dispersion .alter-table .disperse-button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 25px;
}

.dispersion .button-color{
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: none !important;
    box-shadow: none !important;
}

.dispersion .list-interns-container{
    min-height: 300px !important;
}

.centered-component{
    margin: 0 auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.dispersion-company-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dispersion-company-header p{
    color: red;
    margin: 0px 8px 0px 10px;
}

.dispersion .companies-filter .dropdown-menu{
    width: 320px !important;
    max-height: 70vh !important;
    padding: 17px 14px 17px 17px !important;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
}

.dispersion .companies-filter .dropdown-menu .companies-search-container{
    max-height: 50vh;
    margin-top: 10px;
    overflow-y: scroll;
}

.dispersion .companies-filter .dropdown-menu::-webkit-scrollbar-track {
    background-color: white !important;
}

.dispersion .companies-filter .dropdown-menu .filter-form-text{
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.dispersion .companies-filter .buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
}

.dispersion .w-text{
    color: white !important;
}

.dispersion .companies-filter .dropdown-menu .check-filter .form-check-label{
    font-size: 14px !important;
    font-weight: 300;
}

.dispersion .companies-filter .dropdown-menu .check-filter{
    display: flex;
    align-items: center;
}


@media (max-width: 1105px) {
    .dispersion .search{
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
}
.pending{
    width: 100%;
    height: 85vh;
}

.pending .title {
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.pending h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: black;
    margin-bottom: 20px;
}

.pending .alter-table{
    display: flex;
    align-items: center;
}

.pending .alter-table .disperse-button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
}
.pending .alter-table .csv-button{
    display: inline-flex;
    align-items: center;
    color: #666;
    background:rgba(255, 255, 255, 0.3);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #999;
}

.pending .alter-table .disperse-button svg,
.pending .alter-table .csv-button svg{
    margin-right: 8px;
    font-size: 25px;
}
.pending .alter-table .dd-wrapper{
    display: inline-block;
}
.pending .alter-table .dd-header{
    height: 50px;
    background:rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px #999;
    color: #666;
    padding: 0 20px;
    margin-right: 20px;
}

.pending .options input,
.pending .options select,
.pending .options .rw-widget-container,
.pending .options .rw-select{
    background:rgba(255, 255, 255, 0.3) !important;
}

.pending .options input,
.pending .options select{
    height: 50px;
}

.pending .main-table{
    width: 97%;
    margin-top: 30px;
}
.pending .main-table .row{
    margin-left: 0;
    color: #666;

}
.pending .main-table .table-body{
    box-shadow: 0 0 5px #aaa;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    color: black;
    font-weight: 500;
    height: 70px;
    border-radius: 10px;
    margin-top: 10px;
}

.pending .title .default-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    margin: 5px 0px 5px 1rem;
}

.pending .title .default-button svg{
    font-size: 10px;
    margin-right: 5px;
}

.pending .title .filters-menu{
    width: 90%;
}

.pending .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.pending .table{
    border: none;
    margin: 0;
}

.pending .table thead, .table th{
    border: none;
    border-color: transparent;
    text-align: center;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}

.pending .table thead tr th{
    border-top: none;
}

.pending .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.pending .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.pending .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.pending .table tbody tr td svg{
    font-size: 12px;
}

.pending .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.pending .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.pending .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0.5rem 0;
}

.pending .options > *{
    margin: 0 15px 0 0;
}

.pending .form-group{
    margin-top: 20px;
}

.pending label{
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
}

.pending input[type=number]{
    background-color: #eceef1;
    border: none;
    border-radius: 20px;
    width: 15rem;
}

.pending .rw-datetime-picker input{
    background-color: #eceef1;
    border: none;
    width: 15rem;
}

.pending .rw-datetime-picker > *{
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.pending .rw-select-bordered{
    border: none;
    background-color: #eceef1;
}

.pending select {
    border: none;
    background-color: #eceef1;
    border-radius: 20px;
    width: 15rem;
}

.pending .disperse-and-csv{
    display: flex;
    flex-direction: row;
}

.pending .disperse-and-csv .disperse-button{
    background-image: linear-gradient(to right, #5FEC90 40%, #5FEC90 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
}

.pending .disperse-and-csv .disperse-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.pending .disperse-and-csv .disperse-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.pending .disperse-and-csv .csv-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
    color: white;
    text-decoration: none;
}

.pending .disperse-and-csv .csv-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.pending .disperse-and-csv .csv-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.pending-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 30px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}
.interns {
    width: 100%;
    min-height: 85vh !important;
}

.interns h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}
.title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
    margin-bottom: 20px;
}

.universities .alter-table{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.universities .alter-table .add-university,
.universities .alter-table .reload-table{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    width: 50px;
    font-weight: 600;
    font-size: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px #888;
    margin-right: 20px;
    cursor: pointer;
}

.universities .alter-table .search-icon{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50% 0 0 50%;
}

.universities .alter-table .search-container{ margin: 0; }
.universities .alter-table .search-container input{
    height: 50px;
    border-radius: 0 25px 25px 0;
    border: none;
    background:rgba(255, 255, 255, 0.6);
    width: 270px;
    margin-right: 20px;
 }
.universities .alter-table .search-container input:focus{
    box-shadow: none;
}
.interns .title-search-bar{
    display: flex;
    flex-direction: row;
}

.interns .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.interns .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.interns .search-container{
    width: 90%;
}

.interns .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.interns .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.interns .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.interns .search-bar-container .show{
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
}

.interns input{
    background-color: #eceef1;
    border-radius: 10px;
}

.interns select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.interns label{
    font-weight: bold;
}

.interns .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.interns .form-label{
    color: black;
    font-size: 15px;
}

.interns .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.interns .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-intern a{
    color: #1B3769;
}

.new-intern a:hover{
    color: rgb(46, 243, 41);
}

.new-intern svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.unsuscribe-intern-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.unsuscribe-intern-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.unsuscribe-intern-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.unsuscribe-intern-modal small{
    color: #1B3769;
    margin: auto;
}

.unsuscribe-intern-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 10px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.unsuscribe-intern-modal .btn-danger{
    border: 1px solid #666;
    color: #666;
    background: transparent;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.unsuscribe-intern-modal .btn-danger:hover,
.unsuscribe-intern-modal .btn-danger:focus,
.unsuscribe-intern-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.unsuscribe-intern-modal label{
    font-weight: bold;
    font-size: 14.5px;
}


.verify-interns-docs .form-overflow {
    height: 75vh;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.verify-interns-docs .profile-picture{
    width: 150px;
    height: 150px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}

.verify-interns-docs .profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}

.verify-interns-docs .form-control-file{
    display: none;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.verify-interns-docs .profile-picture svg:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs .row-selection a{
    margin-right: 10px;
}

.verify-interns-docs .row-selection a:hover{
    color: black;
}

.verify-interns-docs .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.verify-interns-docs .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.verify-interns-docs a{
    color: #1B3769;
}

.verify-interns-docs a:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.verify-interns-docs h6{
    font-weight: bold;
    font-size: medium;
    margin: 0 0 15px 0;
    color: #1B3769;
}

.verify-interns-docs .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.verify-interns-docs .modal-header{
    display: flex;
    flex-direction: column;
}

.verify-interns-docs p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}


.verify-interns-docs small{
    color: #1B3769;
    margin: auto;
}

.verify-interns-docs input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs .form-label{
    color: black;
    font-size: 15px;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
}

.verify-interns-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.verify-interns-docs .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.verify-interns-docs .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-cc-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.new-cc-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.new-cc-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.new-cc-modal input[id="description"]{
    line-height: 140%;
    padding: 50px 10px;
}

.new-cc-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-cc-modal .btn-danger{
    border: 1px solid #666 !important;
    color: #666;
    background: transparent;
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-cc-modal .btn-danger:hover,
.new-cc-modal .btn-danger:focus,
.new-cc-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.new-cc-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.interns-list{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 85vh;
}

.interns-list .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.interns-list .options a{
    color: #1B3769;
    margin: 0 0 0 3px;
}

.interns-list .options a:hover{
    color: rgb(46, 243, 41);
}

.interns-list .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    width: 100%;
    height: 75vh;
}

.interns-list .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 250px;
    height: 260px;
    box-shadow: 3px 5px 5px #d7d7d7;
}

.interns-list .card .card-body .profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    position: relative;
    margin: 10px;
}

.interns-list .card .card-body .profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 22px;
}

.interns-list .card .form-control-file{
    display: none;
}

.interns-list .card .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.interns-list .card .form-file-label:hover{
    color: rgb(46, 243, 41);
}

.interns-list .card .card-body .card-options svg:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .pagination-container{
    display: flex;
    flex-direction: row;
    color: #1B3769;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 0 0 0;
    float: right;
    padding: 0;
}

.manage-departments .pagination-container li{
    border: 0.5px solid #d7d7d7;
}

.manage-departments .pagination-container li a{
    margin: 8px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

.pagination-container .selected{
    font-weight: bolder;
    color: rgb(46, 220, 41);
}

.interns-list-options {
    display: flex;
    flex-direction: row;
    margin-right: 60px;
}

.interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.rotate{
    -webkit-animation: rotate 1.5s linear infinite;
            animation: rotate 1.5s linear infinite; 
}

.manage-departments a{
    color: #1B3769;
    font-size: 18px;
    margin: 0 0 0 5px;
}

.manage-departments .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.manage-departments .title a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 20px;
}

.manage-departments  a:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .title svg{
    margin: 8px 5px 0 0;
}

.manage-departments p{
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: auto;
    padding-top: 5px;
}

.manage-departments .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
}

.manage-departments button {
    background: none;
    background-color: transparent;
    border: 0;
}

@-webkit-keyframes rotate{
    to{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate{
    to{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}



@media only screen and (max-width: 1620px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1337px) {
    .manage-departments .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 991px) {
    .verify-interns-docs .modal-dialog-centered{
        max-width: 95%;
        width: 100%;
    }
}

@media only screen and (max-width: 845px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

    .interns-list{
        height: 68vh;
    }
}

@media only screen and (max-width: 540px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

.universities-table{
    min-height: 40vh;
    overflow-y: scroll;
    width: 97%;
}

.table-universities{
    align-content: center;
}

div.thead-row-universities{
    background-color: #E9EDF3;
    padding: 0px;
    margin: 0px;
}

div.col-head-university{
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 10px;
}

div.college-name{
    color: #1B3769;
    font-weight: bold;
}

div.career{
    padding-left: 10px;
}

div.col-university-info{
    display:flex;
    align-items: center;
    border-bottom: 0.1px solid rgb(222, 226, 230);
    padding: 3px;
    margin: 0px;
    min-height: 110px;
}

div.row-university-info{
    padding: 0px;
    margin: 0px;
    min-height: 110px;
}

.university-info-table, .university-info-table .accordion-colapse{
    background-color: transparent!important;;
}

#table-universities .row-university{
    background: #189e9a;
}

#table-universities .accordion div.row-university-info{
    background-color: white;
}

.university-info-table:nth-of-type(odd){
    background-color: #f0f0f0;
}

#table-universities div.row-university-info:hover {background-color: #efefef;}

div.table-faculties{
    margin-left: auto;
    width: 99%;
}

div.table-careers{
    margin-left: auto;
    width: 99%;
}

.accordion-colapse{
    background-color: #FFFFFF;

}

.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    display: flex;
    position: relative;
    margin: 10px 20px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 22px;
}
.profile-picture{
    width: 110px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: #189e9a;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    align-self: center !important;
    height: 100%;
}


.logistics .alter-table .add-university,
.logistics .alter-table .reload-table{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    width: 50px;
    font-weight: 600;
    font-size: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px #888;
    margin-right: 20px;
    cursor: pointer;
}

.logistics .alter-table .search-icon{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50% 0 0 50%;
}

.logistics .alter-table .search-container{ margin: 0; }
.logistics .alter-table .search-container input{
    height: 50px;
    border-radius: 0 25px 25px 0;
    border: none;
    background:rgba(255, 255, 255, 0.6);
    width: 270px;
    margin-right: 20px;
 }
.logistics .alter-table .search-container input:focus{
    box-shadow: none;
}

.logistics .alter-table .Button{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 15px;
    border-radius: 25px;
    height: 50px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
}
.logistics .alter-table .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}


.logistics .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;
    align-items: center;
}
.logistics .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: 600;
    margin-right: 15px;
}


.logistics .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}
.logistics .options .btn svg{
    margin-right: 10px;
}
.logistics .options > *{
    margin: 0 15px 0 0;
}

.logistics .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.logistics .options .filters-menu-companies{
    min-width: 200px !important;
}

.logistics .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}

.logistics .options label{
    margin: 2.5px 0 0 0;
}

.logistics .options .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.logistics .options .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -15px;
}

.logistics .actions-icon {
    width: 20px;
}


.alta {
    color: green;
}

.confirmado {
    color: green;
}
.terminado {
    color: blue;
}
.pausado {
    color: red;
}
.retenido {
    color: #666;
}
.enviado {
    color: black;
}
.in-process {
    color: rgba(198, 198, 73, 0.927);
}
.empresa {
    color: #666;
}
.asesora {
    color: red;
}
.autoservicio {
    color: black;
}
.no-origin{
    color: rgba(198, 198, 73, 0.927);;
}

.logistics .logistic-table-scroll {
    max-width: 97%;
}

.logistics .logistic-table-scroll table {
    overflow: auto;
    max-height: 70vh !important;
}

 .logistics .logistic-table-scroll table thead {
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2 !important;
}


.logistics .logistic-table-scroll table tbody {
    background: rgba(255, 255, 255, 255) !important;
}

.logistics-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.logistics-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.logistics-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.logistics-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.logistics-modals .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logistics-modals .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logistics-modals .btn-danger:hover, 
.logistics-modals .btn-danger:focus,
.logistics-modals .btn-danger:active{
    background: white !important;
    border: 1px solid #666 !important;
    color: #666 !important;
    box-shadow: none;
}

.logistics-modals td{
    cursor: pointer;
}

.logistics td{
    min-height: 40px !important;
}

.logistics .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
}

.logistics .table tbody tr{
    height: auto !important;
}

.logistics-search-container{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.input-logistics{
    background-color: #eceef1;
    border: none;
    border-radius: 20px;
    width: 15rem;
}

.input-logistics:focus{
    outline: none !important;
    box-shadow: none;
}

.upload-accounts-table{
    max-height: 220px !important; 
    overflow-x: scroll !important;
}

.table-status{
    margin-right: 30px !important;
    padding-left: -2px !important;
}

.divider-status-table{
    border-radius: 0px 0px 0px 0px !important;
}

.logistics .alter-table .Button-disabled{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 15px;
    border-radius: 25px;
    height: 50px;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5) !important;
    color: #666 !important;
}

.snipper-class{
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
}
.logistics .force-change-background {
    background: rgba(6, 209, 205, 1) !important;
    z-index: 4;
}
.logistics .companies-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.logistics .logistics-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.logistics .logistics-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.logistics .logistics-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.logistics .logistics-modals small{
    color: black;
    margin: auto;
    text-align: center;
}


.logistics .logistics-modals .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.logistics-modals .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.logistics-modals label {
    font-size: 12px;
    margin: 0;
}

.logistics .logistics-modals select {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}
.logistic-accounts-table{
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
}

.modal-height {
    height: 30% !important;
    max-height: 30% !important;
}

.modal-width {
    width: 80% !important;
    max-width: 80% !important;
}
.approve-orgs-and-comps {
    width: 100%;
    min-height: 85vh !important;
}

.approve-orgs-and-comps .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.approve-orgs-and-comps .title-search-bar{
    display: inline-block;
}

.approve-orgs-and-comps .alter-table{
    display: flex;
    align-items: center;
}

.approve-orgs-and-comps .add-company{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    font-weight:100;
    cursor: pointer;
}

.approve-orgs-and-comps .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.approve-orgs-and-comps .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.approve-orgs-and-comps h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}
.scroll{
    max-height: 80vh;
    overflow-y: scroll !important;
}

.company-form form{
    width: 95%;
    height: 78vh;
    padding-right: 10px;
    padding-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.company-form .title-container,
.users .title-container,
.manage-departments .title-container,
.roles .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.button-back {
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}


.users .title-container .users-options, 
.manage-departments  .title-container .users-options, 
.roles .title-container .users-options{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.users .title-container .users-options svg,
.manage-departments .title-container .users-options svg,
.roles .title-container .users-options svg{
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}
.company-form .title-container svg:hover, 
.users .title-container svg.back:hover,
.manage-departments .title-container svg.back:hover,
.roles .title-container svg.back:hover{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}

.company-form p{
    margin-top: 20px;
    font-size: 1.2em;
}

.company-form input, .company-form .input{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
    border: none;
    border-radius: 10px;
}

.company-form .btn-container{
    width: 95%;
}
.company-form .btn-container .btn{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.users form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}


.users form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.users form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.approve-orgs-and-comps .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    margin-left: 15px;
    font-size: 28px;
    color: #666;
}
.approve-orgs-and-comps .search-icon:hover{
    color: darkgreen;
}
.approve-orgs-and-comps .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .search-container input{
    border: none;
    height: 50px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
}

.company-form .form-overflow{
    height: 68vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.company-form p{
    font-weight: bold;
    color: #1B3769;
}

.company-form .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.company-form .files svg{
    margin: 3px 3px 0 0;
}

.company-form .files small{
    margin: 0;
}

.company-form .form-label{
    font-weight: bold;
}

.company-form .form-file-label:hover{
    cursor: pointer;
}

.company-form .form-control-file{
    display: none;
}

.company-form input[type="checkbox"]{
    height: auto;
}

.company-form .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.company-form .title a{
    margin: 4px 5px 0 0;
    color: #1B3769;
    font-size: 20px;
}

.company-form .title a:hover{
    color: rgb(46, 243, 41);
}

.company-form .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.company-form button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-form select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.list-companies{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 60vh;
}

.list-companies .title-container{
    margin-top: 30px;
    padding-left: 30px;
    color: #666;
}

.list-companies .title-container h5{
    font-weight: 400;
}
.list-companies .table-row{
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 95%;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px #ccc;
}

.list-companies .table-row .proved-container{
    font-weight: 600;
    font-size: 18px;
}

.list-companies .table-row .name-container{
    margin-right: 20px;
    font-size: 1.2em;
}

.list-companies .options-container{
    margin-left: auto;
}

.list-companies .table-row .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em;
    background: transparent !important;
    border: none;
    color: black;
    box-shadow: none;
    cursor: pointer;
}

.list-companies .table-row .dropdown-toggle:active,
.interns-list .table-content .dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.list-companies .table-row .dropdown-toggle::after{
    display: none;
}

.list-companies .table-row .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.list-companies .table-row .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.list-companies .table-row .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.list-companies .table-row .row{
    margin-left: 0;
}

.list-companies .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: 20px 0 0 0;
}

.list-companies .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 220px;
    height: 205px;
    box-shadow: 3px 5px 5px #d7d7d7;
    margin: auto;
}

.list-companies .flip-card {
    background-color: transparent;
    width: 220px;
    height: 175px;
    margin: auto;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
}

.flip-card-back {
    position: absolute;
    bottom: -25px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.list-companies .card a{
    color: #1B3769;
    font-size: 25px;
    padding: 0px 5px 0px 5px;
}

.list-companies .card a:hover{
    color:rgb(46, 243, 41);
}

.list-companies .flip-card-inner .card-footer{
    position: absolute;
    bottom: 0;
}

.list-companies .card .company-name{
    color: #1B3769;
    font-weight: bold;
    font-size: medium;
    margin-bottom: 2px;
}

.list-companies .card .company-options{
    text-align: center;
    margin: 5px 0;
}

.list-companies .card .company-options .option{
    display: inline-block;
    width: 40%;
    font-size: 28px;
}

.list-companies .card .company-options .option svg:hover{
    cursor: pointer;
}

.list-companies .card .company-options .approve{
    color: green;
}

.list-companies .card .company-options .deny{
    color: tomato;
}

.company-docs a{
    color: #1B3769;
    margin: 0 10px 0 0px;
    font-size: 20px;
}

.company-docs .form-file-label{
    color: #1B3769;
    font-size: 20px;
}

.company-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.company-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.company-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.company-modals p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.company-modals small{
    color: #1B3769;
    margin: auto;
}

.company-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.company-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger:hover{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
}

.users .title, .roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.users .title a, .roles .title a{
    margin: 4px 5px 0 0;
    color: #333;
    font-size: 20px;
}

.users .title a:hover, .roles .title a:hover{
    color: rgb(46, 243, 41);
}

.users .title h4, .roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #333;
}

.users .users-options a, .roles .users-options a{
    font-size: 25px;
    margin-left: 10px;
}

.users .user-cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: .5rem 0 0 0;
}

.users .card{
    border-radius: 15px;
    width: 17.5rem;
    height: 17.5rem;
}

.users .card-header p{
    color: #1B3769;
    font-weight: bolder;
    font-size: small;
    margin: auto;
    text-align: center;
}

.users .user-card-body{
    display: flex;
    flex-direction: column;
}

.card-department-body{
    padding: 0 !important;
}
.users .user-card-body div{
    display: flex;
    flex-direction: row;
}

.users .user-card-body div svg{
    color: #1B3769;
    margin: 2.5px .5rem 0px 0px;
    width: 18px;
}

.users .user-card-body div p{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .card-header:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.users .card-footer:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.users .user-card-footer .options{
    float: right;
}

.users .user-card-footer .options a svg{
    color: #1B3769;
    width: 30px;
    font-size: 25px;
}

.users .user-card-footer .options a svg:hover{
    color: rgb(46, 243, 41);
}

.users .create-user{
    height: 75vh;
    padding: 1rem;
    overflow-y: auto;
}

.users .create-user .checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}


.users .create-user input{
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 15px;
    height: 50px;
}

.users .create-user label{
    color: #1B3769;
    font-size: 14px;
    font-weight: bold;
}

.users .create-user button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 18px;
    margin-right: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.users .table{
    width: 95%;
    margin-bottom: 150px;
}
.users .table h5{
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #666;
    margin-bottom: 15px;
}
.users .table .header{
    background: transparent;
    margin-left: 0;
    padding: 0 10px;
    margin-bottom: 5px;
    color: #666;
}
.users .table .body{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background:rgba(255, 255, 255, 0.6);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 0;
}
.users .table .options{
    text-align: center;
}
.users .table .options svg{ color: #333; }

.roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.roles .title a{
    margin: 4px 5px 0 0;
    color: black;
    font-size: 20px;
}

.roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.roles .roles-list{
    width: 95%;
    padding: 0 20px;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .toggle{
    padding: 0;
    border: none;
    background: transparent;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card{
    border: none;
    margin-bottom: 15px;
    border-radius: 15px  !important;
    box-shadow: 0 3px 8px #aaa;
    background: transparent;
    color: black;
}
.roles .roles-list .card-header{
    border: none;
    background: rgba(255,255,255,0.6);
    color: black !important;
}
.roles .roles-list .card-header .name-container{
    text-align: left;
    color: rgb(0, 0, 0) !important;  
}
.roles .roles-list .btn-link:hover,
.roles .roles-list .btn-link:focus{
    text-decoration: none !important;
    box-shadow: none;
}
.roles .roles-list .card-header .icon-container{
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card-header .btn:hover,
.roles .roles-list .card-header .btn:focus,
.roles .roles-list .card-header .btn:hover{
    text-decoration: none;
    box-shadow:none;
    outline: none;
}

.roles .roles-list .card-header button{
    color: white;
    font-weight: bolder;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}

.roles .roles-list .card-header svg{
    font-size: 20px;
    display: inline-block;
    color: black;
}

.roles .roles-list .card-header .options{
    color: white;
    float: right;
    font-size: 18px;
}

.roles .roles-list .card-header .options > *{
    margin-left: 5px;
}

.roles .roles-list .card-body{
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
}
.roles .roles-list .show{
    background: rgba(255,255,255,0.6);
}

.roles .roles-list .card-body .user-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    min-height: 50px;
    margin: 10px auto;
    width: 95%;
    justify-content: space-between;
    background: #efefef;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
}
.roles .roles-list .card-body .user-options .user-name{
    margin: 0;
    text-align: left;
}
.roles .roles-list .card-body .user-options svg{
    color: #666;
    font-size: 15px;
    margin-right: 5px;
}

.roles .roles-list .card-body .user-options .option{
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
}

.roles .roles-list .card-body .user-options .option:hover{
    color: #333;
}

.roles .roles-list .card-body .user-options .assign-role{
    color: black;
}

.manage-departments .card{
    border: none;
    box-shadow: 0 5px 5px #666;
}

.manage-departments .card-header{
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card-header {
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card svg{
    font-size: .9em;
    margin-left: 3px;
    color: #666 !important;
}

.manage-departments .card-header svg{
    font-size: .9em;
    margin-left: 3px;
    color: white !important;
}

.companies-modals p{
    font-weight: bold;
    font-size: large;
    text-align: center;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.companies-modals table{
    padding: 10px;
    margin: auto;
    max-width: 98%;
}

.companies-modals table tr td{
    text-align: center;
    font-weight: bolder;
    font-size: 14px;
}

.change-permissions-modal .modal-dialog{
    max-width: 80%;
    margin: auto;
}

.companies-modals small{
    color: #1B3769;
    margin: auto;
    text-align: center;
}

.companies-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.companies-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .btn-danger{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.companies-modals .modal-header{
    display: flex;
    flex-direction: column;
}
.options a{
    margin: 0 0 0 10px;
    color: #1B3769;
    font-size: 22x !important;
}
.options a:hover{
    color: rgb(46, 243, 41);
}
.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    position: relative;
    margin: 10px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 18px !important;
}
.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: rgb(46, 243, 41);
}
.form-control-file{
    display: none;
}
.form-control-file{
    display: none !important;
}
.form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}
.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

@media only screen and (max-width: 1620px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 845px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width: 540px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

 .pagination-container{
    display: flex;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 100px 0 0;
    float: right;
    padding: 0;
}

 .pagination-container li{
    border: none;
}

 .pagination-container li a{
    margin: 10px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

 .pagination-container .selected{
    font-weight: bolder;
    color: white !important;
}
.interns-list-options {
    margin-right: 60px;
}

.interns-list-options p{
    margin: 10px 0 0 0;
    color: #1B3769;
}
.search-container input{
    background-color: #eceef1;
    border-radius: 10px;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}
.table-width{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important; 
}
.scroll-row-section{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
    height: 85vh;
}
.user-container{
    width: 100% !important;
}
.search-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%) !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    height: 30px;
    padding: 0 12px !important;
    margin-top: 4px ;
}
.card-roles{
    font-size: 13px !important;
    color: black !important;
}

.groups-icon {
    font-size: 1.5rem;
    margin: 0 10px;
}

.groups-error-message {
    color: red !important;
}
.privacy{
    width: inherit;
    height: inherit;
}

.privacy .background-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.privacy .form-card{
    background-color: rgba(255, 255, 255, 0.96);
    padding: 20px;
    border-radius: 5px;
    height: 90vh;
    overflow-y: auto;
}

.privacy form{
    width: 70%;
    margin: auto;
}

.privacy span{
    margin-left: 25%;
    margin-right: 5%;
    text-align: right;
    font-size: 16px;
}

.privacy .container-img{
    margin-top: 0%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.privacy select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.privacy .container{
    margin-left: 3%;
    background-color: #fefefe;
    font-size: 16px;
    border-radius: 5px 5px 5px 5px;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
    height: 500px;
    width: 50%;
}

.privacy .icons{
    margin-left: 5%;
}


.privacy small{
    margin: auto;
    line-height: 17.57px;
}

.privacy .InternsLogo {
    width: 150px;
    height: auto;
}

.privacy .logoCaintra {
    width: 100px;
    height: auto;
}

.privacy .text-container{
    margin-top: 30px;
}

.privacy .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.privacy .logos{
    margin-left: 3%;
    display: flex;
    
}

.privacy input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.privacy .form-label{
    color: black;
    font-size: 15px;
}

.privacy .row-logo{
    margin: 25px 5px 5px 5px;
}

.privacy .row-contact{
    text-align: center;
    margin-top: 5%;
}

.privacy .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.privacy .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    padding-top: 40%;
}

.privacy .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.privacy .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.privacy .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.privacy .logo{
    padding-top: 2%;
    height: 100px;
    margin-left: 3%;
}

.privacy .footer-content{
    display: flex;
    
}

.privacy .text-foot{
    display: flex;
    align-items: flex-end;
}
                       

.privacy .files svg{
    margin: 3px 3px 0 0;
}


.privacy .files small{
    margin: 0;
}

.privacy .form-file-label:hover{
    cursor: pointer;
}

.privacy .form-control-file{
    display: none;
}

.privacy input[type="checkbox"]{
    height: auto;
}

.privacy .success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}

@media only screen and (min-width: 1920px) {
    .privacy .row-selection{
        margin-bottom: 10%;
    }    

    .privacy input{
        height: 50px;
    }

    .privacy .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .privacy .row-contact{
        padding-top: 30px;
    }
    .privacy span{
        margin-left: 25%;
        margin-right: 5%;
        text-align: right;
        font-size: 20px;
    }
    .privacy .container{
        margin-left: 3%;
        background-color: #fefefe;
        font-size: 20px;
        border-radius: 5px 5px 5px 5px;
        padding-left: 20px;
        padding-right: 40px;
        box-shadow: 5px 5px 5px rgb(197, 197, 197);
        height: 500px;
        width: 50%;
    }
    .privacy .container-img{
        margin-top: 3%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .privacy .container-img .privacy-img{
        margin-right: 15%;
    }
}


@media only screen and (min-height: 1321px) {
    .privacy .container{
        height: 900px;
        width: 1200px;
        font-size: 35px;
    }
    .privacy .privacy-img{
        height: 900px;
    }
}

@media only screen and (max-width: 767px) {
    .privacy .container{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .privacy .row-selection a{
        font-size: 20px;
    }
}
.uploads {
    width: 100%;
    min-height: 85vh !important;
    font-family: "Mulish", 'Verdana', 'Tahoma';
}

.uploads .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.uploads .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
    margin-top: 5px;
}

.uploads .alter-table {
    display: flex;
    align-items:center;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
    justify-content:space-between;
}

.uploads .alter-table .Button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 25px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: -20px;
}

.uploads .alter-table .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.uploads .row-selection .section{
    margin-right: 10px;
    background: #D9D9D9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}

.uploads .row-selection a:hover{
    text-decoration: none;
}

.uploads .row-selection .selected{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    opacity: 0.8;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}

.uploads .row-selection .color-white{
    font-weight: bold;
    color: white;
}

.uploads .row-selection .middle{
    background: #D9D9D9;
    margin-right: -15px;
    margin-left: -25px;
    padding-left: 20px;
}

.uploads .row-selection p{
    color: #535A63;
    font-weight: lighter;
    font-size: 20px;
}

.uploads .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.uploads .table-container {
    display: flex;
    width: 97%;
}

.uploads .table-container .input-text{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.header-height {
    height: 120px;
}

.uploads .spacer{
    background: transparent !important;
    height: 10px !important;
}

.table-scroll {
    overflow-x: scroll !important;
}

.uploads .table{
    width: 100%;
    display: inline-block !important;
    border: none;
    margin: 0;
}

.uploads .table thead,.uploads .table th{
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2 !important;
    font-size: 1em !important;
    font-weight: 500 !important;
}

.uploads .table thead th:nth-child(1){
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 3 !important;
}

.uploads .table thead th:nth-child(2){
    position: -webkit-sticky;
    position: sticky;
    left: 37px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(3){
    position: -webkit-sticky;
    position: sticky;
    left: 260px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(4){
    position: -webkit-sticky;
    position: sticky;
    left: 448px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(5){
    position: -webkit-sticky;
    position: sticky;
    left: 571px;
    z-index: 3 !important;
}

.uploads .table tbody td:nth-child(1){
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1 !important;
}

.uploads .table tbody td:nth-child(2){
    position: -webkit-sticky;
    position: sticky;
    left: 37px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(3){
    position: -webkit-sticky;
    position: sticky;
    left: 260px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(4){
    position: -webkit-sticky;
    position: sticky;
    left: 448px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(5){
    position: -webkit-sticky;
    position: sticky;
    left: 571px;
    z-index: 0;
    z-index: 1 !important;
}

.uploads .table tbody td{
    z-index: 1;
    background: rgb(255, 255, 255)!important;
}

.uploads .table tbody tr td:first-child { border-top-left-radius: 5px; }
.uploads .table tbody tr td:last-child { border-top-right-radius: 5px; }
.uploads .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.uploads .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.uploads .table thead tr th{
    border: none;
}

.uploads .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.uploads .table thead tr .long-text{
    min-width: 82px;
}

.uploads .table thead tr .longer-text{
    min-width: 124px;
}

.uploads .table tbody tr td svg{
    font-size: 12px;
}

.uploads .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.uploads .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}


.prefacture-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.prefacture-modals input{
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
}
.uploads .center-container{
    display: flex;
    align-items: center;
}

.uploads .center-container .spinner-border{
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-top: 5px !important;
}
.help .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}


.help .pdf-viewer{
    height: 80vh;
    width: 97%;
}
.amounts .save-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    min-width: 100px;
}

.amounts .row-selection .selected{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.amounts .row-selection p{
    font-size: 18px;
    color: black;
    margin: 0 auto;
}
.amounts .row-selection .section{
    margin-right: 10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.amounts .row-selection{
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 15px;
    background-size: 30%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.amounts .row-selection .text-selected {
    color: #FAFAFA;
    font-weight: 700;
}

.amounts .row-selection .text-section {
    color: #535A63;
    font-weight: 400;
}

.amounts .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}

.amounts .text-form{
    background: #FFFFFF;
    opacity: 0.8;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}
.amounts .dispersion-text{
    background: #FFFFFF;
    opacity: 0.8;
    border-radius: 20px 20px 20px 20px;
    width: 40%;
    text-align: center;
    margin-left: 5px;
}
.amounts .dispersion-row{
    display: flex;
    align-items: center;
}
.amounts .error{
    background: rgba(215, 82, 82, 0.8);
}

.amounts .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
    width: 97%;
}

.amounts .table{
    border: none;
    margin: 0;
}

.amounts .card-header{
    padding: 0;
    margin: 0;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.amounts .card-header button{
    text-decoration: none;
    color: #1B3769;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.amounts .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}


.amounts .card-header button:active{
    outline: none;
    border: none;
}

.amounts .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.amounts .table thead, .amounts .table th{
    border: none;
    border-color: transparent;
    text-align: center !important;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}


.amounts .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.amounts .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.amounts .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.amounts .table tbody tr td svg{
    font-size: 12px;
}

.amounts .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.amounts .wi-90{
    width: 90px !important;
}

.amounts .wi-110{
    width: 110px;
}

.amounts .wi-70{
    width: 70px;
}

.amounts .center-text{
    text-align: center;
}

.amounts .check-container{
    display: flex;
    align-items: center;
}
.amounts .line-bottom{
    border-bottom: 1px solid;
    border-bottom-color: gainsboro;
}
.amounts .max-vh{
    height: 70vh;
    overflow: auto;
}

.amounts .date-selector-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center
}

.amounts .date-selector-container > *{
    display: flex;
    flex-direction: row;
    margin: 10px 10px 10px 0px;
}

.amounts .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.amounts .table-amounts{
    width: 97% !important;
    margin-top: 20px;
}

.amounts .table-amounts tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
}

.amounts .table-amounts tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table-amounts tbody {
    background: rgba(255, 255, 255, 255) !important;
}

.amounts .table-amounts thead {
    background: #E9EDF3;
    color: white !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.amounts .table-amounts tr{
    height: 30px;
}

.amounts .total{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
}

.amounts .total th{
    font-weight: bold;
    font-size: 20px !important;
}


.amounts .table-amounts{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.amounts .table-amounts tbody tr:last-child th:first-child{
    border-bottom-left-radius: 10px;
}

.amounts .table-amounts tbody tr:last-child  th:last-child{
    border-bottom-right-radius: 10px;
}


.amounts .total-container{
    width: 97%;
}

.amounts .total-container .total{
    display: flex;
    justify-content: flex-end;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.amounts .total-container{
    width: 97%;
    background-color: #E9EDF3 !important; 
    border-radius: 15px;
    border: 1px solid #d7d7d7;
    margin-top: 10px;
}
.amounts .footer-total{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    padding: 10px;
}

.amounts .footer-total p{
    margin: 0;
}

.amounts .delete-bank{
    display: flex;
    align-items: center;
    justify-content: center;
}

.amounts .delete-bank b{
    margin-right: 10px;
}

.amounts .delete-bank .trash-icon:hover{
    cursor: pointer;
}

.amounts .reload-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    font-size: 25px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    width: 50px;
    color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amounts .reload-button:hover{
    cursor: pointer;
}


.custom-date-picker{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
}

.custom-date-picker .react-datepicker-wrapper input{
    background: transparent !important;
    color: #666;
    height: 50px;
    padding-top: 0;
    border: none;
    padding: 0;
    margin: 0;
}

.custom-date-picker .date-btn:hover input{
    color: white;
}

.custom-date-picker .date-btn input:focus-visible{
    outline: none;
    border: none;
}

.custom-date-picker .date-btn{
    padding: 0 20px !important;
}

.custom-date-picker search-container{
    display: inline-block;
    margin: 0;
    min-width: 30%;
}

.custom-date-picker .search-icon{
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
    width: 50px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}

.custom-date-picker .btn {
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}

.custom-date-picker .inner-text{
    margin-right: 5px;
}

/* 
.default-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 10px;
} */


.configurations .stamp-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.configurations .custom-switch.custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 1.5rem;
}

.configurations .custom-switch.custom-switch .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.configurations .custom-switch.custom-switch .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.configurations .custom-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(calc(1.5rem - 0.25rem));
            transform: translateX(calc(1.5rem - 0.25rem));
}

.configurations .stamp-container .text-switch h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.configurations .stamp-container .text-switch p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
}

.configurations .Button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: 10px;
}

.configurations .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.configurations .row-selection a{
    margin-right: 10px;
}

.configurations .row-selection a:hover{
    color: black;
}

.configurations .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.configurations .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.configurations .input{
    align-items: center;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.configurations .alter-table {
    display: flex;
    align-items:center;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
    justify-content:space-between;
}

.configurations .alter-table .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
}

.configurations .alter-table .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0 0 0 10px;
}

.configurations .alter-table  .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.configurations .alter-table .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -10px;
}

.configurations .alter-table .options {
    display: flex;
    align-items: center;
}

.configurations .alter-table .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}

.configurations .alter-table  .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}

.configurations .table-container {
    display: flex;
    width: 97%;
    height: 70vh;
    overflow: scroll;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.configurations .table-container thead,.configurations .table-container th{
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2 !important;
    font-size: 1em !important;
    font-weight: 500 !important;
}

.configurations .table-container tbody td{
    z-index: 1;
    background: rgb(255, 255, 255)!important;
    text-align: center;
}

.configurations .api-container .api{
height: 40px;
padding: 10px;
font-size: 20px;
background: #FAFAFA;
opacity: 1;
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
-webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
border-radius: 20px;
}

.configurations .ButtonTest{
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: 10px;
    justify-content: center;
    max-width: 150px;
}

.configurations .api-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 97%;
}

.configurations .api-container label{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}
.configurations .api-container form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.configurations .api-container .form-group{
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.configurations .api-container .api-group{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.configurations .editor-container{
    display: flex;
    width: 97%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.configurations .tox-tinymce{
    width: 95% !important;
    margin-top: 30px;
    margin-bottom: 30px;
}

.configurations .editor-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tox .tox-notification--warn, .tox .tox-notification--warning{
    display: none !important;
}
*{
    font-family: "Mulish" !important;
}

.companies-suspension{
    width: 97%;
    height: 96%;
}
.companies-suspension h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.companies-suspension .search-container-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.companies-suspension .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}

.companies-suspension .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
    width: 100%;
}
.companies-suspension .search-container input{
    border: none;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    width: 100%;
}

.companies-suspension input{
    background-color: #eceef1;
    border-radius: 10px;
}


.companies-suspension .options-container{
    margin-top: 20px;
    margin-bottom: 20px;
}


.companies-suspension .buttons-general-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.companies-suspension .buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companies-suspension .buttons-container button{
    display: flex !important;
    border: none;
    border-radius: 30px;
    outline-style: none;
    padding: 0px 0px 0px 10px;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    width: 200px;
    margin-top: 10px;
}

.companies-suspension .buttons-container .filter-button{
    padding: 0px 0px 0px 10px;
    height: 50px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}


.buttons-container button:hover{
    outline-style: none;
}


.companies-suspension .yellow-button{
    background-color: rgb(227, 204, 0);
}

.companies-suspension .grey-button{
    background-color: rgb(129,129,129) !important;;
    background: rgb(129,129,129) !important;;
}

.companies-suspension .grey-button:hover{
    background-color: rgb(142, 142, 142) !important;
    background: rgb(129,129,129) !important;
}

.companies-suspension .green-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
} 

.companies-suspension{
    display: flex;
    flex-direction: column;
    height: 89vh;
}

.companies-suspension .companies-table-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    overflow: scroll;
}


/* asd */

.companies-suspension .companies-table-container-list .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7 !important;
    overflow-y: auto !important;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5) !important;
}


.companies-suspension .companies-table-container-list .table-container {
    overflow-x: scroll !important;
    display: inline-block;
    width: 95%;
    margin-top: 0px;
}


.companies-suspension .companies-table-container-list .table{
    width: 100% !important;
    border: none;
    margin: 0;
    border-collapse:separate;
    border-spacing:0 10px;
}

.companies-suspension .companies-table-container-list .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: bold;
}

.companies-suspension .companies-table-container-list .table tbody tr{
    background: rgba(255, 255, 255, 0.6)!important;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.10);
    border-radius: 5px; 
}


.companies-suspension .companies-table-container-list .table tbody tr td:first-child { border-top-left-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:last-child { border-top-right-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.companies-suspension .companies-table-container-list .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.companies-suspension .companies-table-container-list .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.companies-suspension .companies-table-container-list .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.companies-suspension .pagination-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px !important;
}


.companies-suspension .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.companies-suspension .custom-switch .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.companies-suspension .custom-switch  .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
    cursor: pointer;
}

.companies-suspension .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(calc(1.5rem - 0.25rem));
            transform: translateX(calc(1.5rem - 0.25rem));
}

.companies-suspension .green-switch .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color:  rgba(7,210,204,1);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.companies-suspension .orange-switch .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color: rgb(234, 201, 35);
    background: linear-gradient(90deg, rgb(234, 201, 35)  0%,  rgb(210, 146, 7) 100%);
}

.companies-suspension .custom-switch .custom-control-label::before {
    color: #fff;
    border-color:  rgb(210, 7, 7);
    background: linear-gradient(90deg, rgba(210, 7, 7, 0.921) 0%, rgb(255, 0, 0) 100%);
    cursor: pointer;
}

.companies-suspension .custom-switch .custom-control-label::after {
    background-color: #fff;
    cursor: pointer;
}

.companies-suspension .center-content{
    display: flex !important;
    justify-content: center !important;
}

.history-modal-table .center-content{
    display: flex !important;
    justify-content: center !important;
}


.companies-suspension .history-icon{
    width: 25px !important;
    margin-right: 10px;
}

.companies-suspension .checkbox-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.companies-suspension .form-check-custom{
    margin-right: 10px;
}

.companies-suspension  input[type=checkbox]:checked{
    -webkit-filter: saturate(0.1);
            filter: saturate(0.1);
}

.companies-suspension .file-excel-icon{
    color: #fff;
    margin-right: 8px;
}

.companies-suspension .disabled-white-selection::selection{
    color: #00000099 !important;
    background: #0000001c;
}

.history-modal .modal-content{
    background: rgba(255,255,255,0.9) !important;
}

.history-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.history-modal p{
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}


.history-modal .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.history-modal .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 15px;
}

.history-modal td{
    cursor: pointer;
}


.modal-width-companies{
    width: 85% !important;
    max-width: 85% !important;
}

.modal-height-companies{
    height: 50% !important;
    max-height: 50% !important;
}

.history-modal-table{
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
}

.history-modal .close-button-container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.companies-suspension .amount-max-width{
    max-width: 195px;
}

.companies-suspension .pointer{
    cursor: pointer;
}

.companies-suspension .dollar-icon-size, .save-icon-size{
    height: 28px !important;
    width: 28px !important;
}

.companies-suspension .trash-icon-size{
    height: 25px !important;
    width: 25px !important;
    margin-top: 2px;
    margin-left: 4px;
    margin-right: 5px;
}

.companies-suspension .rw-widget-picker {
    border: none;
    background-color: transparent;
    border-radius: 10px;
    height: 60px;
    margin-left: 2px !important;
}

.companies-suspension .rw-widget-picker .rw-input{
    border: 1px solid #ced4da;
}

.companies-suspension .rw-widget-picker>.rw-select {
    border: 1px solid #ced4da;
    border-radius: 0px 10px 10px 0px !important;
}

.companies-suspension
.rw-widget-container.rw-state-focus,
.rw-state-focus>.rw-widget-container,
.rw-widget-container .rw-state-focus:hover,
.rw-state-focus>.rw-widget-container:hover{
    background-color: transparent !important;
    border-color: none !important;
    box-shadow: none !important;
}

.companies-suspension .spinner-custom{
    height: 20px !important;
    width: 20px !important;
    margin-right: 5px;
}

.history-modal-table .header-table {
    color: black !important;
}

.history-modal-table .font-content{
    font-weight: 600;
    color:  #666 !important;
}

.react-tooltip-lite {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .tooltip-arrow {
    border-top-color: #333;
  }
  
  .tooltip.visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .tooltip.transition {
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    transition-duration: 0.2s;
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  
  
  .my-date{
    margin-left: 5px;
    padding: 0 20px!important;
    background: hsla(0,0%,100%,.5)!important;
    border: none;
    border-radius: 30px;
    color: #666 !important;
    display: flex;
    width: 250px !important;
    align-items: right;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0 15px 0 0;
  }

  .react-datepicker-wrapper input {
    background: transparent!important;
    color: #666;
    height: 50px;
    padding-top: 0;
}



.react-datepicker-wrapper input {
    background: transparent!important;
    color: #666 !important;
    height: 50px;
    border: none;
    padding-top: 0;
}

.my-btn {
    margin-right: 10px;
    margin-left: 60px;
}
