.prefecture{
    width: 100%;
    min-height: 85vh !important;
}
.prefecture .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}

.prefecture .search{
    display: flex;
    flex-direction: row;
}

.prefecture .search-icon:hover{
    color: darkgreen;
}
.prefecture .search-container{
    width: 350px !important;
    margin: 0;
    float: left !important;
}
.prefecture .search-container input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0 15 0 0;
}
.prefecture .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}
.prefecture h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
    display: inline-block;
}

.prefecture .company{
    font-size: 18px;
    color: #1B3769;
}

.prefecture h5{
    display: inline-block;
    font-weight: lighter;
    margin: 5px 0 0 15px;
    color: darkblue;
    font-size: 1.15em;
    font-weight: 400;
}

.prefecture .title-search-bar{
    display: flex;
    flex-direction: row;
}

.prefecture .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.prefecture .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.prefecture .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.prefecture .table-container {
    display: flex;
    width: 95%;
}

.table-scroll {
    overflow-x: scroll !important;
}

.header-height {
    height: 120px;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.prefecture .table{
    width: 100%;
    display: inline-block !important;
    border: none;
    margin: 0;
}

.prefecture .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: 400;
}

.prefecture .table tbody tr{
    background: rgba(255, 255, 255, 0.7)!important;
}

.prefecture .table tbody tr td:first-child { border-top-left-radius: 5px; }
.prefecture .table tbody tr td:last-child { border-top-right-radius: 5px; }
.prefecture .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.prefecture .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.prefecture .no-spacer{
    height: 70px !important;
}
.prefecture .spacer{
    background: transparent !important;
    height: 10px !important;
}
.prefecture .table thead tr th{
    border: none;
}

.prefecture .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.prefecture .table tbody tr td svg{
    font-size: 12px;
}

.prefecture .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.prefecture .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.prefecture .filters{
    min-width: 25%;
    max-width: 50%;
}

.prefecture .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0.5rem 0;
}

.prefecture .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0px 15px 0px 0px;
}
.prefecture .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}
.prefecture .options .btn svg{
    margin-right: 10px;
}
.prefecture .options > *{
    margin: 0 15px 0 0;
}

.prefecture .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
}

.prefecture .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0 0 0 10px;
}

.prefecture .options label{
    margin: 2.5px 0 0 0;
}

.prefecture .options .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.prefecture .options .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -15px;
}

.change-period .modal-dialog .modal-content{
    background-color: transparent;
    border: none;
}

.change-period .modal-dialog .modal-content .modal-body{
    height: 100%;
    width: 65%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
}

.change-period .modal-dialog .modal-content .modal-body .content{
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.change-period .modal-dialog .modal-content .modal-body .content h5{
    font-weight: bolder;
}

.change-period .modal-dialog .modal-content .modal-body .content p{
    font-weight: bolder;
    padding: 0;
    margin: 1rem 0 1rem 0;
}

.change-period .modal-dialog .modal-content .modal-body .content .default-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 10px;
}

.change-period .modal-dialog .modal-content .modal-body .content form select{
    border-radius: 20px;
    font-size: 15px;
    background-color: #ECEEF1;
    cursor: pointer;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 0.5);
    border: none;
}

.change-period .modal-dialog .modal-content .modal-body .content form select option{
    background-color: #ECEEF1;
}

.prefecture .alter-table{
    display: flex !important;
    align-items: center;
}

.prefecture .columns{
    flex-direction:column;
}

.prefecture .columns .margin{
    margin-top: 5px;
}

.header-container-prefacture{
    width: 95%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.search-container-prefacture{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 15px;
}

.authorize-button-text{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none !important;
    border-radius: 30px !important;
    outline-style: none !important;
    font-weight: 600 !important;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    height: 50px;
    margin: 0px 15px 0px 0px;
    min-width: 100px;
    display: flex !important;
}
.prefecture .search{
    margin: 0 !important;
    float: left;
}

.prefecture .search input{
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    float: left;
}

.prefecture .search-and-authorize{
    display: flex;
    flex-direction: row;
}

.prefecture .authorize-button{
    display: flex !important;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    padding: 0 30px 0 10px;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 0px 15px 0 0px;
}


.prefecture .authorize-button-period{
    display: inline-block !important;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 7px 20px 0 10px;
    padding: 0 20px;
}


.prefecture .authorize-button-disabled{
    display: inline-block !important;
    background: rgba(255, 255, 255, 0.5) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 30px;
    outline-style: none;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    margin: 0px 20px 0 10px;
    color: #666 !important;
    padding: 0 20px;
}

.prefecture .authorize-button-disabled:hover{
    color: white !important;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}


.prefecture .authorize-button svg{
    font-size: 1.4em !important;
    margin: 0 10px;
}

.prefecture .costs-table{
    display: inline-block !important;
    width: auto;
    margin-bottom: 20px;
    margin-right: 50px;
    border-radius: 10px;
}

.prefecture .search-and-date{
    margin-top: 10px;
}
.prefecture .costs-table td{
    text-align: left !important;
}

.prefecture .search-and-date .search-bar-container{
    margin-left: auto;
    display: flex;
}

.prefecture .search-and-date .search-bar-container .search-bar{
    margin-left: auto;
}

.prefecture .search-and-date .period-container{
    align-self: start;
}
.interns-list-options {
    float: left;
    left: 68%;
}

.prefecture .interns-list-options{
    margin-right: 50px !important;
}

.prefecture .interns-list-options .selected{
    color: white !important;
}

.prefacture .paginate .interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.prefacture .paginate .interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.paginate {
    display: flex;
}

.prefacture-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.prefacture-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.prefacture-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.prefacture-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.prefacture-modals small{
    color: black;
    margin: auto;
    text-align: center;
}

.prefacture-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.prefacture-modals .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}


.prefacture-modals .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}


.prefacture-modals .btn-danger:hover, 
.prefacture-modals .btn-danger:focus,
.prefacture-modals .btn-danger:active{
    background: white !important;
    border: 1px solid #666 !important;
    color: #666 !important;
    box-shadow: none;
}

.prefacture-modals .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.prefacture-modals .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.prefacture-modals label {
    font-size: 12px;
    margin: 0;
}

.prefacture-modals select {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.records, .records > * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}
.records .modal-dialog {
    width: 30% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width:none !important;
    position: absolute;
    right: 0px;
}

.records .modal-dialog .modal-content{
    height: 0% !important;
    min-height: 100% !important;
    border-radius: 0 !important;
    background-color: white !important;
    overflow-y: auto;
}

.records .records-title{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d7d7d7;
}

.records .records-title svg{
    margin: 5px 5px 0 0;
    font-size: 20px;
}

.records .records-title p{
    font-size: 20px;
    font-weight: bold;
}

.records .record {
    margin: 10px 0 0 0;
    border-bottom: 1px solid #d7d7d7;
}

.records .record .message{
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 8px 0;
}

.records .record .author{
    color: #1858CA;
    font-size: 13px;
    margin: 0 0 0 0;
}

.records .record .date{
    color: gray;
    font-size: 13px;
    margin: 0 0 8px 0;
}

.set-text-center{
    text-align: center !important;
}
.loading-screen-container .modal-content{
    width: 120px;
    margin: 0 auto;
    border: none;
    background-color: transparent;
}

.cssload-thecube {
	width: 73px;
	height: 73px;
	margin: 0 auto;
	margin-top: 49px;
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
		-o-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(7,211,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
	animation: cssload-fold-thecube 2.76s infinite linear both;
		-o-animation: cssload-fold-thecube 2.76s infinite linear both;
		-ms-animation: cssload-fold-thecube 2.76s infinite linear both;
		-webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
		-moz-animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
		-o-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		-webkit-transform-origin: 100% 100%;
		-moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
		-o-transform: scale(1.1) rotateZ(90deg);
		-ms-transform: scale(1.1) rotateZ(90deg);
		-webkit-transform: scale(1.1) rotateZ(90deg);
		-moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
		-o-transform: scale(1.1) rotateZ(180deg);
		-ms-transform: scale(1.1) rotateZ(180deg);
		-webkit-transform: scale(1.1) rotateZ(180deg);
		-moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
		-o-transform: scale(1.1) rotateZ(270deg);
		-ms-transform: scale(1.1) rotateZ(270deg);
		-webkit-transform: scale(1.1) rotateZ(270deg);
		-moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
		-o-animation-delay: 0.35s;
		-ms-animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
		-moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
		-o-animation-delay: 0.69s;
		-ms-animation-delay: 0.69s;
		-webkit-animation-delay: 0.69s;
		-moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
		-o-animation-delay: 1.04s;
		-ms-animation-delay: 1.04s;
		-webkit-animation-delay: 1.04s;
		-moz-animation-delay: 1.04s;
}
.purchase-order{
    margin-bottom: 1rem;
    border: none;
    box-shadow: none;
}

.purchase-order .input-group {
    border-radius: 15px;
    overflow: auto !important;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
    color: #12121307;
}

.input-icon-text{
    border-radius: 15px 0px 0px 15px !important;
    border-right: none !important;
}
.input-icon{
    border-radius: 0px 15px 15px 0px !important;
    border-left: none !important;
    background: rgba(250, 250, 250, 0.75) !important; 
}
.text-column{
    margin: 1rem 1.5rem 0rem 1.5rem;
}
.purchase-order-title{
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    padding-left: 1rem;
}

.purchase-padding{
    padding: 0.5rem !important;
}
.float-left{
    float: left !important;
}

.contpaq-margin {
    margin: -10px 10px -20px 10px;
}

.contpaq-margin .margin-buttom {
    margin-right: 5px !important;
}

.new-contpaq-modal .modal-content{
    background-color: rgba(250, 250, 250, 0.9);
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
    border-radius: 20px;
    color: #535A63 !important;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: fit-content;
}

.new-contpaq-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 20px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 45px 8px 45px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-contpaq-modal .btn-danger{
    border: 1px solid #666 !important;
    color: #666;
    background: transparent;
    border: none;
    border-radius: 20px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 45px 8px 45px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-contpaq-modal .btn-danger:hover,
.new-contpaq-modal .btn-danger:focus,
.new-contpaq-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.new-contpaq-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.title-video-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.video-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.video-text{
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #1B3769;
    padding-left: 10px;
    font-weight: 400;
}

.compaq-buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 15px;
}

.video-player{
    width: 100% !important;
    height: 100% !important; 
} 

 .modal-height {
    height: 55% !important;
    max-height: 55% !important;
}

.modal-width {
    width: 55% !important;
    max-width: 55% !important;
}

.purchase-padding .manually-invoice-row{
    width: 100%;
    margin-left: 0;
}

.manually-invoice-row .custom-label{
    width: 140px;
    margin-left: 0;
}

.manually-invoice-row .custom-group{
    border-radius: 10px !important;
}

.manually-invoice-row .custom-input{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px !important;
    width: 320px !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.manually-invoice-row .money-radius{
    border-radius: 10px 0px 0px 10px;
}

.manually-invoice-row .money-input{
    border-radius: 0px 10px 10px 0px !important; 
}

.manually-invoice-row .custom-form{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 70px 0px 70px;
}

.manually-invoice-row .money-input:focus{
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0.5px 0px 1px 0px rgba(209, 209, 209, 0.5) !important;
}
.manually-invoice-row .custom-input:focus{
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 0px 1px 0px rgba(209, 209, 209, 0.5) !important;
}

@keyframes cssload-fold-thecube {
	0%, 10% {
		transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

@-o-keyframes cssload-fold-thecube {
	0%, 10% {
		-o-transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-o-transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-o-transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

@-ms-keyframes cssload-fold-thecube {
	0%, 10% {
		-ms-transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-ms-transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-ms-transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

@-webkit-keyframes cssload-fold-thecube {
	0%, 10% {
		-webkit-transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-webkit-transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-webkit-transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}

@-moz-keyframes cssload-fold-thecube {
	0%, 10% {
		-moz-transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-moz-transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-moz-transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}