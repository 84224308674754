.layout {
    width: inherit;
    height: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}

.layout .navbar .logoCaintra, .layout .sidebar .logoCaintra{
    height: auto;
}

.layout .navbar{
    border-bottom: rgba(215, 215, 215, 0.5) solid 2px;
}

.layout .navbar .nav-link{
    color: #1B3769;
}

.layout .navbar .mobile-options{
    display: none;
}

.layout .navbar .navbar-brand{
    padding: 15px 0px 10px 20px;
}

.divider{
    border: 1px solid #535A63 !important;
    border-radius: 5px !important;
    margin-left: auto;
    margin-right: auto;
}

.layout .content {
    width: 98%;
    height: 100%;
    margin-top: 10px;
    margin-left: 10px;
    box-sizing: border-box;
}
.layout .page {
    float: right;
    padding: 40px 0 0 30px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.5);
    transition: all .3s;
    padding-bottom: 20px;
    min-height: 95vh;
    margin-left: 1%;
}

.layout .sidebar-container{
    display: inline-block;
    transition: all .3s;
    min-height: 95vh !important;
}

.layout .sidebar {
    border-radius: 15px;
    padding: 15px 5px;
    background-color: rgba(250, 250, 250, 0.65);
    height: 100%;
    box-shadow: 2px 0px 10px 0px rgb(0, 0, 0, 0.050);
    min-height: 95vh !important;
}

.layout .sidebar ul::-webkit-scrollbar{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.layout .sidebar ul::-webkit-scrollbar{
    display: none;
}

.layout .sidebar .profile{
    display: flex;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    border-radius: 25px;
    margin: 25px 0 25px 0;
    padding: 5px;
}

.layout .sidebar .profile .img-container{
    margin-right: 8px;
    padding: 5px;
    border-radius: 50%;
    overflow: hidden;
}
.layout .sidebar .profile .img-container img{
    border-radius: 50%;
}
.layout .sidebar .profile .cont{
    display: inline-block;
}

.layout .sidebar .profile span{
    display: block;
    line-height: 23px;
}

.layout .sidebar .profile .name{
    font-size: 1.1em;
    color: black;
    font-weight: 600;
}

.layout .sidebar .profile .role{
    font-size: .8em;
    color: rgb(132, 134, 132);
    font-weight: 500;
}

.layout .sidebar .profile .company{
    font-size: 1em;
    color: rgb(47, 150, 219);
    font-weight: 500;
}

.layout .sidebar .items{ 
    list-style-type: none;
}

.layout .sidebar li{
    margin-top: 6%;
    text-align: center;
}

.layout .sidebar .items svg{
    margin-right: 10px;
    margin-top: -4px;
}

.layout .sidebar .items a{
    display: inline-flex;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 13px;
    color: #454545;
    font-weight: 400;
    align-items: center;
    justify-content: left;
    padding: 0 15px;
    height: 45px;
    width: 100%;
    text-align: center;
}


.layout .sidebar .items a.mini{
    display: inline-flex;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 13px;
    color: #454545;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 45px;
    width: 45px;
    text-align: center;
}
.layout .sidebar .items a:hover,
.layout .sidebar .items a.mini:hover
{
    text-decoration: none !important;
}
.layout .sidebar .items .selected{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}
.layout .sidebar .items .selected img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
}

.layout .sidebar #toggle-sidebar{
    font-size: 32px;
    position: sticky;
    bottom: 15px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 15px !important;
}

.layout .sidebar #toggle-sidebar svg{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    transition: all 0.5s;
    border-radius: 50%;
    padding: 5px;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 5px #666;
}
.layout .sidebar #toggle-sidebar svg.extended{
    transform: rotateY(180deg);
}

.dropdown-decoration{
    text-decoration: none !important;
}
.dropdown-decoration:hover{
    text-decoration: none !important;
}

.layout .navbar .see-as-button{
    background: none;
    color: #1B3769;
    border: none;
}

.layout .navbar .show > .see-as-button{
    background: none;
    color: #1B3769;
    border:none;
}

.layout .navbar .see-as-button:active:focus{
    background: none;
    color: #1B3769;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none;
    outline: none;
}

.layout .navbar .dropdown-menu, .show > *{
    z-index: 999;
}

.layout .navbar .dropdown-menu a{
    color: #1B3769;
    font-weight: bold;
}

.layout .navbar .btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
 }

.nav-modal{
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.border-radius-2 div{
    border-radius: 20px !important;
}

.nav-modal .form-companies{
    border-radius: 20px !important; 
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
}

.modal-content{
    width: 920px !important;
}

.unsuscribe-intern-modal .modal-header{
    text-align: left;
    border: none;
    color: black;
    padding-top: 25px;
}

.layout .sidebar .configuration-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.sidebar-display{
    display: flex;
    flex-direction: column;
}

.modal-height-patch{
    height: 90vh !important;
    padding: 30px !important;
}

.version-notes{
    height: 95% !important;
    padding: 10px 10px 10px 18px;

    overflow: auto;
}

.modal-height-patch .buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.modal-height-patch .buttons-container .checkbox-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-right: 20px;
}
.modal-height-patch .buttons-container .checkbox-container p{
    margin-left: 10px; 
    margin-top: 0;
    padding-top: 0px;
    font-weight: bold; 
    font-size: 14px;
}

.modal-height-patch .buttons-container .checkbox-container .checkbox-class{
    margin: 0 !important;
}

.modal-height-patch .buttons-container .default-button{
    margin: 0 !important;
}



@media only screen and (max-width: 991px) {
    .layout .navbar .mobile-options{
        margin: 5% 0 5% 50%;
        display: block;
    }

    .layout .navbar .mobile-options a{
        text-align: end;
        
    }

    .layout .navbar .desktop-options{
        display: none;
    }

    .layout .navbar .navbar-brand{
        padding: 5px 0px 0px 0px;
    }

    .layout .navbar svg{
        margin-right: 5px;
        width: 25px;
    }
    
    .layout .navbar a{
        font-size: 18px;
        padding: 0 0 5px 0;
    }

    .layout .sidebar-container {
        display: none;
    }
}

@media only screen and (max-height: 2000px)  {
    
    .layout .sidebar-container{
        max-height: 90vh !important;
    }
    
    .layout .sidebar {
        max-height: 90vh !important;
    }
}

.version{
    margin-top: 2px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.version2{
    margin-top: -24px !important;
    margin-left: 0px !important;
    color :rgb(178, 179, 178) !important;
    font-weight: bold !important;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
    
}  

.flex-child:first-child {
    margin-right: 20px;
} 

.myVersion {
    margin-top: -22px;
    color: rgb(132, 134, 132);
    font-weight: 500;
}

.modal-patch-notes-container{
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    
}

@media only screen and (max-width: 1189px){
    .modal-patch-notes-container img{
        width: 90% !important;
    }
}