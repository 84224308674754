.uploads {
    width: 100%;
    min-height: 85vh !important;
    font-family: "Mulish", 'Verdana', 'Tahoma';
}

.uploads .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.uploads .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
    margin-top: 5px;
}

.uploads .alter-table {
    display: flex;
    align-items:center;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
    justify-content:space-between;
}

.uploads .alter-table .Button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20;
    font-weight: 600;
    font-size: 18px;
    border-radius: 25px;
    box-shadow: 0 0 5px #aaa;
    margin-right: 15px;
    margin-top: -20px;
}

.uploads .alter-table .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.uploads .row-selection .section{
    margin-right: 10px;
    background: #D9D9D9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}

.uploads .row-selection a:hover{
    text-decoration: none;
}

.uploads .row-selection .selected{
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    opacity: 0.8;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
}

.uploads .row-selection .color-white{
    font-weight: bold;
    color: white;
}

.uploads .row-selection .middle{
    background: #D9D9D9;
    margin-right: -15px;
    margin-left: -25px;
    padding-left: 20px;
}

.uploads .row-selection p{
    color: #535A63;
    font-weight: lighter;
    font-size: 20px;
}

.uploads .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.uploads .table-container {
    display: flex;
    width: 97%;
}

.uploads .table-container .input-text{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    width:fit-content;
}

.table-container::-webkit-scrollbar {
    height: 15px;
}

.header-height {
    height: 120px;
}

.uploads .spacer{
    background: transparent !important;
    height: 10px !important;
}

.table-scroll {
    overflow-x: scroll !important;
}

.uploads .table{
    width: 100%;
    display: inline-block !important;
    border: none;
    margin: 0;
}

.uploads .table thead,.uploads .table th{
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: sticky;
    top: 0;
    z-index: 2 !important;
    font-size: 1em !important;
    font-weight: 500 !important;
}

.uploads .table thead th:nth-child(1){
    position: sticky;
    left: 0;
    z-index: 3 !important;
}

.uploads .table thead th:nth-child(2){
    position: sticky;
    left: 37px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(3){
    position: sticky;
    left: 260px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(4){
    position: sticky;
    left: 448px;
    z-index: 3 !important;
}

.uploads .create thead th:nth-child(5){
    position: sticky;
    left: 571px;
    z-index: 3 !important;
}

.uploads .table tbody td:nth-child(1){
    position: sticky;
    left: 0;
    z-index: 1 !important;
}

.uploads .table tbody td:nth-child(2){
    position: sticky;
    left: 37px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(3){
    position: sticky;
    left: 260px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(4){
    position: sticky;
    left: 448px;
    z-index: 1 !important;
}

.uploads .create tbody td:nth-child(5){
    position: sticky;
    left: 571px;
    z-index: 0;
    z-index: 1 !important;
}

.uploads .table tbody td{
    z-index: 1;
    background: rgb(255, 255, 255)!important;
}

.uploads .table tbody tr td:first-child { border-top-left-radius: 5px; }
.uploads .table tbody tr td:last-child { border-top-right-radius: 5px; }
.uploads .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.uploads .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.uploads .table thead tr th{
    border: none;
}

.uploads .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.uploads .table thead tr .long-text{
    min-width: 82px;
}

.uploads .table thead tr .longer-text{
    min-width: 124px;
}

.uploads .table tbody tr td svg{
    font-size: 12px;
}

.uploads .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.uploads .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}


.prefacture-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.prefacture-modals input{
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
}
.uploads .center-container{
    display: flex;
    align-items: center;
}

.uploads .center-container .spinner-border{
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-top: 5px !important;
}