.privacy{
    width: inherit;
    height: inherit;
}

.privacy .background-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.privacy .form-card{
    background-color: rgba(255, 255, 255, 0.96);
    padding: 20px;
    border-radius: 5px;
    height: 90vh;
    overflow-y: auto;
}

.privacy form{
    width: 70%;
    margin: auto;
}

.privacy span{
    margin-left: 25%;
    margin-right: 5%;
    text-align: right;
    font-size: 16px;
}

.privacy .container-img{
    margin-top: 0%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.privacy select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.privacy .container{
    margin-left: 3%;
    background-color: #fefefe;
    font-size: 16px;
    border-radius: 5px 5px 5px 5px;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
    height: 500px;
    width: 50%;
}

.privacy .icons{
    margin-left: 5%;
}


.privacy small{
    margin: auto;
    line-height: 17.57px;
}

.privacy .InternsLogo {
    width: 150px;
    height: auto;
}

.privacy .logoCaintra {
    width: 100px;
    height: auto;
}

.privacy .text-container{
    margin-top: 30px;
}

.privacy .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.privacy .logos{
    margin-left: 3%;
    display: flex;
    
}

.privacy input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.privacy .form-label{
    color: black;
    font-size: 15px;
}

.privacy .row-logo{
    margin: 25px 5px 5px 5px;
}

.privacy .row-contact{
    text-align: center;
    margin-top: 5%;
}

.privacy .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.privacy .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    padding-top: 40%;
}

.privacy .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.privacy .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.privacy .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.privacy .logo{
    padding-top: 2%;
    height: 100px;
    margin-left: 3%;
}

.privacy .footer-content{
    display: flex;
    
}

.privacy .text-foot{
    display: flex;
    align-items: flex-end;
}
                       

.privacy .files svg{
    margin: 3px 3px 0 0;
}


.privacy .files small{
    margin: 0;
}

.privacy .form-file-label:hover{
    cursor: pointer;
}

.privacy .form-control-file{
    display: none;
}

.privacy input[type="checkbox"]{
    height: auto;
}

.privacy .success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}

@media only screen and (min-width: 1920px) {
    .privacy .row-selection{
        margin-bottom: 10%;
    }    

    .privacy input{
        height: 50px;
    }

    .privacy .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .privacy .row-contact{
        padding-top: 30px;
    }
    .privacy span{
        margin-left: 25%;
        margin-right: 5%;
        text-align: right;
        font-size: 20px;
    }
    .privacy .container{
        margin-left: 3%;
        background-color: #fefefe;
        font-size: 20px;
        border-radius: 5px 5px 5px 5px;
        padding-left: 20px;
        padding-right: 40px;
        box-shadow: 5px 5px 5px rgb(197, 197, 197);
        height: 500px;
        width: 50%;
    }
    .privacy .container-img{
        margin-top: 3%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .privacy .container-img .privacy-img{
        margin-right: 15%;
    }
}


@media only screen and (min-height: 1321px) {
    .privacy .container{
        height: 900px;
        width: 1200px;
        font-size: 35px;
    }
    .privacy .privacy-img{
        height: 900px;
    }
}

@media only screen and (max-width: 767px) {
    .privacy .container{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .privacy .row-selection a{
        font-size: 20px;
    }
}