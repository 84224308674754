

.logistics .alter-table .add-university,
.logistics .alter-table .reload-table{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    width: 50px;
    font-weight: 600;
    font-size: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px #888;
    margin-right: 20px;
    cursor: pointer;
}

.logistics .alter-table .search-icon{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50% 0 0 50%;
}

.logistics .alter-table .search-container{ margin: 0; }
.logistics .alter-table .search-container input{
    height: 50px;
    border-radius: 0 25px 25px 0;
    border: none;
    background:rgba(255, 255, 255, 0.6);
    width: 270px;
    margin-right: 20px;
 }
.logistics .alter-table .search-container input:focus{
    box-shadow: none;
}

.logistics .alter-table .Button{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 15px;
    border-radius: 25px;
    height: 50px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
}
.logistics .alter-table .Button svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}


.logistics .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;
    align-items: center;
}
.logistics .options .btn{
    background: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: 600;
    margin-right: 15px;
}


.logistics .options .btn:hover{
    color: white;
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
}
.logistics .options .btn svg{
    margin-right: 10px;
}
.logistics .options > *{
    margin: 0 15px 0 0;
}

.logistics .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.logistics .options .filters-menu-companies{
    min-width: 200px !important;
}

.logistics .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}

.logistics .options label{
    margin: 2.5px 0 0 0;
}

.logistics .options .filters-menu input[type=checkbox]{
    width: 11px;
    margin-left: -15px;
    cursor: pointer;
}

.logistics .options .filters-menu input[type=checkbox]:checked{
    background: black;
    background-color: black;
    background-image: none;
    background-position: -15px;
}

.logistics .actions-icon {
    width: 20px;
}


.alta {
    color: green;
}

.confirmado {
    color: green;
}
.terminado {
    color: blue;
}
.pausado {
    color: red;
}
.retenido {
    color: #666;
}
.enviado {
    color: black;
}
.in-process {
    color: rgba(198, 198, 73, 0.927);
}
.empresa {
    color: #666;
}
.asesora {
    color: red;
}
.autoservicio {
    color: black;
}
.no-origin{
    color: rgba(198, 198, 73, 0.927);;
}

.logistics .logistic-table-scroll {
    max-width: 97%;
}

.logistics .logistic-table-scroll table {
    overflow: auto;
    max-height: 70vh !important;
}

 .logistics .logistic-table-scroll table thead {
    border: none !important;
    background: rgba(6, 209, 205, 1) !important;
    color: white !important;
    position: sticky;
    top: 0;
    z-index: 2 !important;
}


.logistics .logistic-table-scroll table tbody {
    background: rgba(255, 255, 255, 255) !important;
}

.logistics-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.logistics-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.logistics-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.logistics-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.logistics-modals .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logistics-modals .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logistics-modals .btn-danger:hover, 
.logistics-modals .btn-danger:focus,
.logistics-modals .btn-danger:active{
    background: white !important;
    border: 1px solid #666 !important;
    color: #666 !important;
    box-shadow: none;
}

.logistics-modals td{
    cursor: pointer;
}

.logistics td{
    min-height: 40px !important;
}

.logistics .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
}

.logistics .table tbody tr{
    height: auto !important;
}

.logistics-search-container{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.input-logistics{
    background-color: #eceef1;
    border: none;
    border-radius: 20px;
    width: 15rem;
}

.input-logistics:focus{
    outline: none !important;
    box-shadow: none;
}

.upload-accounts-table{
    max-height: 220px !important; 
    overflow-x: scroll !important;
}

.table-status{
    margin-right: 30px !important;
    padding-left: -2px !important;
}

.divider-status-table{
    border-radius: 0px 0px 0px 0px !important;
}

.logistics .alter-table .Button-disabled{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 15px;
    border-radius: 25px;
    height: 50px;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5) !important;
    color: #666 !important;
}

.snipper-class{
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
}
.logistics .force-change-background {
    background: rgba(6, 209, 205, 1) !important;
    z-index: 4;
}
.logistics .companies-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.logistics .logistics-modals .modal-content{
    background: rgba(255,255,255,0.8);
}

.logistics .logistics-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.logistics .logistics-modals p{
    font-weight: bold;
    font-size: large;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}

.logistics .logistics-modals small{
    color: black;
    margin: auto;
    text-align: center;
}


.logistics .logistics-modals .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.logistics-modals .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.logistics-modals label {
    font-size: 12px;
    margin: 0;
}

.logistics .logistics-modals select {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}
.logistic-accounts-table{
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
}

.modal-height {
    height: 30% !important;
    max-height: 30% !important;
}

.modal-width {
    width: 80% !important;
    max-width: 80% !important;
}