.reports .title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    color: black;
    margin-bottom: 20px;
}

.reports h5{
    font-weight: lighter;
    margin: 5px 0 0 0;
    color: #00AEEF;
}
.reports .search-icon{
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
    width: 50px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}
.reports .search-container{
    display: inline-block;
    margin: 0;
    min-width: 30%;
}
.reports .search-container input{
    height: 50px;
    background: rgba(255,255,255,0.6);
    border-radius: 0 25px 25px 0 !important;
    border: none;
}
.reports .search-container input:focus{
    box-shadow: none;
}
.reports .options{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.reports .options .react-datepicker-wrapper input{
    background: transparent !important;
    color: #666;
    height: 50px;
    padding-top: 0;
}
.reports .options .date-btn{
    padding: 0 20px !important;
}
.reports .options .date-btn:hover input{
    color: white;
}
.reports .options .date-btn input:focus-visible{
    outline: none;
    border: none;
}

.reports .title-search-bar{
    display: flex;
    flex-direction: row;
}

.reports .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
    height: 2.2rem;
}

.reports .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.reports .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.reports .table{
    border: none;
    margin: 0;
    margin-top: 30px;
}

.reports .table td p{
    margin: 0;
}

.reports .table thead tr th{
    border-top: none;
}

.reports .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.reports .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.reports .table tbody tr td{
    color: black;
    font-size: 14px;
    height: 50px;
    text-align: center;
}
.reports .table tbody tr td.spacer{
    height: 10px;
    background: transparent;
}
.reports .table tbody tr td svg{
    font-size: 12px;
}

.reports .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.reports .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.reports .react-datepicker-wrapper input{
    background-color: #1B3769;
    color: white;
    border: none;
    padding: 0;
    margin: 0;
}

.reports .barraBusqueda{
    border: 0.5px solid #D7D7D7;
    border-radius: 12px;
}

.reports .barraBusqueda input{
    border: none;
}

.reports .barraBusqueda button{
    background: none;
    background-color: none;
    border: none;
}

.reports .drop-down-filters{
    background-image: none;
    background-Color: #1B3769;
    border-radius: 20px;
    border-style: none;
}

.reports .drop-down-categories{
    margin: auto;
    padding: 0px;
    text-align: center;    
}

.reports .table-scroll{
    max-height: 60vh;
    overflow-x: auto;
}

.reports .tables-scroll{
    height:53%;
    overflow-x: auto;
}

.reports .card-body{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.reports .pagination-container{
    display: flex;
    flex-direction: row;
    color: #1B3769;
    list-style-type: none;
    margin: 10px;
    float: right;
    padding: 0;
}

.reports .pagination-container li{
    border: none !important;
}

.reports .pagination-container li a{
    margin: 8px;
    font-size: 20px;
}

.reports .pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.reports .pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.reports .pagination-container .selected{
    font-weight: bolder;
    color: white;
}

.reports .options .card-body label{
    margin: 0px;
    padding: 0px;
}

.reports .reports-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.reports .row-selection .selected{
    background: #FFFFFF;
    opacity: 0.8;
    margin-left: -10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.reports .row-selection p{
    font-size: 18px;
    color: black;
    margin: 0 auto;
}
.reports .row-selection .section{
    margin-right: 10px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 15px;
    text-decoration: none;
}

.reports .row-selection{
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 20px 20px 20px 20px;
}

.reports .row-selection .text-selected {
    font-weight: bold;
}

.reports .row-selection .text-section {
    font-weight: lighter;
}

.reports .col-reports{
    padding: 0 !important;
}

.reports .reload-table-grey{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5) !important;
    color: black;
    font-size: 1.4em;
}

.reports .check-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
}

.reports .table-scroll ul {
    margin-bottom: 0;
}

.reports .table-scroll li {
    text-align: start;
}