.interns {
    width: 100%;
    min-height: 85vh !important;
}

.interns h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}
.title h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
    margin-bottom: 20px;
}

.universities .alter-table{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.universities .alter-table .add-university,
.universities .alter-table .reload-table{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    width: 50px;
    font-weight: 600;
    font-size: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px #888;
    margin-right: 20px;
    cursor: pointer;
}

.universities .alter-table .search-icon{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50% 0 0 50%;
}

.universities .alter-table .search-container{ margin: 0; }
.universities .alter-table .search-container input{
    height: 50px;
    border-radius: 0 25px 25px 0;
    border: none;
    background:rgba(255, 255, 255, 0.6);
    width: 270px;
    margin-right: 20px;
 }
.universities .alter-table .search-container input:focus{
    box-shadow: none;
}
.interns .title-search-bar{
    display: flex;
    flex-direction: row;
}

.interns .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.interns .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.interns .search-container{
    width: 90%;
}

.interns .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.interns .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.interns .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.interns .search-bar-container .show{
    transform: translate(0px, 0px);
}

.interns input{
    background-color: #eceef1;
    border-radius: 10px;
}

.interns select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.interns label{
    font-weight: bold;
}

.interns .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.interns .form-label{
    color: black;
    font-size: 15px;
}

.interns .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.interns .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-intern a{
    color: #1B3769;
}

.new-intern a:hover{
    color: rgb(46, 243, 41);
}

.new-intern svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.unsuscribe-intern-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.unsuscribe-intern-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.unsuscribe-intern-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.unsuscribe-intern-modal small{
    color: #1B3769;
    margin: auto;
}

.unsuscribe-intern-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 10px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.unsuscribe-intern-modal .btn-danger{
    border: 1px solid #666;
    color: #666;
    background: transparent;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.unsuscribe-intern-modal .btn-danger:hover,
.unsuscribe-intern-modal .btn-danger:focus,
.unsuscribe-intern-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.unsuscribe-intern-modal label{
    font-weight: bold;
    font-size: 14.5px;
}


.verify-interns-docs .form-overflow {
    height: 75vh;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.verify-interns-docs .profile-picture{
    width: 150px;
    height: 150px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}

.verify-interns-docs .profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}

.verify-interns-docs .form-control-file{
    display: none;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.verify-interns-docs .profile-picture svg:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs .row-selection a{
    margin-right: 10px;
}

.verify-interns-docs .row-selection a:hover{
    color: black;
}

.verify-interns-docs .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.verify-interns-docs .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.verify-interns-docs a{
    color: #1B3769;
}

.verify-interns-docs a:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.verify-interns-docs h6{
    font-weight: bold;
    font-size: medium;
    margin: 0 0 15px 0;
    color: #1B3769;
}

.verify-interns-docs .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.verify-interns-docs .modal-header{
    display: flex;
    flex-direction: column;
}

.verify-interns-docs p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}


.verify-interns-docs small{
    color: #1B3769;
    margin: auto;
}

.verify-interns-docs input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs .form-label{
    color: black;
    font-size: 15px;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
}

.verify-interns-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.verify-interns-docs .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.verify-interns-docs .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-cc-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.new-cc-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.new-cc-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.new-cc-modal input[id="description"]{
    line-height: 140%;
    padding: 50px 10px;
}

.new-cc-modal .btn-primary{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-cc-modal .btn-danger{
    border: 1px solid #666 !important;
    color: #666;
    background: transparent;
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.new-cc-modal .btn-danger:hover,
.new-cc-modal .btn-danger:focus,
.new-cc-modal .btn-danger:active{
    border: 1px solid #666 !important;
    color: #666 !important;
    background: transparent !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
}

.new-cc-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.interns-list{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 85vh;
}

.interns-list .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.interns-list .options a{
    color: #1B3769;
    margin: 0 0 0 3px;
}

.interns-list .options a:hover{
    color: rgb(46, 243, 41);
}

.interns-list .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    width: 100%;
    height: 75vh;
}

.interns-list .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 250px;
    height: 260px;
    box-shadow: 3px 5px 5px #d7d7d7;
}

.interns-list .card .card-body .profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    position: relative;
    margin: 10px;
}

.interns-list .card .card-body .profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 22px;
}

.interns-list .card .form-control-file{
    display: none;
}

.interns-list .card .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.interns-list .card .form-file-label:hover{
    color: rgb(46, 243, 41);
}

.interns-list .card .card-body .card-options svg:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .pagination-container{
    display: flex;
    flex-direction: row;
    color: #1B3769;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 0 0 0;
    float: right;
    padding: 0;
}

.manage-departments .pagination-container li{
    border: 0.5px solid #d7d7d7;
}

.manage-departments .pagination-container li a{
    margin: 8px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

.pagination-container .selected{
    font-weight: bolder;
    color: rgb(46, 220, 41);
}

.interns-list-options {
    display: flex;
    flex-direction: row;
    margin-right: 60px;
}

.interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.rotate{
    animation: rotate 1.5s linear infinite; 
}

.manage-departments a{
    color: #1B3769;
    font-size: 18px;
    margin: 0 0 0 5px;
}

.manage-departments .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.manage-departments .title a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 20px;
}

.manage-departments  a:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .title svg{
    margin: 8px 5px 0 0;
}

.manage-departments p{
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: auto;
    padding-top: 5px;
}

.manage-departments .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
}

.manage-departments button {
    background: none;
    background-color: transparent;
    border: 0;
}

@keyframes rotate{
    to{ transform: rotate(360deg); }
}



@media only screen and (max-width: 1620px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1337px) {
    .manage-departments .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 991px) {
    .verify-interns-docs .modal-dialog-centered{
        max-width: 95%;
        width: 100%;
    }
}

@media only screen and (max-width: 845px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

    .interns-list{
        height: 68vh;
    }
}

@media only screen and (max-width: 540px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

.universities-table{
    min-height: 40vh;
    overflow-y: scroll;
    width: 97%;
}

.table-universities{
    align-content: center;
}

div.thead-row-universities{
    background-color: #E9EDF3;
    padding: 0px;
    margin: 0px;
}

div.col-head-university{
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 10px;
}

div.college-name{
    color: #1B3769;
    font-weight: bold;
}

div.career{
    padding-left: 10px;
}

div.col-university-info{
    display:flex;
    align-items: center;
    border-bottom: 0.1px solid rgb(222, 226, 230);
    padding: 3px;
    margin: 0px;
    min-height: 110px;
}

div.row-university-info{
    padding: 0px;
    margin: 0px;
    min-height: 110px;
}

.university-info-table, .university-info-table .accordion-colapse{
    background-color: transparent!important;;
}

#table-universities .row-university{
    background: #189e9a;
}

#table-universities .accordion div.row-university-info{
    background-color: white;
}

.university-info-table:nth-of-type(odd){
    background-color: #f0f0f0;
}

#table-universities div.row-university-info:hover {background-color: #efefef;}

div.table-faculties{
    margin-left: auto;
    width: 99%;
}

div.table-careers{
    margin-left: auto;
    width: 99%;
}

.accordion-colapse{
    background-color: #FFFFFF;

}

.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    display: flex;
    position: relative;
    margin: 10px 20px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 22px;
}
.profile-picture{
    width: 110px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: #189e9a;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    align-self: center !important;
    height: 100%;
}