@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400&display=swap');

.login{
    width: inherit;
    height: inherit;
    background-image: url("../../assets/background-girl.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.login .blurer{
    height: 100%;
}
.login .card{
    margin-top: 70%;
    width: 100%;
}

.login .form-container{
    display: flex;
    align-items: center;
    justify-content: center;
}


.login .form-box{
    width: 400px;
    background:  rgb(250, 250, 250, 0.7);
    border-radius: 15px;
    min-height: 60vh !important;
    max-height: 90vh !important;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: transparent;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(7,210,204);
    background: linear-gradient(0deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.login .description-container {
    padding: 20% 0 0 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.login .description-container h2{
    font-weight: bold;
    width: 50%;
    font-size: 55px;
    margin-bottom: 20px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login .description-container h4{
    width: 75%;
    font-size: 25px;
    font-weight: 200;
    color: white;
}

.login h5{
    font-weight: bold;
    color: rgb(41, 36, 36);
    display: inline-block;
    margin-bottom: 3%;
}

.new-cc-modal form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}

.new-cc-modal form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.login .row{
    margin: 0;
}
.login form{
    margin: 0 40px;
}
.login form input{
    height: 50px;
    background: rgba(250, 250, 250, 0.75) !important;
    border-radius: 15px;
    color: rgb(41, 36, 36) !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15) !important;

}
.login form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
    color: #12121307;
}
.login form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}
.login form input:focus{
    outline: none;
    box-shadow: none;
    color: #12121307;
    background: rgba(250, 250, 250, 0.75);
}
.login form input:focus::placeholder{
    color: #97a3b2;
}
.login form input::placeholder{
    color: #12121362;
}
.login form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.login form .input-icon{
    font-size: 25px;
    color: rgb(2, 2, 2);
}
.login form .see-password{
    cursor: pointer;
}
input:-webkit-autofill:hover,
input:-webkit-autofill{
    box-shadow: 0 0 0 30 #97a3b2 inset !important;
    -webkit-box-shadow: 0 0 0 30px rgba(250, 250, 250, 0.75) inset !important;
    -webkit-text-fill-color: rgb(104, 104, 104) !important;
}
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    box-shadow: 0 0 0 30 #e8f0fe inset !important;
    -webkit-box-shadow: 0 0 0 30px #e8f0fe inset !important;
    -webkit-text-fill-color: rgb(104, 104, 104)  !important;
}
input:-webkit-autofill
{
 background-color: rgba(250, 250, 250, 0.75);
 -webkit-text-fill-color: rgb(104, 104, 104) !important;
}

.login .InternsLogo {
    width: 207px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.login .logoCaintra {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.login .text-container{
    margin-top: 30px;
}

.login .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.login input{
    background-color: #eceef1;
    border: none;
    border-radius: 5px;
}
.login .forgot-container{
    text-align: left;
}
.login .forgot-password{
    color: rgb(104, 104, 104) !important;
    text-decoration:underline;
    cursor: pointer;
    font-size: 19px;
}

.login .row-logo{
    margin: 5% 5% 5% 5%;
}

.login .row-contact{
    text-align: center;
    margin-top: 5%;
}

.login .btn {
    background: rgb(181, 197, 197);
    background: #1B3769;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    outline-style: none;
    padding: 8px 70px 8px 70px;
    font-size: 1.3em;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px auto;
}

.login .btn:hover{
    background: #072353;
}


.login .login-btn{
    margin-top: 80px ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.login .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: 40px;
    margin-bottom: 20px;
}


.login .row-selection a{
    color: rgb(54, 54, 54);
    font-size: 25px;
    margin-right: 5%;
    font-family: 'Mulish', sans-serif !important;
}

.logos-container .divider{
    border: none !important;
    border-right: 1px solid rgb(54, 54, 54) !important;
} 

.login .row-selection .logInBtn{
    border-right: 1px solid rgb(54, 54, 54);
    padding-right: 5%;
}

.login .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.login .need-help-container{
    text-align: right;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
}
.login .need-help-container .need-help{
    color: rgb(61, 61, 61);
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 1em;
    text-decoration: underline;
}

.login .register-company-container{
    text-align: right;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.login .register-company-container .register-here{
    color: rgb(61, 61, 61);
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 1em;
    text-decoration: underline;
} 

.login .need-help-container .need-help svg{
    color: rgb(54, 54, 54);
    margin-left: 10px;
    margin-top: -5px;
    font-size: 2.4em;
}
@media only screen and (min-width: 1920px) {
    .login .row-selection{
        margin-bottom: 10%;
    }    

    .login input{
        height: 50px;
    }

    .login .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .login .row-contact{
        padding-top: 30px;
    }
}

.logos-container{
   margin-top: 15px;
   display: flex !important;
   justify-content: space-evenly !important;
   align-content: center !important;
}

.whatsapp-icon{
    width: 18px;
    height: 18px;
}


@media only screen and (min-width: 1366px) {
    .login .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .login .background-container{
        display: none;
    }

    .login .row-selection{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .login .row-selection a{
        font-size: 20px;
    }
    
}