*{
  padding: 0;
  margin:  0;
  scroll-behavior: smooth;
}

#root{
  background: linear-gradient(72.46deg, rgba(37, 235, 81, 0.75) 0%, rgba(0, 174, 239, 0.75) 100%);
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 15px;

}

::-webkit-scrollbar-thumb:hover {
  background: #1B3769;
}

@media only screen and (min-width: 993px) {
  #root{
    overflow-y: hidden;
  }
}


.myButton{
  border-radius: 20px !important;
  margin-top: 15px;
}

.myHeader{
  height: 90px !important;
  overflow: hidden;
}

div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
} 

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}


.myInputDate{
  border-style: solid !important;
  border-color: rgb(197, 197, 197) !important;
  border-width: 0.5px !important;
  border-radius: 5px !important;
  height: 30px !important;
  width: 104px !important;
  margin-left: 11px !important;
}