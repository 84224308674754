.dispersion{
    width: 100%;
    height: 93vh;
}

.dispersion .title {
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.dispersion .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: black;
}
.dispersion .title .default-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    color: white;
    margin: 5px 0px 0px 25px;
}

.dispersion .alter-table{
    display: flex;
    align-items: center;
}

.dispersion .alter-table .csv-button{
    display: inline-flex;
    align-items: center;
    color: #666;
    background:rgba(255, 255, 255, 0.3);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #999;
}

.dispersion .alter-table .disperse-button svg,
.dispersion .alter-table .csv-button svg{
    margin-right: 8px;
    font-size: 25px;
}
.dispersion .alter-table .dd-wrapper{
    display: inline-block;
}
.dispersion .alter-table .dd-header{
    height: 50px;
    background:rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px #999;
    color: #666;
    padding: 0 20px;
    margin: 0;
}

.dispersion .options input,
.dispersion .options select,
.dispersion .options .rw-widget-container,
.dispersion .options .rw-select1{
    background:rgba(255, 255, 255, 0.3) !important;
}

.dispersion .options input,
.dispersion .options select{
    height: 50px;
}

.dispersion .title .filters-menu{
    width: 90%;
}

.dispersion .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto; 
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.my-table-border{
    min-height: 350px !important;
}

.dispersion .table{
    border: none;
    margin: 0;
}

.dispersion .card-header{
    padding: 0;
    margin: 0;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.dispersion .card-header button{
    text-decoration: none;
    color: #1B3769;
    width: fit-content;
}

.dispersion .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.dispersion .card-header button:active{
    outline: none;
    border: none;
}

.dispersion .card-header div{
    text-decoration: none;
    color: #1B3769;
    align-self: flex-end !important;
    margin-right: 10px;
}

.dispersion .table thead, .dispersion .table th{
    border: none;
    border-color: transparent;
    text-align: left !important;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}

.dispersion .table thead tr th{
    border-top: none;
}

.dispersion .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.dispersion .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.dispersion .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.dispersion .table tbody tr td svg{
    font-size: 12px;
}

.dispersion .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.dispersion .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.dispersion .options{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dispersion .options > *{
    margin: 15px 15px 0px 0 !important;
}

.dispersion .form-group{
    margin-top: 20px;
}

.dispersion label{
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
}

.dispersion input[type=number]{
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 20px;
    width: 10rem;
}

.dispersion .rw-datetime-picker input{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: none;
    width: 8rem;
}

.dispersion .rw-datetime-picker > *{
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.dispersion .options .rw-select{
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
}

.dispersion select {
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    width: 15rem;
}

.dispersion .disperse-and-csv{
    display: flex;
    flex-direction: row;
}

.dispersion .disperse-and-csv .disperse-button{
    background-image: linear-gradient(to right, #5FEC90 40%, #5FEC90 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
}

.dispersion .disperse-and-csv .disperse-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.dispersion .disperse-and-csv .disperse-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.dispersion .disperse-and-csv .csv-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
    color: white;
    text-decoration: none;
}

.dispersion .disperse-and-csv .csv-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.dispersion .disperse-and-csv .csv-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.dispersion .company-accordion{
    width: 100% !important;
    background-color: red;
}

.dispersion .card-body{
    padding: 0;
    margin: 0;
}
.dispersion-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 30px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}

.dispersion .search-icon:hover{
    color: darkgreen;
}
.dispersion .search-container{
    width: 300px !important;
    margin: 0;
    float: left !important;
}
.dispersion .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}
.dispersion .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}
.dispersion .search-icon:hover{
    color: darkgreen;
}
.dispersion .search-container input{
    height: 50px;
    border: none;
    border-radius: 0 25px 25px 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.dispersion .search{
    float: left;
}
.dispersion .search input{
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    float: left;
}
.screen-select-fixed {
    min-height: 65vh !important;
}


.green-button{
    margin: 10px 0px;
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    border-radius: 25px;
    font-style: normal;
    font-weight: 400;
    color: #FAFAFA;
    min-width: 100px;
    padding: 10px 20px;
    border: none !important;
    outline-style: none !important;
    justify-content: center;
    align-items: center;
    height: 44px;
    display: flex !important;
    font-size: 15px;
}

.dispersion .dropdown-options{
    margin: 10px 0px 10px 0px;
    padding: 15px;
}

.dispersion .dropdown-options .dropdown-menu{
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
    width: 250px;
}

.dispersion .options-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.dispersion .options-search-container a{
    font-size: 14px !important;
    font-weight: 300;
}

.dispersion .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.dispersion .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}

.dispersion .options .btn{
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 25px;
    color: #666;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    height: 50px;
}

.dispersion .alter-table .disperse-button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 25px;
}

.dispersion .button-color{
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: none !important;
    box-shadow: none !important;
}

.dispersion .list-interns-container{
    min-height: 300px !important;
}

.centered-component{
    margin: 0 auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.dispersion-company-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dispersion-company-header p{
    color: red;
    margin: 0px 8px 0px 10px;
}

.dispersion .companies-filter .dropdown-menu{
    width: 320px !important;
    max-height: 70vh !important;
    padding: 17px 14px 17px 17px !important;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
}

.dispersion .companies-filter .dropdown-menu .companies-search-container{
    max-height: 50vh;
    margin-top: 10px;
    overflow-y: scroll;
}

.dispersion .companies-filter .dropdown-menu::-webkit-scrollbar-track {
    background-color: white !important;
}

.dispersion .companies-filter .dropdown-menu .filter-form-text{
    border-radius: 25px;
    border: none;
    box-shadow: 0px 0px 9px rgba(215, 215, 215, 1);
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.dispersion .companies-filter .buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
}

.dispersion .w-text{
    color: white !important;
}

.dispersion .companies-filter .dropdown-menu .check-filter .form-check-label{
    font-size: 14px !important;
    font-weight: 300;
}

.dispersion .companies-filter .dropdown-menu .check-filter{
    display: flex;
    align-items: center;
}


@media (max-width: 1105px) {
    .dispersion .search{
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
}