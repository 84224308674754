.register-company{
    width: 100vw;
    height: 100vh;
}

.background-container{
    height: 100vh;
    
}

.background-container-register{
    height: 100vh;
    background-image: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    border-radius: 25px !important;
}


.register-company .logoCaintra {
    width: 100px;
    height: auto;
    margin-left: 80%;
}


.register-company form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}

.register-company form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.register-company form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.register-company .description-container {
    padding: 20% 0 0 4%;
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    color: white;
}

.register-company .description-container h2{
    font-weight: bold;
    font-size: 55px;
    margin-bottom: 20px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.register-company .description-container h4{
    font-size: 20px;
    text-align: justify;
    margin-right: 50px;
    color: white !important;
}

.register-company .description-container h5{
    font-weight: bold;
    margin-bottom: 3%;
    color: white !important;
}

.register-company .step-bar {
    margin: 20px 0 0 10px;
}

.register-company .content-class{
    margin: 70px 0 0 0;
    padding: 0;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 6rem;
}

.register-company .buttonsClass{
    background: white;
    padding: 0.5rem;
    position: sticky;
    bottom: 0;
}

.register-company .next-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
    border: none;
    border-radius: 15px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(255, 255, 255);
}

.register-company .previous-button:hover{
    border: none;
    color: white;
    text-decoration: none;
}
.register-company .next-button:hover{
    color: white;
    text-decoration: none;
    box-shadow: none !important;
}

.register-company .previous-button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,.5) 0%, rgba(35,234,87,.5) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(255, 255, 255);
}

.register-company .previous-button:hover{
    border: none;
}

.register-company .progress-labels{
    font-weight: bold;
    font-size: 12px;
}

.register-company .progress-labels{
    font-weight: bold;
    font-size: 12px;
}

.register-company p{
    font-weight: bold;
    color: #1B3769;
}

.register-company input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
}

.register-company form {
    padding: 10px;
}
.register-company .form-label{
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.register-company select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.register-company .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
    color: white;
}

.register-company .files svg{
    margin: 3px 3px 0 0;
}

.register-company .files small{
    margin: 0;
}

.register-company .form-file-label:hover{
    cursor: pointer;
}

.register-company .form-control-file{
    display: none;
}

.register-company input[type="checkbox"]{
    height: auto;
}

.register-company .back-button{
    position: fixed;
    z-index:100;
    top: 30px;
    left: 60px;
    font-size: 30px;
    color: #1B3769;
}

.register-company .back-button:hover{
    color: white;
}

.welcome-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.welcome-modal .modal-content{
    background: none;
    background-color: transparent;
    border: none;
}

.welcome-modal .modal-dialog-centered{
    min-width: 90%;
    background: none;
    background-color: transparent;
}

.welcome-modal .modal-header{
    display: flex;
    flex-direction: column;
    border: none;
}

.welcome-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: none;
    background-color: transparent;
}

.welcome-modal .modal-body svg{
    color: #6ce090;
}

.welcome-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-size: 75px;
    font-weight: bold;
}

.welcome-modal .modal-body h3{
    color: white;
    font-size: 40px;
    margin-bottom: 15px;
}

.welcome-modal{
    background-color: rgb(255, 255, 255, 0.2);
    backdrop-filter: blur(7px);
}

.success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}
.custom-dropdown{
    background-color: #eceef1 !important;
    border: none !important;
    border-radius: 10px !important;
    color: #5d6369 !important; 
    text-align: left !important;
    padding-left: 3% !important;
}
.menu-custom-dropdown{
    width: 100% !important;
    font-size: 1rem !important;
    font-weight: 400 !important; 
    line-height: 1.5  !important;
    background-color: #6a6e70 !important;
    color: blanchedalmond !important;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
}
.validate-email{
    width: 50% !important;
    align-content: center !important;
    align-self: center !important;
}
.error-label{
    color: tomato !important; 
    font-size: .8rem !important;
    padding: 0;
    margin-bottom: 0px !important;
    margin-left: 2%;
}

.agent {
    margin-top: 5px;
    font-weight: bold;
    color: #1B3769 !important;
}