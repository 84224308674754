body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header, .row {
  display: flex; 

}

.col {
  flex: 1;        
}

.apexcharts-tooltip{
  width: 60%;
  max-width: 1230px;
  font-size: 9px;
  z-index: 99999 !important;
}

.apexcharts-tooltip-series-group{
  margin: auto;
  width: 61%;
}


.header{
  font-weight: bold;
}

.modal-content{
  padding-bottom: 15px !important;
}

.sticky {
 
  top: 0;
  margin: 10px 30px 0px 7px !important;
  z-index: 1;
  border-radius: 10px;
  margin: auto;
  width: 96.5%;
  padding: 12px;
  height: 170px;
}

.element-space{
  margin: 20px 0 30px 0;
  height: 40px !important;
  word-wrap: break-word;
}

