.dd-wrapper-noti .dd-header-noti{
    background-color: transparent !important;
    border: none;
    box-shadow: 0px 0px 0px rgba(215, 215, 215, 1) !important;
    outline-style: none;
    font-size: 15px;
    text-align: left;
    padding: 10px 15px;
    color: #666;
    z-index: 1;
}

.dd-wrapper-noti .dd-header-noti div{
    display: inline-block;
    font-size: 16px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.dd-wrapper-noti .dd-header-noti p{
    font-weight: normal;
    margin: auto;
}

.dd-wrapper-noti .dd-header-title{
    margin: 0;
    padding: 0;
}

.dd-wrapper-noti ul{
    margin-block-end: 20 !important;
    margin-block-start: 0 !important;
    margin-inline-end: 0 !important;
    margin-inline-start: 0 !important;
    margin: 0;
    padding: 0;
    width: 100px !important;
}

.dd-wrapper-noti .dd-list{
    position: absolute !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    margin-top: 10px;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0px;  
    z-index: 1;
    width: 400px !important;
}
.dd-wrapper-noti .dd-list .nav-item{
    width: 48% !important;
    border-radius: 0px !important;
}
.dd-wrapper-noti .dd-list li{
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100% !important;
    box-shadow: none !important; 
}

.dd-wrapper-noti .dd-list > *{
    color: rgb(0, 0, 0);
    font-weight: lighter;
    font-size: 18px;
}

.dd-wrapper-noti .dd-list li label{
    margin: 0;
    padding: 0;
}

.dropdown-container{
    word-wrap: break-word;
    box-shadow: none; 
}

.lu-container{
    overflow-y: scroll;
    max-height: 80vh;
    background-color: white;
    box-shadow: none !important;
    padding-bottom: 1rem !important;
}

.toast-border{
    border: none !important;
    background-color: white !important;
}
.card-text{
   font-size: small; 
   word-wrap: break-word;
}
hr .solid{
    width: 100%;
}
.text-title{
    font-size: 1rem !important;
    text-align: start;
    margin: 0;
    color: #1B3769;
    margin-bottom: .2rem !important;
}
.card-divider{
    margin-bottom: 2% !important;
    margin-top: 2% !important;
}
.footer-text{
    font-weight: bold;
    text-decoration: none !important;
    font-size: .7rem !important;
    text-align: start;
    margin-bottom: 0;
    color: #1B3769;
}
.dd-wrapper-noti .card-body{
    padding: 1rem .6rem 0rem .6rem !important;
}
.text-description{
    text-align: start !important;
    margin-bottom: -.5rem !important;
    font-size: .8rem;
    font-weight: initial;
    color: rgb(66, 66, 66);
}

.list-end:last-child{
    background-color: aquamarine !important;
}

.tab-title{
    color: #1B3769 !important;
}

@media only screen and (max-width: 1780px){
    .dd-wrapper-noti .dd-list{
        width: 15% !important;
    }
}

@media only screen and (max-width: 1550px){
    .dd-wrapper-noti .dd-list{
        width:35% !important;
    }
}

@media only screen and (max-width: 1380px){
    .dd-wrapper-noti .dd-list{
        width: 19% !important;
    }
}

@media only screen and (max-width: 1280px){
    .dd-wrapper-noti .dd-list{
        width: 20% !important;
    }
}