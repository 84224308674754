*{
    font-family: "Mulish" !important;
}

.companies-suspension{
    width: 97%;
    height: 96%;
}
.companies-suspension h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.companies-suspension .search-container-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.companies-suspension .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    font-size: 28px;
    color: #666;
}

.companies-suspension .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
    width: 100%;
}
.companies-suspension .search-container input{
    border: none;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    width: 100%;
}

.companies-suspension input{
    background-color: #eceef1;
    border-radius: 10px;
}


.companies-suspension .options-container{
    margin-top: 20px;
    margin-bottom: 20px;
}


.companies-suspension .buttons-general-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.companies-suspension .buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companies-suspension .buttons-container button{
    display: flex !important;
    border: none;
    border-radius: 30px;
    outline-style: none;
    padding: 0px 0px 0px 10px;
    font-size: 1.1em !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 50px;
    width: 200px;
    margin-top: 10px;
}

.companies-suspension .buttons-container .filter-button{
    padding: 0px 0px 0px 10px;
    height: 50px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}


.buttons-container button:hover{
    outline-style: none;
}


.companies-suspension .yellow-button{
    background-color: rgb(227, 204, 0);
}

.companies-suspension .grey-button{
    background-color: rgb(129,129,129) !important;;
    background: rgb(129,129,129) !important;;
}

.companies-suspension .grey-button:hover{
    background-color: rgb(142, 142, 142) !important;
    background: rgb(129,129,129) !important;
}

.companies-suspension .green-button{
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
} 

.companies-suspension{
    display: flex;
    flex-direction: column;
    height: 89vh;
}

.companies-suspension .companies-table-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    overflow: scroll;
}


/* asd */

.companies-suspension .companies-table-container-list .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7 !important;
    overflow-y: auto !important;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5) !important;
}


.companies-suspension .companies-table-container-list .table-container {
    overflow-x: scroll !important;
    display: inline-block;
    width: 95%;
    margin-top: 0px;
}


.companies-suspension .companies-table-container-list .table{
    width: 100% !important;
    border: none;
    margin: 0;
    border-collapse:separate;
    border-spacing:0 10px;
}

.companies-suspension .companies-table-container-list .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: bold;
}

.companies-suspension .companies-table-container-list .table tbody tr{
    background: rgba(255, 255, 255, 0.6)!important;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.10);
    border-radius: 5px; 
}


.companies-suspension .companies-table-container-list .table tbody tr td:first-child { border-top-left-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:last-child { border-top-right-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.companies-suspension .companies-table-container-list .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.companies-suspension .companies-table-container-list .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.companies-suspension .companies-table-container-list .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.companies-suspension .companies-table-container-list .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.companies-suspension .pagination-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px !important;
}


.companies-suspension .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.companies-suspension .custom-switch .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.companies-suspension .custom-switch  .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
    cursor: pointer;
}

.companies-suspension .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

.companies-suspension .green-switch .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color:  rgba(7,210,204,1);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.companies-suspension .orange-switch .custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color: rgb(234, 201, 35);
    background: linear-gradient(90deg, rgb(234, 201, 35)  0%,  rgb(210, 146, 7) 100%);
}

.companies-suspension .custom-switch .custom-control-label::before {
    color: #fff;
    border-color:  rgb(210, 7, 7);
    background: linear-gradient(90deg, rgba(210, 7, 7, 0.921) 0%, rgb(255, 0, 0) 100%);
    cursor: pointer;
}

.companies-suspension .custom-switch .custom-control-label::after {
    background-color: #fff;
    cursor: pointer;
}

.companies-suspension .center-content{
    display: flex !important;
    justify-content: center !important;
}

.history-modal-table .center-content{
    display: flex !important;
    justify-content: center !important;
}


.companies-suspension .history-icon{
    width: 25px !important;
    margin-right: 10px;
}

.companies-suspension .checkbox-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.companies-suspension .form-check-custom{
    margin-right: 10px;
}

.companies-suspension  input[type=checkbox]:checked{
    filter: saturate(0.1);
}

.companies-suspension .file-excel-icon{
    color: #fff;
    margin-right: 8px;
}

.companies-suspension .disabled-white-selection::selection{
    color: #00000099 !important;
    background: #0000001c;
}

.history-modal .modal-content{
    background: rgba(255,255,255,0.9) !important;
}

.history-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.history-modal p{
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    color: black;
    margin: auto;
    padding-top: 5px;
}


.history-modal .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.history-modal .btn-danger{
    background: white;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 15px;
}

.history-modal td{
    cursor: pointer;
}


.modal-width-companies{
    width: 85% !important;
    max-width: 85% !important;
}

.modal-height-companies{
    height: 50% !important;
    max-height: 50% !important;
}

.history-modal-table{
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
}

.history-modal .close-button-container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.companies-suspension .amount-max-width{
    max-width: 195px;
}

.companies-suspension .pointer{
    cursor: pointer;
}

.companies-suspension .dollar-icon-size, .save-icon-size{
    height: 28px !important;
    width: 28px !important;
}

.companies-suspension .trash-icon-size{
    height: 25px !important;
    width: 25px !important;
    margin-top: 2px;
    margin-left: 4px;
    margin-right: 5px;
}

.companies-suspension .rw-widget-picker {
    border: none;
    background-color: transparent;
    border-radius: 10px;
    height: 60px;
    margin-left: 2px !important;
}

.companies-suspension .rw-widget-picker .rw-input{
    border: 1px solid #ced4da;
}

.companies-suspension .rw-widget-picker>.rw-select {
    border: 1px solid #ced4da;
    border-radius: 0px 10px 10px 0px !important;
}

.companies-suspension
.rw-widget-container.rw-state-focus,
.rw-state-focus>.rw-widget-container,
.rw-widget-container .rw-state-focus:hover,
.rw-state-focus>.rw-widget-container:hover{
    background-color: transparent !important;
    border-color: none !important;
    box-shadow: none !important;
}

.companies-suspension .spinner-custom{
    height: 20px !important;
    width: 20px !important;
    margin-right: 5px;
}

.history-modal-table .header-table {
    color: black !important;
}

.history-modal-table .font-content{
    font-weight: 600;
    color:  #666 !important;
}

.react-tooltip-lite {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .tooltip-arrow {
    border-top-color: #333;
  }
  
  .tooltip.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .tooltip.transition {
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    opacity: 0;
    transform: translateY(10px);
  }
  
  
  .my-date{
    margin-left: 5px;
    padding: 0 20px!important;
    background: hsla(0,0%,100%,.5)!important;
    border: none;
    border-radius: 30px;
    color: #666 !important;
    display: -webkit-flex;
    display: flex;
    width: 250px !important;
    align-items: right;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0 15px 0 0;
  }

  .react-datepicker-wrapper input {
    background: transparent!important;
    color: #666;
    height: 50px;
    padding-top: 0;
}



.react-datepicker-wrapper input {
    background: transparent!important;
    color: #666 !important;
    height: 50px;
    border: none;
    padding-top: 0;
}

.my-btn {
    margin-right: 10px;
    margin-left: 60px;
}