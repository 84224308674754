.dd-wrapper .dd-header{
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px;
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    margin: 5px 0px 0px 1rem;
    box-shadow: none !important;
    font-weight: 600;
}

.dd-wrapper .dd-header p{
    font-weight: 600;
    margin: auto;
}

.dd-wrapper .dd-header-title{
    margin: 0;
    padding: 0;
}

.dd-wrapper ul{
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin: 0;
    padding: 0;
}

.dd-wrapper .dd-list{
    position: absolute;
    margin-top: 10px;
    margin-left: 1.2rem;
    width: 400px !important;
    max-height: 60vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
}

.dd-wrapper .dd-list li{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.dd-wrapper .dd-list > *{
    color: black;
    font-weight: lighter;
    font-size: 18px;
}

.dd-wrapper .dd-list li label{
    margin: 0;
    padding: 0;
}

.dd-wrapper .dd-list li input[type=checkbox]{
    width: 11px;
    cursor: pointer;
    margin: 5px 5px 0 5px;
}
