.dashboard {
    overflow-x: hidden;
    overflow-y: scroll; 
    height: 87vh;
    font-family: "Mulish" !important;
}

.dashboard h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.dashboard .title-search-bar{
    display: flex;
    flex-direction:column;
}

.dashboard .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.dashboard .title-search-bar .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
    font-weight: bold;
}

.internsDashBoard{
    margin-top: 5%;
    height: 10% !important;
}

.interns-container{
    height: 100%;
    width: 100%;
}

.interns-container .container{
    background-attachment: #FFFFFFE5;
    font-size: 25px;
    border-radius: 12px;
    display: flex;
    flex-direction: initial;
    height: 130px;
}

.interns-container .container .circle-container{
    width: 35%;
    height: 100%;
    align-content: center;
    padding: 1rem;
}

.interns-container .container .text{
    color: #121213;
    font-weight: bold;
    padding: 1rem;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.interns-container .container .text h5{
    color: #121213;
    font-weight: bold;
}
.interns-container .container .text h2{
    color: #121213;
    font-weight: bold;
}

.interns-container .container .circle-container .circle{
    align-items: center;
    clip-path: circle();
    justify-content: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
}

.set-color-icon{
    filter: invert(100%) sepia(1%) saturate(3485%) hue-rotate(236deg) brightness(117%) contrast(100%);
    width: 28%;
}


.active-color{
    background: rgba(6, 209, 205, 0.8);
}

.inactive-color{
    background: rgba(237, 90, 108, 0.8);
}

.dashboard .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.dashboard .search-container{
    width: 90%;
}

.dashboard .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.dashboard .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.dashboard .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.dashboard .search-bar-container .show{
    transform: translate(0px, 0px);
}

.dashboard input{
    background-color: #eceef1;
    border-radius: 12px;
}

.dashboard-row{
    margin: 0px 50px 20px 0px;
    height:66vh !important;
    overflow: auto ;
}

.dashboard-container{
    background: #FFFFFFE5;
    font-size: 25px;
    border-radius: 12px;
    padding: 2rem;
    margin: 30px 0px 0px 6px;
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.title-icon-container{
    display: flex;
    flex-direction: column;
}

.title-dashboard{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 14px;
    margin-bottom: 10px;
    justify-content: space-between;
}
.title-gender-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.title-dashboard p{
    margin: 0px 10px;
    font-weight: bold;
}

.gender-dashboard{
    max-width: 99% !important;
}

.gender-dashboard .apexcharts-menu-icon{
    transform: scale(1.7)!important;
    padding-bottom: 50px !important;
    padding-right: 30px !important;
}

.title-icon-container .icons-container{
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 20px 0px 10px 26px;
}

.title-icon-container .comparsion-icons{
    margin: 20px 20px 10px 0px !important;
}


.title-icon-container .icons-container .masculine-icon{
    margin-right: 30px;
    margin-left: 10px;
    width: 20px;
}

.dashboard .history-icon{
    margin-left: 5px;
    width: 25px !important;
}

.dashboard .arrow-rise{
    margin-left: 5px;
    width: 27px !important;
}

.title-icon-container .icons-container .femenine-icon{
    margin-left: 15px;
    width: 25px;
    margin-right: 10px;
}

.legends-container{
    padding: 4rem;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-flow: wrap;
}


.label-container{
    font-size: .8rem;
    width: 100%;
    min-height: 4rem;
    max-height: 6rem;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    flex-wrap: nowrap;
}

.label-legend-brand{
    border-radius: 30px 30px 30px 30px;
    background: rgba(237, 90, 108, 0.8);
    width: 1rem !important;
    height: .5rem;
    margin-right: .8rem;
}
.apexcharts-canvas .apexcharts-title-text{
    font-weight: 700;
    font-size: 20px;
}
.gender-dashboard .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
    display: none;
}

.dashboard .options{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard .options .dropdown-options{
    margin: 10px 0px 10px 0px;
}

.dashboard .options-dashboard-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 45px;
    margin-left: 6px;
}

.dashboard .options-dashboard-container .green-button{
    margin: 10px 0px;
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%);
    border-radius: 25px;
    font-style: normal;
    font-weight: 400;
    color: #FAFAFA;
    min-width: 100px;
    padding: 10px 20px;
    border: none !important;
    outline-style: none !important;
    justify-content: center;
    align-items: center;
    height: 44px;
    display: flex !important;
}
.dashboard .options-dashboard-container .green-button .download-all-icon{
    width: 19px;
    height: 19px;
    filter: invert(100%) sepia(100%) saturate(9%) hue-rotate(346deg) brightness(110%) contrast(96%);
    margin-right: 5px;
}

.dashboard .options .btn{
    background: #FFFFFFE5 !important;
    border: none;
    border-radius: 30px;
    color: #666;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: 600;
    margin-right: 6px;
}

.dashboard .options .filters-menu{
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
}

.dashboard .options .filters-menu-companies{
    min-width: 200px !important;
}

.dashboard .options .filters-menu > *{
    color: black;
    font-weight: lighter;
    font-size: 12px;
    margin: 0px;
}
.dashboard .options .options-search-container {
    max-height: 40vh !important;
    overflow-y: scroll;
}

.dashboard .options-search-container a:hover{
    background-color: rgba(220, 220, 220, 0.500) !important;
}

.dashboard .options-search-container a:active{
    background-color: rgba(220, 220, 220, 0.500) !important;
}


.dashboard .options-search-container hr{
    width: 80%;
    margin: 5px auto;
}

.title-icon-container .icons-container .icon-text-container{
   display: flex;
   align-items: center;
   flex-direction: row;
}
.title-icon-container .icons-container .icon-text-container p{
    font-weight: bold;
    margin-bottom: 0px;
}

.title-icon-container .icons-container .difference-gender-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.title-icon-container .icons-container .difference-gender-container-women{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: 14px;
}


.title-icon-container .icons-container .difference-gender-container-men{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
}
.dashboard .avergare-info-container{
    margin: 30px 0px 0px 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.dashboard .avergare-info-container .scholarship-container{
    width: 50%;
    height: 180px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    margin-right: 10px;
}
.dashboard .avergare-info-container .scholarship-container .info-container{
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    width: 68%;
    justify-content: flex-start;
}

.dashboard .avergare-info-container .scholarship-container .info-container .coin-icon{
    width: 48px;
    height: 48px;
}

.dashboard .avergare-info-container .scholarship-container .info-container h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
}
.dashboard .avergare-info-container .scholarship-container .info-container p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 10px 0px 0px 0px;
    color: rgba(0, 0, 0, 0.6);
}

.dashboard .avergare-info-container .scholarship-container .options-icons{
    display: flex;
    flex-direction: column;
    width: 10% !important;
    justify-content: space-around;
    align-items: flex-end;
    margin-left: 10px;
    height: 40%;
}

.dashboard .avergare-info-container .scholarship-container .options-icons .info-icon{
    width: 18px;
    height: 18px;
}

.dashboard .avergare-info-container .scholarship-container .options-icons .download-icon{
    width: 19px;
    height: 19px;
}

.dashboard .avergare-info-container .scholarship-container  .title-container{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}


.dashboard .avergare-info-container .scholarship-container .title-container .top-uniersities-container{
    overflow-y: scroll;
}

.dashboard .avergare-info-container .scholarship-container .title-container .title-top{
    margin: 0px !important;
}

.dashboard .avergare-info-container .scholarship-container .title-container .top-universities{
    font-weight: 500;
    font-size: 14px;
    margin: 0 !important;
    color: #121213;
}


.dashboard .avergare-info-container .universities-container .universities-title-container{
    height: 100%;
    padding: 10px 0px 10px 0px;
    justify-content: center;
}

.dashboard .avergare-info-container .universities-container{
    width: 50%;
    margin-left: 10px;
    margin-right: 0px;
}

.dashboard .avergare-info-container .scholarship-container .info-universities-container{
    width: 75%;
}


.dashboard .avergare-info-container .scholarship-container .options-university{
    width: 15%;
    align-items: flex-end;
    margin-left: 10px;
    height: 100%;
    justify-content: space-between;
    padding: 15px 0px 20px 0px;
}

.dashboard .avergare-info-container .scholarship-container .options-university .pagination-icon{
    width: 19px;
    height: 19px;
    color: grey;
    margin-right: 2px;
}

.dashboard .avergare-info-container .scholarship-container .options-university .pagination-icon:hover{
    cursor: pointer;
}

.dashboard .dashboard-row .intern-status-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    margin: 30px 0px 0px 6px;
}

.dashboard .dashboard-row .intern-status-container .intern-status{
    margin: 0px 10px 0px 0px !important;
}

.dashboard .dashboard-row .intern-status-container .total-interns{
    margin: 0px 0px 0px 10px !important;
}

.dashboard  .title-search-bar .title-container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.dashboard  .title-search-bar .title-container h5{
    font-weight: lighter;
    margin: 5px 0 0 15px;
    color: darkblue;
    font-size: 1.15em;
    font-weight: 400;
}

.dashboard .date-title {
    font-size: 18px;
}

.dashboard .arrow-up{
    color: #25EB51;
    margin-right: 5px;
}

.dashboard .arrow-down{
    color: #ED5A6C;
    margin-right: 5px;
}

.dashboard .total-percent{
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1366px){
    .dashboard .total-percent{
        justify-content: left;
    }   
}


.dashboard .loading-screen{
    margin: 0 auto !important;
}

.dashboard .arrow-up{
    color: #25EB51;
    margin-right: 5px;
}

.dashboard .arrow-down{
    color: #ED5A6C;
    margin-right: 5px;
}

.dashboard .total-percent{
    display: flex;
    justify-content: space-evenly;
}

.dashboard .download-loading-icon{
    width: 19px;
    height: 19px;
    margin-right: 5px;
    color: #FAFAFA;
}

.dashboard .intern-redirect-container{
    margin: 30px 0px 30px 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dashboard .intern-redirect-container .redirect-container{
    width: 50%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
}

.dashboard .intern-redirect-container .start-container{
    margin-right: 10px;
}

.dashboard .intern-redirect-container .middle-container{
    margin-right: 10px;
    margin-left: 10px;
}

.dashboard .intern-redirect-container .end-container{
    margin-left: 10px;
}

.dashboard .intern-redirect-container .redirect-container .info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 10%;
}

.dashboard .intern-redirect-container .redirect-container .info-container .people-icon{
    width: 48px;
    height: 48px;
}

.dashboard .intern-redirect-container .redirect-container .info-container .info-icon{
    width: 19px;
    height: 19px;
    display: none;
}

.dashboard .intern-redirect-container .redirect-container .info-container .title-container{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.dashboard .intern-redirect-container .redirect-container .info-container .title-container h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.dashboard .intern-redirect-container  .info-container .title-container p{
    margin: 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #717171;
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon{
    margin-right: 10%;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon .pagination-icon{
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0);
}

.dashboard .intern-redirect-container .redirect-container .redirect-icon .pagination-icon:hover{
    cursor: pointer !important;
}

.dashboard .manual-btn{
    background: linear-gradient(270deg, #3DD7DA 0%, #1D8BE8 100%);
    border-radius: 9999px;
    padding: 8px 20px;
    gap: 8px;
    width: 150px;
    height: 40px;
    border: none;
}

.dashboard .manual-container{
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 45px;
    margin-left: 6px;
}

.dashboard .manual-button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    
    padding: 0;
}

.dashboard .manual-container .manual-button-container .manual-btn .manual-icon{
    width: 21px;
    height: 24px;
    margin-right: 3px;
}

@media only screen and (max-width: 1366px){
    .dashboard .total-percent{
        justify-content: left;
    }   
}


@media only screen and (max-width: 1300px){
    .dashboard .dashboard-row .intern-status-container{
        flex-direction: column;
    }
    .dashboard .dashboard-row .intern-status-container .intern-status{
        margin: 0px!important;
    }
    
    .dashboard .dashboard-row .intern-status-container .total-interns{
        margin: 30px 0px 0px 0px !important;
    }    
}

@media only screen and (max-width: 1085px){

    .dashboard .avergare-info-container .scholarship-container{
        height: 200px;
    }
    .dashboard .avergare-info-container .scholarship-container .options-icons{
        flex-direction: row;
        margin-left: 0px !important;
        width: 100% !important;
        justify-content: flex-end;
        margin-top: 0px !important;
        height: 20px;
    }

    .dashboard .avergare-info-container .scholarship-container{
        flex-direction: column-reverse;
        padding: 10px;
    }

    .dashboard .avergare-info-container .scholarship-container .info-container{
        margin-bottom: 20px;
    }

    .dashboard .avergare-info-container .scholarship-container .options-icons .download-icon{
        margin-left: 10px;
    }
    
    .dashboard .avergare-info-container .scholarship-container .info-universities-container{
        height: 73%;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .options-university{
        height: 30%;
    }
    
}

@media only screen and (max-width: 995px){

    .dashboard .avergare-info-container .scholarship-container{
        height: 240px;
    }

    .dashboard .avergare-info-container .scholarship-container .info-container{
        flex-direction: column-reverse;
        padding: 10px;
        justify-content: center;
        margin-bottom: 0px;
        align-content: center;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .info-universities-container{
        height: 85%;
        width: 100%;
    }

    .dashboard .avergare-info-container .scholarship-container .options-university{
        height: 15%;
    }

    .dashboard .intern-redirect-container{
        flex-direction: column;
        margin-top: 0;
    }

    .dashboard .intern-redirect-container .redirect-container{
        width: 100%;
        margin: 0;
    }

    .dashboard .intern-redirect-container .start-container{
        margin: 20px 0px 0px 0px;
    }

    .dashboard .intern-redirect-container .middle-container{
        margin: 30px 0px 30px 0px;
    }

    .dashboard .intern-redirect-container .end-finish{
        margin: 30px 0px 0px 0px;
    }

}

@media only screen and (max-width: 1280px){
    .doughnut-container{
        flex-direction: column
    }
}
