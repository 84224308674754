
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700, 500");

:root {
--black: rgba(0, 0, 0, 1);
--deep-sapphire: rgba(8, 46, 109, 1) ;
--bunker: rgba(17, 18, 19, 1) ;
--mountain-mist: rgba(147, 149, 152, 1) ;
--silver: rgba(192, 192, 192, 1) ;
--quill-gray: rgba(211, 211, 211, 1) ;
--carnation: rgba(237, 90, 108, 1) ;
--alabaster: rgba(250, 250, 250, 1) ;
--white: rgba(255, 255, 255, 1) ;
--font-size-xxs: 7px;
--font-size-xs: 8px ;
--font-size-s: 9px;
--font-size-m: 10px;
--font-size-l: 12px;
--font-family-roboto: "Roboto";
} 

/* Classes */
.roboto-bold-black-8px {
    color: var(--black) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.border-1px-black {
    border-width: 1px;
    border-style: solid;
    border-color: var(--black) ;
}

.roboto-bold-black-10px {
    color: var(--black) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-bold-black-10px-2 {
    color: var(--black) !important;
    font-family: var(--font-family-roboto) !important;
    font-size: 9.1px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    margin-top: -7px !important;
    margin-bottom: -5px !important;
    margin-left: -1px !important;
}

.roboto-normal-black-8px {
    color: var(--black) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.border-1px-silver {
    border-width: 1px;
    border-style: solid;
    border-color: var(--silver) ;
}

.roboto-medium-mountain-mist-10px {
    color: var(--mountain-mist) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 500 !important;
    font-style: normal !important;
}

.roboto-normal-white-10px {
    color: var(--white) !important;
    font-family:var(--font-family-roboto) !important;
    font-size:var(--font-size-m) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.roboto-bold-bunker-10px {
    color: var(--bunker) !important;
    font-family: var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-normal-black-10px {
    color: var(--black) !important;
    font-family: var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}


.roboto-bold-alabaster-10px {
    color: var(--alabaster) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-bold-carnation-10px {
    color: var(--carnation) !important;
    font-family:var(--font-family-roboto) !important;
    font-size: var(--font-size-m) !important;
    font-weight: 700 !important;
    font-style: normal !important;
}

.roboto-normal-black-7px{
    color: var(--black) !important;
    font-family: var(--font-family-roboto) !important;
    font-size:var(--font-size-xxs) !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

.factura{
    align-items: center;
    background-color: var(--white) ;
    display: flex;
    flex-direction: column;
    height: 820px;
    padding: 15px 0;
    width: 612px;
}

.flex-row{
    align-items: flex-start;
    display: flex;
    height: 99px;
    margin-left: 12px;
    min-width: 560px;
}

.flex-row-1 {
    align-items: center;
    display: flex;
    height: 98px;
    min-width: 393px;
}

.logo {
    height: 70px;
    margin-bottom: 6px;
    object-fit: cover;
    width: 70px;
}

.flex-col{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 13px;
    min-height: 98px;
    width: 310px;
}

.flex-row-2 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    min-width: 262px;
}

.emisor {
    align-self: flex-end;
    letter-spacing:0;
    min-height: 12px;
    min-width: 35px;
}

.documento-no-oficial{
    color: var(--carnation);
    font-family:var(--font-family-roboto);
    font-size: var(--font-size-l) ;
    font-weight: 700;
    letter-spacing: 0;
    margin-left: 86px;
    min-height: 14px;
}

.flex-col-item{
    letter-spacing: 0;
    min-height: 12px;
}

.rfc-cit4402264-p8 {
letter-spacing:0;
margin-top: 5px;
min-height: 12px;
}

.overlap-group4 {
height: 25px;
position: relative;
width: 150px;
}

.col-obrera {
left: 0;
letter-spacing:0;
position: absolute;
top: 0;
}

.x64010-monterrey-nuevo-len {
left: 0;
letter-spacing:0;
position: absolute;
top: 13px;
}

.flex-col-1 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    min-height: 88px;
    width: 143px;
}
.field-4 {
    align-items: flex-start;
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    min-width: 145px;
    padding: 1px 5px;
}

.factura-1 {
    letter-spacing: 0;
    min-height: 12px;
    text-align: center;
    width: 135px;
}

.field-container{
    align-items: flex-start;
    display: flex;
    margin-top: 2px;
    min-width: 145px;
}

.field {
    align-items: flex-start;
    display: flex;
    height: 16px;
    min-width: 62px;
    padding: 1px 5px;
}

.serie,
.folio,
.fecha,
.hora,
.moneda {
    letter-spacing: 0;
    min-height: 12px;
    text-align: right;
    width: 52px;
}

.field-1 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    margin-left: 2px;
    min-width: 80px;
    padding: 1px 5px;
}

.adp,
.number,
.date,
.text-3
.mxn{
    letter-spacing:0;
    min-height: 12px;
    text-align: center;
    width: 69px;
}

.flex-col-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    min-height: 657px;
    width: 572px;
}

.flex-row-3 {
    align-items: center;
    align-self: flex-end;
    display: flex;
    min-width: 477px;
}

.rgimen-fiscal-603 {
    letter-spacing: 0;
    min-height: 12px;
    min-width: 282px;
}

.field-5 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    margin-left: 50px;
    min-width: 62px;
    padding: 1px 5px;
}

.field-6 {
    align-items: flex-start;
    align-self: flex-start;
    background-color: var(--deep-sapphire);
    display: flex;
    height: 16px;
    margin-top: 9px;
    min-width: 95px;
    padding: 1px 5px;
}
.receptor {
    letter-spacing: 0;
    min-height: 12px;
    width: 85px;
}

.frame-16 {
    align-items: center;
    display: flex;
    height: 97px;
    justify-content: flex-end;
    min-width: 572px;
}

.column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 62px;
    width: 236px;
}

.socio-15586 {
    letter-spacing:0;
    min-height: 12px;
    width: 300px;
}

.celestica-de-monterrey-sa-de-cv {
    letter-spacing:0;
    min-height: 12px;
    width: 228pX;
    margin-top: -5px !important;
    margin-bottom: -5px !important;
}

.rfc-cm0980416-b80 {
    letter-spacing:0;
    margin-top: 10px;
    min-height: 12px;
    width: 120px;
}

.observaciones{
    color: var(--black);
    font-family:var(--font-family-roboto);
    font-size:var(--font-size-s);
    font-weight: 400;
    letter-spacing:0;
    margin-top: 5px;
    min-height: 11px;
    width: 77px;
}

.column-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    min-height: 77px;
    width: 314px;
}

.ejecutivo-ninguno{
    letter-spacing:0;
    min-height: 12px;
    width: 300px;
}

.efectuar-el-pago-uti{
    letter-spacing: 0;
    margin-top: 10px;
    min-height: 9px;
    width: 182px;
}

.convenio-cie-bbva00 {
letter-spacing:0;
margin-top: 5px;
min-height: 9px;
width: 300px;
}

.referencia{
letter-spacing: 0;
margin-top: 5px;
min-height: 9px;
width: 40px;
}

.la-referencia-es-un{
letter-spacing:0;
min-height: 18px;
width: 304pX;
margin-top: 5px !important;
}

.overlap-group3 {
margin-right: 0;
margin-top: 5px;
position: relative;
width: 574px;
}

.row-1{
    align-items: flex-start;
    background-color: #f8f8f8;
    display: flex;
    height: 28px;
    left: 1px;
    min-width: 572px;
    position: absolute;
    top: 0;
}

.column-container{
    height: 30px;
    margin-left:-1px;
    margin-top: -1px;
    position: relative;
    width: 574px;
}

.column-2 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 52px;
    min-width: 62px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.clave-unidad-sat{
letter-spacing: 0;
min-height: 18px;
text-align: center;
width: 50px;
}

.column-3 {
align-items: flex-start;
display: flex;
height: 30px;
left: 112px;
min-width: 70px;
padding: 4.5px 5px;
position: absolute;
top: 0;
}

.clave-prod-servicio{
letter-spacing: 0;
min-height: 18px;
text-align: center;
width: 58px;
}

.column-4 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 180px;
    min-width: 177px;
    padding: 9px 5px;
    position: absolute;
    top: 0;
}

.descripcin{
    letter-spacing: 9;
    min-height: 9px;
    text-align: center;
    width: 165px;
}

.column-5 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 355px;
    min-width: 61px;
    padding: 2px 5px;
    position: absolute;
    top: 0;
}

.valor-unitario{
    letter-spacing:0;
    min-height: 9px;
    text-align: center;
    width: 49px;
}

.column-container-1 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 574px;
}
.column-container-2 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 87px;
    width: 574px;
}

.column-container-3 {
    height: 36px;
    left: 0;
    position: absolute;
    top: 126px;
    width: 574px;
}

.column-6 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 0;
    min-width: 54px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.text-5 {
    letter-spacing:0;
    min-height: 8px;
    width: 42px;
}

.column-7 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 52px;
    min-width: 62px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.pce-pce {
    letter-spacing: 0;
    min-height: 8px;
    width: 50px;
}

.column-8 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 112px;
    min-width: 70px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.x94131500-organizac {
    letter-spacing:0px;
    min-height: 24px;
    width: 58px;
    line-height:normal;
}

.column-9 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 180px;
    min-width: 177px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.adp-administracion-de-becas {
    letter-spacing:0;
    min-height: 8px;
    width: 165px;
}

.column-10 {
    align-items: flex-start;
    display: flex;
    height: 60px;
    left: 355px;
    min-width: 61px;
    padding: 4.5px 5px;
    position: absolute;
    top: 0;
}

.price {
letter-spacing: 0;
min-height: 8px;
text-align: right;
width: 49px;
}

.overlap-group6 {
position: relative;
width: 572px;
}

.overlap-group5 {
height: 169px;
left: 0;
position: absolute;
top: 0;
width: 572px;
}

.overlap-group5_ {
    height: 169px;
    left: 0;
    position: absolute;
    top: 40;
    width: 572px;
}

.layer-2 {
height: 160px;
left: 44pX;
position: absolute;
top: 0;
width: 485px;
}

.layer-2_ {
    height: 160px;
    left: 44pX;
    position: absolute;
    top: 40;
    width: 485px;
}

.tipo-de-relacin{
    left: 0;
    letter-spacing:0;
    position: absolute;
    top: 60px;
}

.tipo-de-relacin_{
    left: 0;
    letter-spacing:0;
    position: absolute;
    top: 100px;
}

.field-7 {
    align-items: flex-start;
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 9px;
}

.field-7_ {
    align-items: flex-start;
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 49px;
}

.factura-2 {
    letter-spacing:0;
    min-height: 12px;
    text-align: center !important;
    width: 324px;
}

.tres-mil-cuatrocient {
    letter-spacing:0;
    min-height: 12px;
    text-align: center !important;
    width: 324px;
    margin-top: -5px !important;
}

.field-8 {
    align-items: flex-start;
    display: flex;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 25px;
}

.field-8_ {
    align-items: flex-start;
    display: flex;
    left: 0;
    min-width: 334px;
    padding: 1px 5px;
    position: absolute;
    top: 65px;
}

.phone{
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 10px;
    width: 85px;
}

.phone_{
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 50px;
    width: 85px;
}

.text-2 {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 28px;
    width: 85px;
}

.text-2_ {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 68px;
    width: 85px;
}

.phone-1 {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 46px;
    width: 85px;
}

.phone-1_ {
    left: 482px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 86px;
    width: 85px;
}

.text-4 {
    left: 482px;
    letter-spacing: 9;
    position: absolute;
    text-align: right;
    top: 64px;
    width: 85px;
}

.text-4_ {
    left: 482px;
    letter-spacing: 9;
    position: absolute;
    text-align: right;
    top: 104px;
    width: 85px;
}

.field-9 {
    align-items: flex-start;
    display: flex;
    height: 16px;
    left: 477px;
    min-width: 95px;
    padding: 1px 5px;
    position: absolute;
    top: 81px;
}

.field-9_ {
    align-items: flex-start;
    display: flex;
    height: 16px;
    left: 479px;
    min-width: 95px;
    padding: 1px 5px;
    position: absolute;
    top: 121px;
}

.price-1{
    letter-spacing:0;
    min-height: 12px;
    text-align: right;
    width: 85px;
}

.price-1_{
    letter-spacing:0;
    min-height: 12px;
    text-align: right;
    width: 85px;
}

.mtodo-de-pago {
    left: 298px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 106px;
}

.mtodo-de-pago_ {
    left: 298px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 136px;
}


.forma-de-pago {
    left: 303px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 123px;
}

.forma-de-pago_ {
    left: 303px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 153px;
}

.uso-cfdi{
    left: 326px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 140px;
}

.uso-cfdi_{
    left: 326px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 170px;
}

.serie-del-certificado-del-emisor {
left: 233px;
letter-spacing:0;
position: absolute;
text-align: right;
top: 157px;
}

.serie-del-certificado-del-emisor_ {
    left: 233px;
    letter-spacing:0;
    position: absolute;
    text-align: right;
    top: 187px;
}

.ppd-pago-en-parcialidades-o-diferido{
left: 381px;
letter-spacing:0;
position: absolute;
top: 106px;
}


.ppd-pago-en-parcialidades-o-diferido_{
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 136px;
}

.×99-por-definir {
left: 381px;
letter-spacing:0;
position: absolute;
top: 123px;
}

.×99-por-definir_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 153px;
}

.p01-por-definir {
left: 381px;
letter-spacing:0;
position: absolute;
top: 140px;
}

.p01-por-definir_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 170px;
}

.no-se-puede-agregar-firma-digital-a-un {
left: 381px;
letter-spacing:0;
position: absolute;
top: 157px;
}

.no-se-puede-agregar-firma-digital-a-un_ {
    left: 381px;
    letter-spacing:0;
    position: absolute;
    top: 187px;
}

.text-1 {
left: 0;
letter-spacing: 9;
position: absolute;
top: 77pX;
}

.text-1_ {
    left: 0;
    letter-spacing: 9;
    position: absolute;
    top: 110pX;
}

.flex-row-4 {
align-items: flex-start;
display: flex;
height: 12px;
margin-left: 129px;
margin-top: 5px;
min-width: 67px;
}

.folio-fiscal{
letter-spacing: 0;
min-height: 12px;
min-width: 54px;
text-align: right;
}

.folio-fiscal_{
    letter-spacing: 0;
    min-height: 12px;
    min-width: 54px;
    text-align: right;
    margin-top: 15px;
}

.text-6,
.no-se-puede-agregar-firma-digital-a-un-1,
.text-7 {
letter-spacing:0;
margin-left: 10px;
min-height: 12px;
}

.text-6_{
    margin-top: 15px !important;
}
.text-6_,
.no-se-puede-agregar-firma-digital-a-un-1_,
.text-7_{
letter-spacing:0;
margin-left: 10px;
min-height: 12px;
margin-top : 10px;
}

.flex-row-5 {
align-items: flex-start;
align-self: flex-end;
display: flex;
height: 12px;
margin-right:21px;
margin-top: 5px;
min-width: 343px;
}

.no-de-serio-del-certificado-del-sat {
letter-spacing:0;
min-height: 12px;
min-width: 163px;
text-align: right;
}

.no-de-serio-del-certificado-del-sat_ {
    letter-spacing:0;
    min-height: 12px;
    min-width: 163px;
    text-align: right;
    margin-top: 10px;
}

.flex-row-6 {
align-items: flex-start;
display: flex;
height: 12px;
margin-left: 50px;
margin-top: 5px;
min-width: 146px;
}

.fecha-y-hora-de-certificacin{
    letter-spacing:0;
    min-height: 12px;
    min-width: 133px;
    text-align: right;
}

.fecha-y-hora-de-certificacin_{
    letter-spacing:0;
    min-height: 12px;
    min-width: 133px;
    text-align: right;
    margin-top: 10px;
}

.este-documento-es-u {
    letter-spacing:0;
    margin-top: 20px;
    min-height: 12px;
    min-width: 272px;
    text-align: center;
}

.este-comprobante-no{
    letter-spacing:0;
    min-height: 9px;
    min-width: 476px;
    text-align: center;
}

.field-container-1 {
    height: 49px;
    margin-top: 20px;
    position: relative;
    width: 574px;
}

.field-2 {
align-items: flex-start;
background-color:var(--quill-gray);
display: flex;
height: 18px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 0;
}

.sello-digital-del-cfdi,
.sello-del-sat,
.cadena-original-del {
letter-spacing:0;
min-height: 12px;
text-align: center;
width: 562px;
}

.field-3 {
align-items: flex-start;
background-color: var(--white) ;
display: flex;
height: 33px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 16px;
}

.no-se-puede-agregar { 
letter-spacing:0;
min-height: 27px;
width: 562px;
}

.field-container-2 {
height: 49px;
margin-top: 3px;
position: relative;
width: 574px;
}

.field-container-3 {
height: 67px;
margin-top: 3px;
position: relative;
width: 574px;
}

.field-10 {
align-items: flex-start;
background-color: var(--white) ;
display: flex;
height: 51px;
left: 0;
min-width: 574px;
padding: 1.5px 5px;
position: absolute;
top: 16px;
}

.no-se-puede-agregar-1 {
    letter-spacing:0;
    min-height: 45px;
    width: 562px;
}

.column-11 {
    align-items: flex-start;
    display: flex;
    height: 30px;
    left: 0;
    min-width: 54px;
    padding: 9px 5px;
    position: absolute;
    top: 0;
}

.cantidad {
    letter-spacing:0;
    min-height: 9px;
    text-align: center;
    width: 42px;
}

.column-11.column-12 {
    left: 414px;
    min-width: 55px;
}

.column-11.column-12 .cantidad,
.column-11.column-13 .cantidad,
.column-11.column-14 .cantidad {
width: 43px;
}

.column-11.column-13 {
left: 467px;
min-width: 55px;
}

.column-11.column-14 {
left: 519px;
min-width: 55px;
}

.column-15 {
align-items: flex-start;
display: flex;
height:60px;
left: 414px;
min-width: 55px;
padding: 4.5px 5px;
position: absolute;
top: 0;
}

.price-2 {
letter-spacing: 0;
min-height: 8px;
text-align: right;
width: 43px;
}

.column-15.column-16 {
left: 467px;
}

.column-15.column-17 {
left: 519px;
}

.field-11 {
align-items: flex-start;
background-color:var(--deep-sapphire);
display: flex;
height: 16px;
left: 381px;
min-width: 94px;
padding: 1px 5px;
position: absolute;
top: 9px;
}

.field-11_ {
    align-items: flex-start;
    background-color:var(--deep-sapphire);
    display: flex;
    height: 16px;
    left: 381px;
    min-width: 94px;
    padding: 1px 5px;
    position: absolute;
    top: 50px;
}

.subtotal {
letter-spacing:0;
min-height: 12px;
text-align: right;
width: 84px;
}

.field-11.field-11_ {
    top: 50x;
}

.field-11.field-12 {
top: 27px;
}

.field-11.field-12_ {
    top: 67px;
}

.field-11.field-13 {
    top: 45px;
}

.field-11.field-13_ {
    top: 85px;
}

.field-11.field-14 {
    top: 63px;
}


.field-11.field-14_ {
    top: 103px;
}


.field-11.field-15 {
    top: 81px;
} 

.field-11.field-15_ {
    top: 121px;
} 

.verify-preview{
    margin-top: 30px;
}

.zoom-div{
    overflow: hidden;
    bottom: 10px;
    position: fixed;
    left: 48%;
}

.zoom{
    transform: scale(1.5);
    color: white;
    background-color: black;
    margin: 5px;
}