.documentation-request{
    width: inherit;
    height: inherit;
    font-family: "Mulish";
}

.documentation-request .background-container{
    background-image: url("../../assets//background-girl.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.documentation-request .form-card{
    background-color: rgba(255, 255, 255, 0.96);
    padding: 20px;
    border-radius: 5px;
    height: 90vh;
    overflow-y: auto;
}

.documentation-request form{
    width: 70%;
    margin: auto;
}

.documentation-request select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.documentation-request h4{
    color: #1B3769;
    margin: auto;
    padding-bottom: 12px;
}

.documentation-request small{
    color: #1B3769;
    margin: auto;
}

.documentation-request .InternsLogo {
    width: 150px;
    height: auto;
}

.documentation-request .logoCaintra {
    width: 100px;
    height: auto;
}

.documentation-request .text-container{
    margin-top: 30px;
}

.documentation-request .text-container a{
    color: rgba(255, 255, 255, 0.8);
    margin-left: 25%;
    font-weight: 100;
}

.documentation-request input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.documentation-request .form-label{
    color: black;
    font-size: 15px;
}

.documentation-request .row-logo{
    margin: 25px 5px 5px 5px;
}

.documentation-request .row-contact{
    text-align: center;
    margin-top: 5%;
}

.documentation-request .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.documentation-request .row-selection{
    display:flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    padding-top: 40%;
}

.documentation-request .row-selection a{
    color: black;
    font-size: 25px;
    margin-right: 5%;
}

.documentation-request .row-selection a small{
    color: black;
    font-size: 15px;
    margin-left: -10px;
}

.documentation-request .files{
    background-color: #eceef1!important;
    height: 140px !important;
    border-radius: 20px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
}

.documentation-request .files .divcard{
    max-width: 90% !important;
    margin: 0 auto;
}

.documentation-request .files .card-title{
    width: 90% !important;
    margin: 0 auto;
}


.documentation-request .files .upload-container{
    width: 90% !important;
    text-align: center !important;
    color: white;
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    outline-style: none;
    padding: 0;
    padding-top: 6px;
    border-radius: 25px;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    opacity: 0.8;
    margin: 0 auto !important;   
}

.files .header-container{
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 5px;
    padding-bottom: 15px;
    display: flex;
    width: 90%;
    justify-content: space-between;
}


.documentation-request .files a{
    display: flex;
    justify-content:  space-between;
    flex-wrap: wrap;
}

.documentation-request .files svg{
    margin: 3px 3px 0 0;
}

.documentation-request .files small{
    margin: 0;
}

.documentation-request .form-file-label:hover{
    cursor: pointer;
}

.documentation-request .form-file{
    width: 100% !important;
    height: 100% !important;
}

.documentation-request .form-file-label{
    width: 100% !important;
    height: 100% !important;
}


.documentation-request .form-control-file{
    display: none;
}

.documentation-request input[type="checkbox"]{
    height: auto;
}

.documentation-request .success-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.success-modal .modal-header{
    display: flex;
    flex-direction: column;
}

.success-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.success-modal .modal-body svg{
    color: #6ce090;
}

.success-modal .modal-body h2{
    color: #1B3769;
    margin-bottom: 15px;
    font-weight: bold;
}

.success-modal .modal-body p{
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: #1B3769;
}

.no-margin{
    margin: 0 !important;
}
.rw-widget > .rw-widget-container  {
    border: none !important;
}
.rw-datetime-picker input{
    border-radius: 10px 0px 0px 10px !important;
}
.rw-select-bordered{
    border: none;
    background-color: #eceef1;
}
.rw-widget-input, .rw-filter-input {
    box-shadow: none !important;
}
@media only screen and (min-width: 1920px) {
    .documentation-request .row-selection{
        margin-bottom: 10%;
    }    

    .documentation-request input{
        height: 50px;
    }

    .documentation-request select{
        height: 50px;
    }

    .documentation-request .btn-login{
        margin-top: 10%;
        font-size: 18px;
    }

    .documentation-request .row-contact{
        padding-top: 30px;
    }
}

@media only screen and (min-width: 1366px) {
    .documentation-request .row-contact{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .documentation-request .row-selection{
        padding-top: 0;
        margin-top: 15%;
        margin-bottom: 20%;
    }
    .documentation-request .row-selection a{
        font-size: 20px;
    }
}

@media only screen and (max-width: 1140px) {
    .documentation-request .files{
        min-height: 175px;
    }
}