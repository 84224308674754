.interns {
    width: 100%;
    min-height: 85vh !important;
    font-family: "Mulish", 'Verdana', 'Tahoma';
}

.interns .h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}

.interns .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.interns #addIntern{
    float: left;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 30px;
    font-weight:100;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    cursor: pointer;
}
.interns #addIntern svg {
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}

.interns .title-search-bar{
    display: flex;
    flex-direction: row;
}

.interns .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.interns .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.interns .search{
    float: left;
    margin-left: 15px;
}

.interns .check{
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.interns .search-container{
    width: 400px !important;
    margin: 0;
    float: left;
}

.interns .search-container-global{
    width: 200px !important;
    margin: 0;
    float: left;
}

.interns .search-btn{
    width: 50px !important;
    margin: 0 auto;
    height: 100%;
}

.interns .search-container-global input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0;
}
.interns .search-container-global input:focus{
    outline: none !important;
    box-shadow: none;
}

.interns .search-icon{
    cursor: pointer;
    float: left;
    height:50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #666;
    border-radius: 25px 0 0 25px;
    width: 47px;
    font-size: 28px;
    padding-left: 16px;
    border: none;
}
.interns .search-icon:hover{
    color: darkgreen;
}
.interns .search-container input{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
    margin: 0;
}
.interns .search-container input:focus{
    outline: none !important;
    box-shadow: none;
}

.clearfix { overflow: auto;  display: inline-block; }
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.interns .alter-table .loadCSV{
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 15px;
    border-radius: 25px;
    height: 50px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
    font-size: 1.1em;
    font-weight:100;
    font-weight: 600;
    box-shadow: 0 0 5px #aaa;
    cursor: pointer;
}
.interns .alter-table .loadCSV svg{
    color: white;
    margin-right: 8px;
    font-size: 1.3em;
}

.interns .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
    margin-right: 20px;
}

.interns .search-bar-container svg{
    font-size: 25px;
    margin-top: 7px;
    color: #1B3769;
}

.interns .search-bar-container svg:hover{
    color: rgb(46, 243, 41);
}

.interns .search-bar-container div.col-md-12{
    padding: 0;
    margin: 0;
}

.interns .search-bar-container .show{
    transform: translate(0px, 0px);
}

.interns input{
    background-color: #eceef1;
    border-radius: 10px;
}
.time-picker{
    background-color: #eceef1 !important;
    border-radius: 10px !important;
    font-weight: bolder !important;
}
.pop-up{
    border-radius: 10px !important;
    background-color: rgb(41, 190, 38) !important;
}
.interns select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.interns label{
    font-weight: bold;
}

.interns .btn {
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.interns .form-label{
    color: black;
    font-size: 15px;
}

.interns .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.interns .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-intern form{
    width: 95%;
}

.checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.new-intern .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.new-intern .title-container svg{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 20px;
    cursor: pointer;
}

.new-intern h4{
    display: inline-block;
    color: #222 !important;
    font-size: 2em;
}

.new-intern a{
    color: #1B3769;
}

.new-intern label{
    color: #333 !important;
    margin: 0 0 1px 10px;
    font-weight: 600 !important;
}

.new-intern .input, .new-intern input, .new-intern .rw-widget-container{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
}

.new-intern .btn-container .btn{
    display: inline-block;
    border-radius: 20px;
    font-weight: 600;
}
.new-intern .btn-container .btn-cancel{
    background: white !important;
    color: #eb697b !important;
    border: 1px solid #eb697b !important;
    box-shadow: none !important;
    margin-right: 10px !important;
}

.new-intern .btn-container .btn-confirm{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}

.unsuscribe-intern-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.unsuscribe-intern-modal .modal-content{
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.85);
}

.unsuscribe-intern-modal .modal-header{
    text-align: left;
    border: none;
    color: black;
    padding-top: 25px;
}

.unsuscribe-intern-modal .description-input, .unsuscribe-intern-modal select, .unsuscribe-intern-modal .rw-widget-picker{
    border: 1px solid #ddd !important;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 3px 3px 3px #ccc;
    width: 100%;
    height: 60px;
}

.unsuscribe-intern-modal .btn-container .col{
    margin-top: 20px;
}
.unsuscribe-intern-modal .btn{
    display: inline-block !important;
    margin: 0 !important;
    border-radius: 20px !important;
    width: 100%;
}
.unsuscribe-intern-modal .cancel-btn{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    margin-right: 5px !important;
}
.unsuscribe-intern-modal .confirm-btn{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}
.unsuscribe-intern-modal .delete-btn{
    background: rgb(238,120,134) !important;
    background: linear-gradient(90deg, rgba(238,120,134,1) 0%, rgba(234,134,125,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
}

.unsuscribe-intern-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.unsuscribe-intern-modal small{
    color: #1B3769;
    margin: auto;
}

.unsuscribe-intern-modal label{
    font-size: 14.5px;
}


.verify-interns-docs .form-overflow {
    height: 75vh;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.verify-interns-docs .profile-picture{
    width: 150px;
    height: 150px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}

.verify-interns-docs .profile-photo-camera{
    position: absolute;
    right: -9px !important;
    top: -8px;
}

.profile-photo svg{
    color: rgb(7,210,204);
}

.verify-interns-docs .form-control-file{
    display: none;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.verify-interns-docs .profile-picture svg:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs .row-selection a{
    margin-right: 10px;
}

.verify-interns-docs .row-selection a:hover{
    color: black;
}

.verify-interns-docs .row-selection .selected{
    color: #00adee;
    font-weight: bold;
}

.verify-interns-docs .row-selection p{
    color: black;
    font-weight: lighter;
    font-size: 20px;
}

.verify-interns-docs a{
    color: #1B3769;
}

.verify-interns-docs a:hover{
    color: rgb(46, 243, 41);
}

.verify-interns-docs svg{
    font-size: 20px;
    margin: 8px 5px 0 0;
}

.verify-interns-docs h6{
    font-weight: bold;
    font-size: medium;
    margin: 0 0 15px 0;
    color: #1B3769;
}

.verify-interns-docs .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.verify-interns-docs .modal-header{
    display: flex;
    flex-direction: column;
}

.verify-interns-docs p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}


.verify-interns-docs small{
    color: #1B3769;
    margin: auto;
}

.verify-interns-docs input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.verify-interns-docs .form-label{
    color: black;
    font-size: 15px;
}

.verify-interns-docs .form-file-label{
    color: #1B3769;
}

.verify-interns-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.verify-interns-docs .rw-widget-container {
    border: none;
    background-color: #eceef1;
    border-radius: 10px;
}

.verify-interns-docs .rw-widget-container input {
    border: none;
    box-shadow: none;
}

.new-cc-modal .modal-content{
    background-color: rgba(250, 250, 250, 0.9);
    box-shadow: 0px 0px 8px 1px rgba(18, 18, 19, 0.15);
    border-radius: 20px;
    color: #535A63 !important;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.new-cc-modal .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.modal-header-styled{
    font-weight: 700;
    color: #121213;
    border: none !important;
    margin: 5px 5px 15px 5px;
    font-size: 18px !important;
}

.new-cc-modal p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.input-cc{
    background: #FAFAFA;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #FAFAFA;
    border-radius: 15px !important;
    height: 50px !important;
}

.new-cc-modal .form-check input[type=checkbox]{
    width: 16px;
    height:  16px;
}

.center-buttons{
    margin: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}


.new-cc-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 25px !important;
    margin: auto;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40% !important;
}

.new-cc-modal .btn-danger{
    background-image: linear-gradient(to right, white 0.1%, gray 100%);
    border: none;
    border-radius: 25px !important;
    margin: auto;
    box-shadow: 0px 0px 4px rgba(18, 18, 19, 0.15) !important;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40% !important;
}

.new-cc-modal label{
    font-weight: bold;
    font-size: 14.5px;
}

.interns-list{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 65vh !important;
}

.interns-list .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.interns-list .options a{
    color: #1B3769;
    margin: 0 0 0 3px;
}
.interns-list-options .status-filter{
    color: rgb(245, 245, 245);
    padding: 3px;
    margin: 0px;
    border-radius: 20px;
}

.interns-list .options a:hover{
    color: rgb(46, 243, 41);
}

.interns-list .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    width: 100%;
    height: 75vh;
    margin-bottom: 30px !important;
}

.interns-list .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 250px;
    height: 260px;
    box-shadow: 3px 5px 5px #d7d7d7;
}

.interns-list .card .card-body .profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    position: relative;
    margin: 10px;
}

.interns-list .card .card-body .profile-photo .profile-photo-camera {
    position: absolute;
    right: -8px;
    top: -10px;
    font-size: 22px;
}

.interns-list .card .form-control-file{
    display: none;
}

.interns-list .card .form-file-label{
    color: #1B3769;
    cursor: pointer;
}

.interns-list .card .form-file-label:hover{
    color: rgb(46, 243, 41);
}

.interns-list .card .card-body .card-options svg:hover{
    color: rgb(46, 243, 41);
}

.interns-list .pagination-container{
    display: flex;
    flex-direction: row;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 0 0 0;
    float: right;
    padding: 0;
}

.interns-list .pagination-container li{
    border: none !important;
}

.interns-list .pagination-container li a{
    margin: 10px;
}

.interns-list .pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.interns-list .pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.interns-list .pagination-container .selected{
    font-weight: bolder;
    color: white;
}

.interns-list-options {
    display: flex;
    flex-direction: row;
    margin-right: 10px !important;
}

.interns-list-options p{
    margin: 18px 0 0 0;
    color: #1B3769;
}

.interns .intern-list-table{
    max-height: 65vh;
    margin-top: 15px;
}

.interns-list-options .rw-dropdown-list{
    width: 75px;
    margin: 10px 10px 0 10px;
}

.rotate{
    animation: rotate 1.5s linear infinite; 
}

.manage-departments a{
    color: #1B3769;
    font-size: 18px;
    margin: 0 0 0 5px;
}

.manage-departments .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.manage-departments .title a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 20px;
}

.manage-departments  a:hover{
    color: rgb(46, 243, 41);
}

.manage-departments .title svg{
    margin: 8px 5px 0 0;
}

.manage-departments p{
    font-weight: bold;
    font-size: 14px;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.manage-departments .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
}

.manage-departments button {
    background: none;
    background-color: transparent;
    border: 0;
}

.scroll{
    max-height: 65vh !important;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden !important;
    margin-bottom: 100px;
}

.react-time-picker {
    display: inline-flex;
    position: relative;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    height: 38px;
    width: 200px;
  }
  .react-time-picker__inputGroup {
    min-width: calc((6px * 3) +  0.70em * 6  +  0.217em * 4);
    flex-grow: 1;
    box-sizing: content-box;
  }
  .react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-time-picker__inputGroup__input {
    min-width: 0.74em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    padding-left: 12px;
    padding-right: 12px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px +  0.54em);
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-time-picker__button svg {
    display: inherit;
  }

  .center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

.filter-button{
    text-decoration: none !important;
    color: #1B3769;
}
.filter-button:hover{
    cursor: pointer !important;
    color: rgb(41, 190, 38);
}

.interns-list{
    width: 97%;
}

.interns-list #table{
    margin: 30px 0 !important;
}

.interns-list #table{
    margin: 0px !important;
}

.table-container-list .table-header{
    margin: 0;
    margin-bottom: 0px !important;
}
.table-container-list #table{
    margin: 0 !important;
}

.interns-list .table-header .col{
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #555;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.interns-list .table-header .col span{
    color: black;
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 5px;
}
.span-text{
    color: black;
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 5px
}

.interns-list .table-header a{
    color: #555;
}

.interns-list .table-content .row{
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
    margin: 0;
    margin-bottom: 5px !important;
    padding: 10px 0;
}

.interns-list .table-content .profile-photo{
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 0;
    margin-right: 10px;
}

.interns-list .table-content .col{
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.table-container-list .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em !important;
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
    cursor: pointer !important;
}
.table-container-list  .dropdown-toggle:active, .interns-list .table-content .dropdown-toggle:focus{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.table-container-list  .dropdown-toggle::after{
    display: none;
}

.table-container-list  .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.table-container-list  .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.table-container-list  .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.table-container-list  .no-select{
    color: #eb697b;
    cursor: default;
}
.table-container-list  .no-select:hover,
.table-container-list  .no-select:active,
.table-container-list  .no-select:focus
{
    color: #eb697b;
    cursor: default;
    background: transparent !important;
}

.table-container-list .dropdown-item .row-in-drop,
.table-container-list .dropdown-item .row-in-drop .col{
    margin: 0;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
}

.table-container-list  .dropdown-item .row-in-drop .icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
}

.table-container-list .dropdown-item .row-in-drop .col svg{
    margin: 0 5px;
}

.table-container-list-interns{
    height: 60vh !important;
}

.verify-interns-docs .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.verify-interns-docs .table-container {
    overflow-x: scroll !important;
    display: inline-block;
    width: 95%;
    margin-top: 30px;
}

.verify-interns-docs .table{
    width: 100% !important;
    border: none;
    margin: 0;
    border-collapse:separate;
    border-spacing:0 10px;
}

.verify-interns-docs .table thead, .table th{
    border: none !important;
    background: transparent !important;
    color: #666 !important;
    font-size: 1em !important;
    font-weight: bold;
}

.center-text{
    text-align: center !important;
}

.document-name{
    margin: 0 auto !important;
}

.verify-interns-docs .table tbody tr{
    background: rgba(255, 255, 255, 0.6)!important;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.10);
    border-radius: 5px; 
}

.verify-interns-docs .table tbody tr td:first-child { border-top-left-radius: 5px; }
.verify-interns-docs .table tbody tr td:last-child { border-top-right-radius: 5px; }
.verify-interns-docs .table tbody tr td:first-child { border-bottom-left-radius: 5px; }
.verify-interns-docs .table tbody tr td:last-child { border-bottom-right-radius: 5px; }

.verify-interns-docs .table thead tr th{
    border: none;
}

.verify-interns-docs .table tbody tr td{
    vertical-align: middle;
    color: black;
    font-size: 1em;
    text-align: left;
    border: none;
    max-width: 100%;
    white-space: nowrap;
}

.verify-interns-docs .table tbody tr td svg{
    font-size: 18px;
}

.verify-interns-docs .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.verify-interns-docs .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.on-click-event{
    cursor: pointer
}
.on-click-event:hover{
    cursor: pointer
}

.table-container-list {
    overflow-x: scroll !important;
    display: inline-block;
    width: 100%;
    min-height: 65vh !important;
}
.table-container-list::-webkit-scrollbar {
    height: 12px
}

.th-text{
    text-align: left !important;
}

.profile-picture-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
}

.department-text{
    color: #0025E5 !important;
    font-size: 15px !important;
    font-weight: bold;
    margin-right: 5px;
    margin: 0px 5px 0px 0px !important;
    padding: 0 !important;
}

.add-deparment-containter{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.center-modal-text{
    display: flex ;
    justify-content: center;
}

.circle-div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0;
}

.center-status-signature{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.all-signatures {
    background: linear-gradient(to right, #06D1CD 0, #25EB51 33%, #E4D270 33%, #E4D270 66%, #ED5A6C 66%, #E86C60 100%) !important;
}
.signed-signatures {
    background: linear-gradient(90deg, #06D1CD 0%, #25EB51 100%) !important;
}
.pending-signatures{
    background: #E4D270 !important;
}

.red-signatures{
    background: linear-gradient(90deg, #ED5A6C 0%, #E86C60 100%) !important;
}

.search-container-interns{
    display: flex !important;
    flex-wrap: wrap;
}

.mt-15{
    margin-top: 15px !important;
}

.reload-table-interns{
    margin-left: 10px;
    display: inline-flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
    margin-right: 20px;
}

@keyframes rotate{
    to{ transform: rotate(360deg); }
}



@media only screen and (max-width: 1484px){
    .table-container-list-interns{
        min-height: 55vh !important;
        height: 45vh !important;
    }

    .interns .intern-list-table{
        max-height: 55vh;
        margin-top: 15px;
    }

    .interns .interns-list{
        min-height: 60vh !important;
    }
}


@media only screen and (max-width: 1620px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(4, 1fr);
        height: 100% !important;
        margin-bottom: 150px !important;
    }
}

@media only screen and (max-width: 1337px) {
    .manage-departments .grid {
        grid-template-columns: repeat(2, 1fr);
        height: 100% !important;
        margin-bottom: 50px !important;
    }
}

@media only screen and (max-width: 1300px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(3, 1fr);
        height: 100% !important;
        margin-bottom: 30px !important;
    }
}


.training-intern-modal>.modal-dialog{
    
}

@media only screen and (max-width: 991px) {
    .verify-interns-docs .modal-dialog-centered{
        max-width: 95%;
        width: 100%;
    }
}

@media only screen and (max-width: 845px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(2, 1fr);
        height: 100% !important;
        margin-bottom: 20px !important;
    }

    .interns-list{
        height: 68vh;
    }
}

@media only screen and (max-width: 540px){
    .interns-list .cards-grid{
        grid-template-columns: repeat(1, 1fr);
        height: 100% !important;
        margin-bottom: 10px !important;
    }
}

.video-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

