.pending{
    width: 100%;
    height: 85vh;
}

.pending .title {
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.pending h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: black;
    margin-bottom: 20px;
}

.pending .alter-table{
    display: flex;
    align-items: center;
}

.pending .alter-table .disperse-button{
    display: inline-flex;
    align-items: center;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
}
.pending .alter-table .csv-button{
    display: inline-flex;
    align-items: center;
    color: #666;
    background:rgba(255, 255, 255, 0.3);
    border: none;
    height: 50px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 0 0 5px #999;
}

.pending .alter-table .disperse-button svg,
.pending .alter-table .csv-button svg{
    margin-right: 8px;
    font-size: 25px;
}
.pending .alter-table .dd-wrapper{
    display: inline-block;
}
.pending .alter-table .dd-header{
    height: 50px;
    background:rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px #999;
    color: #666;
    padding: 0 20px;
    margin-right: 20px;
}

.pending .options input,
.pending .options select,
.pending .options .rw-widget-container,
.pending .options .rw-select{
    background:rgba(255, 255, 255, 0.3) !important;
}

.pending .options input,
.pending .options select{
    height: 50px;
}

.pending .main-table{
    width: 97%;
    margin-top: 30px;
}
.pending .main-table .row{
    margin-left: 0;
    color: #666;

}
.pending .main-table .table-body{
    box-shadow: 0 0 5px #aaa;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    color: black;
    font-weight: 500;
    height: 70px;
    border-radius: 10px;
    margin-top: 10px;
}

.pending .title .default-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    margin: 5px 0px 5px 1rem;
}

.pending .title .default-button svg{
    font-size: 10px;
    margin-right: 5px;
}

.pending .title .filters-menu{
    width: 90%;
}

.pending .table-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 1rem;
    overflow-y: auto;
    box-shadow: -2px 5px 5px rgba(215, 215, 215, 0.5);
}

.pending .table{
    border: none;
    margin: 0;
}

.pending .table thead, .table th{
    border: none;
    border-color: transparent;
    text-align: center;
    background-color: #E9EDF3;
    color: #1B3769;
    font-size: 14px;
}

.pending .table thead tr th{
    border-top: none;
}

.pending .table thead tr th:first-child{
    border-top-left-radius: 10px;
}

.pending .table thead tr th:last-child{
    border-top-right-radius: 10px;
}

.pending .table tbody tr td{
    color: black;
    font-size: 14px;
    text-align: center;
    border: none;
}

.pending .table tbody tr td svg{
    font-size: 12px;
}

.pending .table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.pending .table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.pending .options{
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0.5rem 0;
}

.pending .options > *{
    margin: 0 15px 0 0;
}

.pending .form-group{
    margin-top: 20px;
}

.pending label{
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
}

.pending input[type=number]{
    background-color: #eceef1;
    border: none;
    border-radius: 20px;
    width: 15rem;
}

.pending .rw-datetime-picker input{
    background-color: #eceef1;
    border: none;
    width: 15rem;
}

.pending .rw-datetime-picker > *{
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.pending .rw-select-bordered{
    border: none;
    background-color: #eceef1;
}

.pending select {
    border: none;
    background-color: #eceef1;
    border-radius: 20px;
    width: 15rem;
}

.pending .disperse-and-csv{
    display: flex;
    flex-direction: row;
}

.pending .disperse-and-csv .disperse-button{
    background-image: linear-gradient(to right, #5FEC90 40%, #5FEC90 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
}

.pending .disperse-and-csv .disperse-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.pending .disperse-and-csv .disperse-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.pending .disperse-and-csv .csv-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 2px 10px 2px 10px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 25px;
    margin: 7px 0 0 10px;
    color: white;
    text-decoration: none;
}

.pending .disperse-and-csv .csv-button .inner-text{
    margin: 0px 5px 1px 0px;
    font-size: 11px;
}

.pending .disperse-and-csv .csv-button svg{
    font-size: 15px;
    margin: 0 5px 0 0;
}

.pending-modal .btn-primary{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%);
    border: none;
    border-radius: 30px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}