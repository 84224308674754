
.progress {
	background-color:  rgba(255, 255, 255, 0.5);
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 25px !important;
	width: 98%;
	font-weight: bold;
	box-sizing: border-box;
}

.progress-done {
	background: linear-gradient(to left,  rgba(7,210,204,1), rgba(35,234,87,1));
	box-shadow: 0 3px 3px -5px rgba(7,210,204,1), 0 2px 5px rgba(35,234,87,1);
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.4s;
	box-sizing: border-box;
}