.approve-orgs-and-comps {
    width: 100%;
    min-height: 85vh !important;
}

.approve-orgs-and-comps .company{
    font-size: 18px;
    color: #1B3769;
    margin-bottom: 20px;
}

.approve-orgs-and-comps .title-search-bar{
    display: inline-block;
}

.approve-orgs-and-comps .alter-table{
    display: flex;
    align-items: center;
}

.approve-orgs-and-comps .add-company{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    box-shadow: 0 0 5px #888;
    font-weight:100;
    cursor: pointer;
}

.approve-orgs-and-comps .title-search-bar a{
    margin: 0 0 0 15px;
    color: #1B3769;
    font-size: 25px;
}

.approve-orgs-and-comps .title-search-bar a:hover{
    color: rgb(46, 243, 41);
}

.approve-orgs-and-comps h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #25282a;
}
.scroll{
    max-height: 80vh;
    overflow-y: scroll !important;
}

.company-form form{
    width: 95%;
    height: 78vh;
    padding-right: 10px;
    padding-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.company-form .title-container,
.users .title-container,
.manage-departments .title-container,
.roles .title-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.button-back {
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}


.users .title-container .users-options, 
.manage-departments  .title-container .users-options, 
.roles .title-container .users-options{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.users .title-container .users-options svg,
.manage-departments .title-container .users-options svg,
.roles .title-container .users-options svg{
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}
.company-form .title-container svg:hover, 
.users .title-container svg.back:hover,
.manage-departments .title-container svg.back:hover,
.roles .title-container svg.back:hover{
    font-size: 2em;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px #888;
    color: white;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    margin-right: 20px;
    cursor: pointer;
}

.company-form p{
    margin-top: 20px;
    font-size: 1.2em;
}

.company-form input, .company-form .input{
    min-height: 50px !important;
    background:rgba(255, 255, 255, 0.4) !important;
    border: none;
    border-radius: 10px;
}

.company-form .btn-container{
    width: 95%;
}
.company-form .btn-container .btn{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
}

.users form .input-group input{
    box-shadow: none;
    background:  rgba(250, 250, 250, 0.75);
}


.users form .input-group{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: #12121307;
}

.users form .input-group-text{
    background: rgba(250, 250, 250, 0.75);
    border: none;
}

.approve-orgs-and-comps .search-icon{
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    padding-left: 16px;
    width: 47px;
    height: 50px;
    background:rgba(255, 255, 255, 0.5) !important;
    border: none;
    border-radius: 25px 0 0 25px;
    margin-left: 15px;
    font-size: 28px;
    color: #666;
}
.approve-orgs-and-comps .search-icon:hover{
    color: darkgreen;
}
.approve-orgs-and-comps .search-container{
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .search-container input{
    border: none;
    height: 50px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 25px 25px 0;
}

.approve-orgs-and-comps .alter-table .reload-table{
    margin-left: 10px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color:white;
    font-size: 1.4em;
    box-shadow: 0 0 8px #aaa;
}

.company-form .form-overflow{
    height: 68vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.company-form p{
    font-weight: bold;
    color: #1B3769;
}

.company-form .files a{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.company-form .files svg{
    margin: 3px 3px 0 0;
}

.company-form .files small{
    margin: 0;
}

.company-form .form-label{
    font-weight: bold;
}

.company-form .form-file-label:hover{
    cursor: pointer;
}

.company-form .form-control-file{
    display: none;
}

.company-form input[type="checkbox"]{
    height: auto;
}

.company-form .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.company-form .title a{
    margin: 4px 5px 0 0;
    color: #1B3769;
    font-size: 20px;
}

.company-form .title a:hover{
    color: rgb(46, 243, 41);
}

.company-form .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.company-form button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-form select{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.list-companies{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 60vh;
}

.list-companies .title-container{
    margin-top: 30px;
    padding-left: 30px;
    color: #666;
}

.list-companies .title-container h5{
    font-weight: 400;
}
.list-companies .table-row{
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 95%;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px #ccc;
}

.list-companies .table-row .proved-container{
    font-weight: 600;
    font-size: 18px;
}

.list-companies .table-row .name-container{
    margin-right: 20px;
    font-size: 1.2em;
}

.list-companies .options-container{
    margin-left: auto;
}

.list-companies .table-row .dropdown-toggle{
    padding: 3px !important;
    font-size: 1.3em;
    background: transparent !important;
    border: none;
    color: black;
    box-shadow: none;
    cursor: pointer;
}

.list-companies .table-row .dropdown-toggle:active,
.interns-list .table-content .dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle{
    background: transparent !important;
    border: none !important;
    color: black !important;
    box-shadow: none !important;
}

.list-companies .table-row .dropdown-toggle::after{
    display: none;
}

.list-companies .table-row .dropdown-item{
    padding: 5px 0;
    min-width: 300px;
}
.list-companies .table-row .dropdown-item:hover{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white;
}

.list-companies .table-row .dropdown-menu{
    border: none;
    background: #e2e2eb;
    box-shadow: 0 0 3px #333;
}

.list-companies .table-row .row{
    margin-left: 0;
}

.list-companies .cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: 20px 0 0 0;
}

.list-companies .card{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 220px;
    height: 205px;
    box-shadow: 3px 5px 5px #d7d7d7;
    margin: auto;
}

.list-companies .flip-card {
    background-color: transparent;
    width: 220px;
    height: 175px;
    margin: auto;
    perspective: 1000px;
  }
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
}

.flip-card-back {
    position: absolute;
    bottom: -25px;
    transform: rotateY(180deg);
}

.list-companies .card a{
    color: #1B3769;
    font-size: 25px;
    padding: 0px 5px 0px 5px;
}

.list-companies .card a:hover{
    color:rgb(46, 243, 41);
}

.list-companies .flip-card-inner .card-footer{
    position: absolute;
    bottom: 0;
}

.list-companies .card .company-name{
    color: #1B3769;
    font-weight: bold;
    font-size: medium;
    margin-bottom: 2px;
}

.list-companies .card .company-options{
    text-align: center;
    margin: 5px 0;
}

.list-companies .card .company-options .option{
    display: inline-block;
    width: 40%;
    font-size: 28px;
}

.list-companies .card .company-options .option svg:hover{
    cursor: pointer;
}

.list-companies .card .company-options .approve{
    color: green;
}

.list-companies .card .company-options .deny{
    color: tomato;
}

.company-docs a{
    color: #1B3769;
    margin: 0 10px 0 0px;
    font-size: 20px;
}

.company-docs .form-file-label{
    color: #1B3769;
    font-size: 20px;
}

.company-docs .form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}

.company-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.company-modals .modal-header{
    display: flex;
    flex-direction: column;
}

.company-modals p{
    font-weight: bold;
    font-size: large;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.company-modals small{
    color: #1B3769;
    margin: auto;
}

.company-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.company-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.company-modals .btn-danger:hover{
    background: transparent !important;
    border: 1px solid #666;
    color: #666;
}

.users .title, .roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.users .title a, .roles .title a{
    margin: 4px 5px 0 0;
    color: #333;
    font-size: 20px;
}

.users .title a:hover, .roles .title a:hover{
    color: rgb(46, 243, 41);
}

.users .title h4, .roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #333;
}

.users .users-options a, .roles .users-options a{
    font-size: 25px;
    margin-left: 10px;
}

.users .user-cards-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 25px;
    width: 80vw;
    margin: .5rem 0 0 0;
}

.users .card{
    border-radius: 15px;
    width: 17.5rem;
    height: 17.5rem;
}

.users .card-header p{
    color: #1B3769;
    font-weight: bolder;
    font-size: small;
    margin: auto;
    text-align: center;
}

.users .user-card-body{
    display: flex;
    flex-direction: column;
}

.card-department-body{
    padding: 0 !important;
}
.users .user-card-body div{
    display: flex;
    flex-direction: row;
}

.users .user-card-body div svg{
    color: #1B3769;
    margin: 2.5px .5rem 0px 0px;
    width: 18px;
}

.users .user-card-body div p{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .card-header:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.users .card-footer:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.users .user-card-footer .options{
    float: right;
}

.users .user-card-footer .options a svg{
    color: #1B3769;
    width: 30px;
    font-size: 25px;
}

.users .user-card-footer .options a svg:hover{
    color: rgb(46, 243, 41);
}

.users .create-user{
    height: 75vh;
    padding: 1rem;
    overflow-y: auto;
}

.users .create-user .checkClass{
    display: flex;
    align-items: center;
    flex-direction: row;
}


.users .create-user input{
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 15px;
    height: 50px;
}

.users .create-user label{
    color: #1B3769;
    font-size: 14px;
    font-weight: bold;
}

.users .create-user button{
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    border: none;
    border-radius: 8px;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 18px;
    margin-right: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.users .table{
    width: 95%;
    margin-bottom: 150px;
}
.users .table h5{
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #666;
    margin-bottom: 15px;
}
.users .table .header{
    background: transparent;
    margin-left: 0;
    padding: 0 10px;
    margin-bottom: 5px;
    color: #666;
}
.users .table .body{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background:rgba(255, 255, 255, 0.6);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 0;
}
.users .table .options{
    text-align: center;
}
.users .table .options svg{ color: #333; }

.roles .title{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.roles .title a{
    margin: 4px 5px 0 0;
    color: black;
    font-size: 20px;
}

.roles .title h4{
    font-weight: bolder;
    margin: 5px 0 0 0;
    color: #1B3769;
}

.roles .roles-list{
    width: 95%;
    padding: 0 20px;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .toggle{
    padding: 0;
    border: none;
    background: transparent;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card{
    border: none;
    margin-bottom: 15px;
    border-radius: 15px  !important;
    box-shadow: 0 3px 8px #aaa;
    background: transparent;
    color: black;
}
.roles .roles-list .card-header{
    border: none;
    background: rgba(255,255,255,0.6);
    color: black !important;
}
.roles .roles-list .card-header .name-container{
    text-align: left;
    color: rgb(0, 0, 0) !important;  
}
.roles .roles-list .btn-link:hover,
.roles .roles-list .btn-link:focus{
    text-decoration: none !important;
    box-shadow: none;
}
.roles .roles-list .card-header .icon-container{
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}
.roles .roles-list .card-header .btn:hover,
.roles .roles-list .card-header .btn:focus,
.roles .roles-list .card-header .btn:hover{
    text-decoration: none;
    box-shadow:none;
    outline: none;
}

.roles .roles-list .card-header button{
    color: white;
    font-weight: bolder;
    padding: 0;
    color: rgb(0, 0, 0) !important;
}

.roles .roles-list .card-header svg{
    font-size: 20px;
    display: inline-block;
    color: black;
}

.roles .roles-list .card-header .options{
    color: white;
    float: right;
    font-size: 18px;
}

.roles .roles-list .card-header .options > *{
    margin-left: 5px;
}

.roles .roles-list .card-body{
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
}
.roles .roles-list .show{
    background: rgba(255,255,255,0.6);
}

.roles .roles-list .card-body .user-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    min-height: 50px;
    margin: 10px auto;
    width: 95%;
    justify-content: space-between;
    background: #efefef;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
}
.roles .roles-list .card-body .user-options .user-name{
    margin: 0;
    text-align: left;
}
.roles .roles-list .card-body .user-options svg{
    color: #666;
    font-size: 15px;
    margin-right: 5px;
}

.roles .roles-list .card-body .user-options .option{
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
}

.roles .roles-list .card-body .user-options .option:hover{
    color: #333;
}

.roles .roles-list .card-body .user-options .assign-role{
    color: black;
}

.manage-departments .card{
    border: none;
    box-shadow: 0 5px 5px #666;
}

.manage-departments .card-header{
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card-header {
    padding: 10px 10px;
    background: rgb(7,210,204);
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%);
    color: white !important;
}

.manage-departments .card svg{
    font-size: .9em;
    margin-left: 3px;
    color: #666 !important;
}

.manage-departments .card-header svg{
    font-size: .9em;
    margin-left: 3px;
    color: white !important;
}

.companies-modals p{
    font-weight: bold;
    font-size: large;
    text-align: center;
    color: #1B3769;
    margin: auto;
    padding-top: 5px;
}

.companies-modals table{
    padding: 10px;
    margin: auto;
    max-width: 98%;
}

.companies-modals table tr td{
    text-align: center;
    font-weight: bolder;
    font-size: 14px;
}

.change-permissions-modal .modal-dialog{
    max-width: 80%;
    margin: auto;
}

.companies-modals small{
    color: #1B3769;
    margin: auto;
    text-align: center;
}

.companies-modals input{
    background-color: #eceef1;
    border: none;
    border-radius: 10px;
}

.companies-modals .btn-primary{
    background: rgb(7,210,204) !important;
    background: linear-gradient(90deg, rgba(7,210,204,1) 0%, rgba(35,234,87,1) 100%) !important;
    color: white !important;
    box-shadow: none !important;
    border: none;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .btn-danger{
    background: white !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin: auto;
    outline-style: none;
    padding: 8px 35px 8px 35px;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
}

.companies-modals .logoCaintra{
    width: 120px;
    height: auto;
    margin: auto;
}

.companies-modals .modal-header{
    display: flex;
    flex-direction: column;
}
.options a{
    margin: 0 0 0 10px;
    color: #1B3769;
    font-size: 22x !important;
}
.options a:hover{
    color: rgb(46, 243, 41);
}
.profile-photo{
    border: solid 1px #d7d7d7;
    border-radius: 50px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    position: relative;
    margin: 10px;
}

.profile-photo .profile-photo-camera {
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 18px !important;
}
.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 250px;
    margin: auto;
    position: relative;
    border: 1px solid #d7d7d7;
}
.profile-photo-camera{
    position: absolute;
    right: 5px;
    top: -8px;
}
.profile-picture svg:hover{
    color: rgb(46, 243, 41);
}
.form-control-file{
    display: none;
}
.form-control-file{
    display: none !important;
}
.form-file-label:hover{
    cursor: pointer;
    color: rgb(46, 243, 41);
}
.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}

@media only screen and (max-width: 1620px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1300px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 845px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width: 540px){
    .list-companies .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}

 .pagination-container{
    display: flex;
    color: black;
    list-style-type: none;
    font-size: 21px;
    margin: 10px 100px 0 0;
    float: right;
    padding: 0;
}

 .pagination-container li{
    border: none;
}

 .pagination-container li a{
    margin: 10px;
}

.pagination-container .previous{
    font-weight: bolder;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
}

.pagination-container .next{
    font-weight: bolder;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination-container li a:hover{
    color: rgb(46, 243, 41);
}

 .pagination-container .selected{
    font-weight: bolder;
    color: white !important;
}
.interns-list-options {
    margin-right: 60px;
}

.interns-list-options p{
    margin: 10px 0 0 0;
    color: #1B3769;
}
.search-container input{
    background-color: #eceef1;
    border-radius: 10px;
}

.center-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;    
    align-self: center !important;
    height: 100%;
}
.table-width{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important; 
}
.scroll-row-section{
    width: 100% !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
    height: 85vh;
}
.user-container{
    width: 100% !important;
}
.search-button{
    background-image: linear-gradient(to right, #00adee 40%, #007ed1 100%) !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 5px rgba(215, 215, 215, 1);
    height: 30px;
    padding: 0 12px !important;
    margin-top: 4px ;
}
.card-roles{
    font-size: 13px !important;
    color: black !important;
}

.groups-icon {
    font-size: 1.5rem;
    margin: 0 5px;
}

.groups-error-message {
    color: red !important;
}